import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { login } from "./actions";
import LoginForm from "./LoginForm";

class LoginPage extends Component {
  onSubmit = (formValues) => {
    const { username, password } = formValues;
    if (username && password) {
      this.props.login(username, password);
    }
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.auth.isLoggedIn && this.props.auth.isLoggedIn) {
      if (this.props.history.location.state) {
        const locationState = this.props.history.location.state;
        const { from } = locationState;
        if (from) {
          this.props.history.replace(from);
          return;
        }
      }
      this.props.history.push("/cms");
    }
  }

  render() {
    return <LoginForm onSubmit={this.onSubmit} />;
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth };
};
export default connect(mapStateToProps, { login })(withRouter(LoginPage));
