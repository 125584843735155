import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { snowtrade } from "../api";

export const useHotelFromUrl = () => {
  const [hotel, setHotel] = useState();

  const params = useParams();
  const hotelId = params.hotelId || params.strHotelId;

  useEffect(() => {
    snowtrade
      .get(`/api/hotels/${hotelId}`)
      .then((response) => setHotel(response.data));
  }, [params]);

  return { hotel, isFetching: !hotel };
};
