import React from "react";

import { useForm } from "react-hook-form";
import { Form } from "semantic-ui-react";

import { snowtrade } from "../../api/snowtrade";

export const FilterForm = ({ onAvailableRoomsResponse, hotelId }) => {
  const form = useForm();
  const [isFetching, setIsFetching] = React.useState(false);

  const submitHandler = (filter) => {
    setIsFetching(true);
    snowtrade
      .get(`/api/hotels/${hotelId}/available-rooms`, { params: filter })
      .then((response) => onAvailableRoomsResponse(response.data))
      .finally(() => {
        setIsFetching(false);
      });
  };

  return (
    <Form onSubmit={form.handleSubmit(submitHandler)}>
      <Form.Group inline>
        <Form.Input
          label="Check-in"
          type="date"
          {...form.register("checkInDate")}
        />
        <Form.Input
          label="Check-out"
          type="date"
          min={form.watch("checkInDate")}
          {...form.register("checkOutDate")}
        />
        <Form.Button
          content="Search"
          onClick={submitHandler}
          loading={isFetching}
        />
      </Form.Group>
    </Form>
  );
};
