import React from 'react';

import { Form } from 'semantic-ui-react';

const Input = ({ input, label, type, min, meta: { error } }) => {
  if (error) {
    return (
      <Form.Input
        fluid
        label={label}
        placeholder={label}
        type={type}
        min={min}
        error={error}
        {...input}
      />
    );
  } else {
    return (
      <Form.Input
        fluid
        label={label}
        placeholder={label}
        type={type}
        min={min}
        {...input}
      />
    );
  }
};

export default Input;
