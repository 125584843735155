import React from "react";

import { Link } from "react-router-dom";
import { Button, Container, Header, Loader } from "semantic-ui-react";

import { SyncHotelImagesButton } from "../SyncHotelImagesButton";
import { useHotelFromUrl } from "../useHotelFromUrl";

import "./HotelDetails.css";

export const HotelDetails = () => {
  const { hotel, isFetching } = useHotelFromUrl();
  if (isFetching) return <Loader active />;

  return (
    <Container className="hotelDetails">
      <Header>{hotel.name}</Header>
      <Link to={`/cms/hotels/${hotel._id}/rooms`}>
        <Button>Rooms</Button>
      </Link>
      <Link to={`/cms/hotels/${hotel._id}/edit`}>
        <Button icon>
          Edit <i className="edit icon" />
        </Button>
      </Link>
      <Link to={`/cms/hotels/${hotel._id}/amadeus-manager`}>
        <Button icon>
          Amadeus <i className="cloud download icon" />
        </Button>
      </Link>
      <SyncHotelImagesButton hotelId={hotel._id} />
    </Container>
  );
};
