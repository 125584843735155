import React from "react";

import { Route, Switch } from "react-router-dom";

import {
  HotelAmadeusManagerComponent,
  HotelInputComponent,
  HotelTable,
  newHotelInput,
  newRoomInput,
  RoomAmadeusManagerComponent,
  RoomInputComponent,
} from "../hotel";
import { HotelDetails } from "../hotel/HotelDetails";
import { HotelRoomsPage } from "../hotel/HotelRoomsPage";

export const HotelRoutes = () => (
  <Switch>
    <Route path="/cms/hotels/new" component={newHotelInput} />
    <Route
      path="/cms/hotels/:strHotelId/amadeus-manager"
      component={HotelAmadeusManagerComponent}
    />
    <Route path="/cms/hotels/:hotelId/edit" component={HotelInputComponent} />

    <Route path="/cms/hotels/:hotelId/rooms/new" component={newRoomInput} />
    <Route
      path="/cms/hotels/:hotelId/rooms/:roomId/edit"
      component={RoomInputComponent}
    />
    <Route
      path="/cms/hotels/:hotelId/rooms/:roomId/amadeus-manager"
      component={RoomAmadeusManagerComponent}
    />
    <Route path="/cms/hotels/:hotelId/rooms" component={HotelRoomsPage} />
    <Route path="/cms/hotels/:hotelId" component={HotelDetails} />
    <Route path="/cms/hotels" component={HotelTable} />
  </Switch>
);
