import React from "react";

import _ from "lodash";
import ImageGallery from "react-image-gallery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { Accordion } from "../../accordion";
import { List } from "../../list";
import { PropertyStaysListContainer } from "../../stay";
import { Tab } from "../../tab";
import { GoogleMap } from "../GoogleMap";
import { PropertyStatusDropdown } from "./PropertyStatusDropdown";

import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";

const PropertyDetails = (props) => {
  const { property } = props;

  if (property.isFetching === true) {
    return <div className="ui segment loading">Loading...</div>;
  }

  var images = [];

  if (property.images) {
    images = property.images.map((image) => {
      return {
        original: image.url,
        thumbnail: image.url,
      };
    });
  }

  const { name, city, address, propertyType, bedrooms, beds, status } =
    property;

  const renderOperatorInformation = () => {
    return (
      <div className="ui segment">
        <div style={{ marginBottom: "1rem" }}>
          <button
            onClick={() => {
              props.onSyncButtonClick();
            }}
            className={`ui button icon fluid ${
              property.isImporting ? "loading" : ""
            }`}
          >
            <i className="sync icon" /> Sync Property
          </button>
        </div>
        <div style={{ marginBottom: "1rem" }}>
          <button
            onClick={() => {
              props.onStaySyncButtonClick();
            }}
            className="ui button icon fluid"
          >
            <i className="sync icon" /> Sync Stays
          </button>
        </div>
        <div style={{ marginBottom: "1rem" }}>
          <Link to={props.editPropertyLink}>
            <button
              onClick={props.onCopyLinkClick}
              className="ui button icon fluid"
            >
              <i className="share icon" /> Edit Property
            </button>
          </Link>
        </div>

        <div>
          <span>PropertyId: </span>
          {property._id}
        </div>
        <div>
          <span>Operator: </span>
          <Link
            to={`/cms/operators/${_.lowerCase(property.operator)}/properties`}
          >
            {property.operator}
          </Link>
        </div>
        <div>
          <span>OperatorId: </span>
          {property.operatorId}
        </div>
        <div>
          <span>Status: </span>
          {property.status}
        </div>
        <div>
          <span>Created: </span>
          {property.createdAt}
        </div>
        <div>
          <span>Last updated: </span>
          {property.updatedAt}
        </div>
      </div>
    );
  };

  const renderInformation = ({
    propertyType,
    bedrooms,
    beds = {},
    distances,
  }) => {
    const informationArray = [
      propertyType ? _.upperFirst(propertyType) : null,
      bedrooms ? `${bedrooms} Bedrooms` : null,
      beds.adults ? `${beds.adults} Adults` : null,
      beds.children ? `${beds.children} Children` : null,
      !_.isNil(distances)
        ? distances.map((distance) => {
            return distance;
          })
        : null,
    ];
    const informationArrayCleaned = _.flattenDeep(_.compact(informationArray));
    const informationList = <List listItems={informationArrayCleaned} />;
    return {
      title: "Information",
      content: informationList,
    };
  };

  const renderPropertyMap = () => {
    const map = (
      <div className="mapContainer" style={{ width: "100%", height: "60vh" }}>
        <GoogleMap coordinates={property.coordinates} />
      </div>
    );

    return {
      title: "Location",
      content: map,
    };
  };

  const renderFacilities = (facilities) => {
    if (_.isEmpty(facilities)) {
      return null;
    }
    const facilitiesArray = facilities.map((facility) => facility.facility);
    const facilitiesList = <List listItems={facilitiesArray} />;
    return {
      title: "Facilities",
      content: facilitiesList,
    };
  };

  const renderServices = (servicesIncluded) => {
    if (_.isEmpty(servicesIncluded)) {
      return null;
    }
    let servicesIncludedProps = [];
    _.forOwn(servicesIncluded, (serviceObjects, serviceLevel) => {
      if (!serviceObjects) return;
      const services = serviceObjects.map((s) => s.service);
      const serviceList = <List listItems={services} />;
      servicesIncludedProps.push({ title: serviceLevel, content: serviceList });
    });

    const serviceTabs = <Tab tabProps={servicesIncludedProps} />;
    return {
      title: "Services",
      content: serviceTabs,
    };
  };

  const renderEquipment = (equipmentIncluded) => {
    if (_.isEmpty(equipmentIncluded)) {
      return null;
    }
    const equipmentArray = equipmentIncluded.map(
      (equipment) => equipment.equipment
    );
    const equipmentList = <List listItems={equipmentArray} />;
    return {
      title: "Equipment",
      content: equipmentList,
    };
  };

  let accordionProps = [
    renderInformation(property),
    renderFacilities(property.facilities),
    renderServices(property.servicesIncluded),
    renderEquipment(property.equipmentIncluded),
  ];

  if (property.coordinates) {
    accordionProps.splice(1, 0, renderPropertyMap());
  }

  return (
    <div className="ui grid segment">
      <div className="row">
        <div
          className="sixteen wide column mobile tablet only right aligned"
          style={{ marginBottom: "10px" }}
        >
          <button
            onClick={() => {
              props.onDeleteButtonClick();
            }}
            className="ui right floated icon button negative"
          >
            <i className="trash icon" />
          </button>
          <button
            onClick={() => {
              toast.info("Whoopsy. This button doesn't work yet!");
            }}
            className="ui right floated icon button primary"
          >
            <i className="edit icon" />
          </button>
          <PropertyStatusDropdown
            status={property.status}
            onChangeStatus={props.onChangePropertyStatus}
          />
        </div>
        <div className="ten wide column computer only">
          <h1 className="ui left floated header">
            {name}
            <div className="sub header">
              {address && address.sector ? `${address.sector}, ` : ""}
              {city}
              {address && address.country ? `, ${address.country}` : ""}
            </div>
          </h1>
        </div>
        <div className="sixteen wide column mobile tablet only">
          <h1 className="ui header">
            {name}
            <div className="sub header">
              {address && address.sector ? `${address.sector}, ` : ""}
              {city}
              {address && address.country ? `, ${address.country}` : ""}
            </div>
          </h1>
        </div>
        <div className="six wide column computer only right aligned">
          <button
            onClick={() => {
              props.onDeleteButtonClick();
            }}
            className="ui right floated icon button negative"
          >
            <i className="trash icon" />
          </button>
          <button
            onClick={() => {
              toast.info("Whoopsy. This button doesn't work yet!");
            }}
            className="ui right floated icon button primary"
          >
            <i className="edit icon" />
          </button>
          <PropertyStatusDropdown
            status={property.status}
            onChangeStatus={props.onChangePropertyStatus}
          />
        </div>
      </div>
      {status === "draft" ? (
        <div className="row">
          <div className="column">
            <div className="ui icon warning message">
              <i className="file outline icon" style={{ fontSize: "2em" }}></i>
              <div className="content">
                This Property is still on status: <b>DRAFT</b>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="row">
        <div className="column">
          <div className="ui large horizontal list">
            <div className="item">
              <i className="home icon" style={{ paddingRight: "2rem" }} />
              {_.upperFirst(propertyType)}
            </div>
            <div className="item">
              <i className="bed icon" style={{ paddingRight: "2rem" }} />
              {_.upperFirst(bedrooms)}
            </div>
            <div className="item">
              <i className="user icon" style={{ paddingRight: "2rem" }} />
              {beds ? _.upperFirst(beds.adults) : ""}
            </div>
            <div className="item">
              <i className="child icon" style={{ paddingRight: "2rem" }} />
              {beds ? _.upperFirst(beds.children) : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="twelve wide column computer only">
          <ImageGallery showBullets items={images} />
        </div>
        <div className="sixteen wide column mobile tablet only">
          <ImageGallery showBullets items={images} />
        </div>
        <div className="four wide column computer only">
          {renderOperatorInformation()}
        </div>
        <div className="sixteen wide column mobile tablet only">
          {renderOperatorInformation()}
        </div>
        <div className="sixteen wide column">
          <Accordion accordionProps={_.compact(accordionProps)} />
        </div>
        <div className="sixteen wide column">
          <h2>Stays</h2>
          <PropertyStaysListContainer propertyId={property._id} />
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;
