import React from "react";

import { Router, Switch, Route } from "react-router-dom";

import { AlertBarContainer } from "../alert";
import { DashboardContainer, PropertiesIssueList } from "../dashboard";
import { history } from "../helpers";
import { http404 } from "../httpResponses";
import { MenuContainer } from "../menu";
import { OperatorPropertiesContainer } from "../operator";
import { OperatorPage } from "../operator/OperatorPage";
import { PropertyDetails } from "../property";
import { PropertyEditorComponent } from "../property/PropertyEditor/PropertyEditorComponent";
import { AdvancedSearch } from "../search";
import { CreateUserForm } from "../user/CreateUserForm";
import { EditUserPage } from "../user/EditUser";
import { UsersTable } from "../user/UsersTable";
import "./Cms.css";
import { HotelRoutes } from "./HotelRoutes";

const Cms = () => {
  return (
    <Router history={history}>
      <div className="ui grid">
        <MenuContainer />
        <div className="row">
          <div className="column">
            <div id="mainContent">
              <div className="ui container">
                <AlertBarContainer />
                <Switch>
                  <Route exact path="/cms" component={DashboardContainer} />
                  <Route
                    path="/cms/dashboard/property-issues"
                    component={PropertiesIssueList}
                  />
                  <Route
                    exact
                    path="/cms/advancedSearch"
                    component={AdvancedSearch}
                  />
                  <Route
                    exact
                    path="/cms/properties/:propertyId/editor"
                    component={PropertyEditorComponent}
                  />
                  <Route
                    exact
                    path="/cms/properties/:_id"
                    component={PropertyDetails}
                  />
                  <Route
                    exact
                    path="/cms/operators/:operator/properties"
                    component={OperatorPropertiesContainer}
                  />
                  <Route path="/cms/operators" component={OperatorPage} />
                  <Route path="/cms/users/new" component={CreateUserForm} />
                  <Route path="/cms/users/:userId" component={EditUserPage} />
                  <Route path="/cms/users" component={UsersTable} />
                  <Route path="/cms/hotels" component={HotelRoutes} />
                  <Route exact path="/cms/404" component={http404} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Cms;
