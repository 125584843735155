import React, { useState } from "react";

import AccordionSection from "./AccordionSection";
import "./Accordion.css";

const Accordion = ({ accordionProps = [], defaultActive = 0 }) => {
  const [active, setActive] = useState(defaultActive);

  const onTitleClick = (index) => {
    setActive(active !== index ? index : null);
  };

  return (
    <div className="ui styled fluid accordion" id="accordionPropertyDetails">
      {accordionProps.map((props, index) => (
        <AccordionSection
          key={index}
          index={index}
          title={props.title}
          content={props.content}
          onTitleClick={() => {
            onTitleClick(index);
          }}
          activeIndex={active}
        />
      ))}
    </div>
  );
};

export default Accordion;
