import React, { Component } from 'react';

import _ from 'lodash';
import { connect } from 'react-redux';

import { fetchPropertyStays } from './actions';
import PropertyStaysList from './PropertyStaysList';

export class PropertyStaysListContainer extends Component {
  componentDidMount() {
    this.props.fetchPropertyStays(this.props.propertyId);
  }

  renderCurrency = currency => {
    return currency ? currency : <i className="icon euro sign"></i>;
  };

  render() {
    const { stays, currency } = this.props;
    return (
      <PropertyStaysList
        stays={stays}
        currency={this.renderCurrency(currency)}
      />
    );
  }
}

const mapStateToProps = ({ properties, stays }, ownProps) => {
  const propertyStays = !_.isNil(stays.items[ownProps.propertyId])
    ? stays.items[ownProps.propertyId]
    : [];
  const currency = !_.isNil(properties.items[ownProps.propertyId].currency)
    ? properties.items[ownProps.propertyId].currency
    : '';

  return {
    stays: propertyStays,
    currency
  };
};

export default connect(mapStateToProps, { fetchPropertyStays })(
  PropertyStaysListContainer
);
