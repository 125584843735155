import React from "react";

import PropTypes from "prop-types";
import { Form, Header, Segment } from "semantic-ui-react";

const emptyContact = {
  internalName: "",
  email: "",
  salutationName: "",
  emailSalutationLine: "",
};

export class ContactForm extends React.PureComponent {
  handleChange = (contactType, value) => {
    const contactStateBefore = this.props.contact || {};
    const newContactState = { ...contactStateBefore, [contactType]: value };
    this.props.onChange("contact", newContactState);
  };

  render() {
    const { contact } = this.props;

    let renderedContact = contact ? { ...contact } : {};
    renderedContact.reservation = renderedContact.reservation || emptyContact;
    renderedContact.concierge = renderedContact.concierge || emptyContact;
    renderedContact.information = renderedContact.information || emptyContact;

    return (
      <>
        <h2>Contact</h2>
        <Form>
          <Segment className="colored">
            <ContactInput
              contact={renderedContact.reservation}
              contactType="reservation"
              onChange={this.handleChange}
            />
          </Segment>
          <Segment className="colored">
            <ContactInput
              contact={renderedContact.concierge}
              contactType="concierge"
              onChange={this.handleChange}
            />
          </Segment>
          <Segment className="colored">
            <ContactInput
              contact={renderedContact.information}
              contactType="information"
              onChange={this.handleChange}
            />
          </Segment>
        </Form>
      </>
    );
  }
}

ContactForm.propTypes = {
  contact: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ContactInput = ({ contact, contactType, onChange }) => {
  const handleChange = ({ target }) => {
    const { name, value } = target;
    const newContactState = { ...contact, [name]: value };
    onChange(contactType, newContactState);
  };
  return (
    <>
      <Header>{contactType}</Header>
      <Form.Group widths="equal" onChange={handleChange}>
        <Form.Input
          label="Name"
          placeholder="Internal only"
          value={contact.internalName}
          name="internalName"
        />
        <Form.Input
          label="Email"
          placeholder="Email address of the contact"
          value={contact.emailAddress}
          name="emailAddress"
          type="email"
        />
        <Form.Input
          label="Salutation Name"
          placeholder="Dear xxx, warm greetings from Germany"
          value={contact.salutationName}
          name="salutationName"
        />
      </Form.Group>
      <Form.Input
        label="Personalized Email Salutation Line"
        placeholder="Hey Minnie Mouse! How are you?"
        value={contact.emailSalutationLine}
        name="emailSalutationLine"
        onChange={handleChange}
      />
    </>
  );
};

ContactInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  contactType: PropTypes.string.isRequired,
  contact: PropTypes.object.isRequired,
};
