import React from "react";

import PropTypes from "prop-types";
import { Form, Header } from "semantic-ui-react";

const placeholder = `- Ein belegtes Brot mit Schinken 
- Schinken
- Ein belegtes Brot mit Ei
- Ei`;

export const FacilitiesMarkdownForm = ({ facilitiesMarkdown, onChange }) => {
  const handleChange = ({ target: { value } }) =>
    onChange("facilitiesMarkdown", value);

  return (
    <Form>
      <Header>Facilities</Header>
      <Form.TextArea
        rows="15"
        value={facilitiesMarkdown}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </Form>
  );
};

//
// PropTypes
//

FacilitiesMarkdownForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  facilitiesMarkdown: PropTypes.string,
};
