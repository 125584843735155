import { createSelector } from "reselect";

const selectPropertyItems = (state) => state.properties.items;

/**
 * Returns an array of all properties in the redux store.
 */
export const reselectPropertyArray = createSelector(
  [selectPropertyItems],
  (propertyItems) => Object.values(propertyItems)
);
