import React, { useState } from "react";

import { useSetState } from "react-use";
import {
  Button,
  Divider,
  Form,
  Modal,
  Radio,
  Segment,
} from "semantic-ui-react";

import { PossibleTemplateCommands } from "./PossibleTemplateCommands";

/**
 * @typedef {{
 *   subject: string;
 *   content: string;
 *   mjml?: string;
 *   markdown?: string;
 * }} EmailFormState
 */

/** @typedef {(state: EmailFormState) => Promise<any>} EmailFormSubmitHandler */

/**
 * @param {{
 *   defaultValues: Partial<EmailFormState>;
 *   handleSubmit: EmailFormSubmitHandler;
 * }} props
 */
export const EmailForm = ({ defaultValues, handleSubmit }) => {
  const [state, patchState] = useSetState(
    /** @type {EmailFormState} */ ({
      subject: "",
      content: "",
      type: "markdown",
      ...defaultValues,
    })
  );
  const [isSending, setIsSending] = useState(false);

  const handleSubmitClick = () => {
    if (isSending) return;
    setIsSending(true);

    handleSubmit(state).finally(() => setIsSending(false));
  };

  /** @param {"mjml" | "markdown"} newType */
  const setType = (newType) => {
    patchState({ type: newType, mjml: "", markdown: "" });
  };

  return (
    <>
      <Segment className="colored">
        <Form>
          <Form.Input
            label="Subject"
            value={state.subject}
            onChange={(e, { value }) => patchState({ subject: value })}
          />
          <Form.TextArea
            rows={14}
            label={`${state.type} content`}
            value={state[state.type]}
            onChange={(e, { value }) => patchState({ [state.type]: value })}
          />
          <Form.Field>
            <Radio
              label="Markdown"
              checked={state.type === "markdown"}
              onChange={() => setType("markdown")}
            />
            <Radio
              label="MJML"
              checked={state.type === "mjml"}
              onChange={() => setType("mjml")}
            />
          </Form.Field>

          <Modal
            trigger={
              <Form.Button
                primary
                content="Send email"
                disabled={!state.type}
              />
            }
          >
            <Modal.Content>
              <p>Sure?</p>
              <Button
                primary
                content="Yes, send"
                loading={isSending}
                onClick={handleSubmitClick}
              />
            </Modal.Content>
          </Modal>
        </Form>
      </Segment>
      <Divider section />
      <PossibleTemplateCommands />
    </>
  );
};
