/**
 * @typedef {Object} PropertyDetailsFetchAction
 * @property {"PROPERTY_FETCH_REQUEST"} type
 * @property {Object} payload
 * @property {string} payload._id
 */

/**
 * @typedef {Object} PropertiesFetchAction
 * @property {"PROPERTY_FETCHLIST_REQUEST"} type
 * @property {Object} payload
 * @property {{ projection; conditions }} payload.params
 */

export const propertyTypes = {
  FETCH_REQUEST: "PROPERTY_FETCH_REQUEST",
  FETCH_SUCCESS: "PROPERTY_FETCH_SUCCESS",
  FETCH_FAILURE: "PROPERTY_FETCH_FAILURE",

  FETCHLIST_REQUEST: "PROPERTY_FETCHLIST_REQUEST",
  FETCHLIST_SUCCESS: "PROPERTY_FETCHLIST_SUCCESS",
  FETCHLIST_FAILURE: "PROPERTY_FETCHLIST_FAILURE",

  EDIT_REQUEST: "PROPERTY_EDIT_REQUEST",
  EDIT_SUCCESS: "PROPERTY_EDIT_SUCCESS",
  EDIT_FAILURE: "PROPERTY_EDIT_FAILURE",
};
