import React from "react";

import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import { createInputChangeEventHandler } from "../../../form/changeHandlers";

export const PropertySectorInput = ({ value, onChange }) => {
  return (
    <Form.Input
      value={value || ""}
      onChange={createInputChangeEventHandler(onChange)}
      name="sector"
      label="Sector"
    />
  );
};

//
// Prop Types
//

PropertySectorInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};
