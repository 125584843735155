import React from "react";

import { toast } from "react-toastify";
import { useCopyToClipboard } from "react-use";
import { Icon } from "semantic-ui-react";

import { snowtrade } from "../api";

export const UserTokenIcon = ({ userId }) => {
  // eslint-disable-next-line no-unused-vars
  const [_state, copyToClipboard] = useCopyToClipboard();

  const handleClick = () => {
    snowtrade.get(`/api/users/${userId}/tokens`).then((response) => {
      const { accessToken, expiresIn } = response.data;
      copyToClipboard(accessToken);
      toast.success(`AccessToken copied. Expires in ${expiresIn} `);
    });
  };
  return <Icon name="key" link onClick={handleClick} />;
};
