import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button, Header, Input, Loader, Segment } from "semantic-ui-react";

import { snowtrade } from "../../../api/snowtrade";
import { useHotelRoomById } from "../../useHotelRoomById";
import { AmadeusSearch } from "../AmadeusSearch";
import { useAmadeusHotelId } from "../useAmadeusHotelId";

const useAmadeusRoomMap = (strRoomId) => {
  const [amadeusRoomDescriptionRegExp, setAmadeusRoomDescriptionRegExp] =
    useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    snowtrade
      .get(`/api/hotel-rooms/${strRoomId}/room-map`)
      .then((response) =>
        setAmadeusRoomDescriptionRegExp(
          response.data.amadeusRoomDescriptionRegExp
        )
      )
      .finally(() => setIsFetching(false));
  }, []);

  const persistRoomMap = () => {
    setIsFetching(true);
    snowtrade
      .put(`/api/hotel-rooms/${strRoomId}/room-map`, {
        strRoomId,
        amadeusRoomDescriptionRegExp,
      })
      .then(() => toast.success("Persisted Room Map"))
      .catch(() => toast.error("uuuupsi"))
      .finally(() => setIsFetching(false));
  };

  const changeRoomMapRegExp = ({ target: { value } }) =>
    setAmadeusRoomDescriptionRegExp(value);

  return {
    amadeusRoomDescriptionRegExp,
    isFetching,
    persistRoomMap,
    changeRoomMapRegExp,
  };
};

export const RoomAmadeusManager = ({ roomId, hotelId }) => {
  const { room, isFetching: isFetchingRoom } = useHotelRoomById(roomId);
  const {
    amadeusRoomDescriptionRegExp,
    isFetching: isFetchingRoomMap,
    persistRoomMap,
    changeRoomMapRegExp,
  } = useAmadeusRoomMap(roomId);
  const { amadeusHotelId } = useAmadeusHotelId(hotelId);

  if (isFetchingRoom) {
    return <Loader active />;
  }

  return (
    <>
      <Header>{room.name}</Header>
      <Segment className="colored">
        <Input
          label="Amadeus Description Reg Exp"
          value={amadeusRoomDescriptionRegExp}
          loading={isFetchingRoomMap}
          onChange={changeRoomMapRegExp}
          fluid
        />
        <Button primary onClick={persistRoomMap}>
          Save
        </Button>
      </Segment>
      <Segment className="colored">
        <Header>Amadeus Search</Header>
        {amadeusHotelId && <AmadeusSearch amadeusHotelId={amadeusHotelId} />}
      </Segment>
    </>
  );
};

//
// Prop Types
//

RoomAmadeusManager.propTypes = {
  hotelId: PropTypes.string.isRequired,
  roomId: PropTypes.string.isRequired,
};
