import _ from "lodash";
import { call, put } from "redux-saga/effects";

import { alertActions } from "../alert";
import { snowtrade } from "../api";
import { authHeader } from "../helpers/authHeader";
import { history } from "../helpers/history";
import { logout } from "../login";

export function* callAPIMiddleware(props) {
  const { type, payload } = props;

  // Modifier for the type prop
  const typeSplit = _.split(type, "_");
  const element = typeSplit[0];
  const action = typeSplit[1];

  // Switch over the possible Actions to identify the method type for the API request
  let method;
  switch (action) {
    case "FETCHLIST":
      method = "get";
      break;
    case "FETCH":
      method = "get";
      break;
    case "CREATE":
      method = "post";
      break;
    case "EDIT":
      method = "patch";
      break;
    case "DELETE":
      method = "delete";
      break;
    default:
      break;
  }

  // Switch over the route of the selected element
  let route;
  switch (element) {
    case "PROPERTY":
      route = "/properties";
      break;
    case "EXTERNALPROPERTY":
      route = "/external/properties";
      break;
    case "EXTERNALPROPERTYDETAILS":
      route = "/external/properties";
      break;
    case "EXTERNALPROPERTYSTAY":
      route = `/external/properties/${payload.propertyId}/stays`;
      break;
    case "EXTERNALSTAY":
      route = "/external/stays";
      break;
    default:
      break;
  }

  // Modifier for the payload prop
  let _id = "";
  let params = {};
  let data = {};

  // Check if payload is defined
  if (typeof payload !== "undefined") {
    if (typeof payload._id !== "undefined") {
      _id = payload._id;
    }
    if (typeof payload.params !== "undefined") {
      params = payload.params;
    }
    if (typeof payload.data !== "undefined") {
      data = payload.data;
    }
  }

  // get Header with Authentication Token
  const headers = authHeader();

  try {
    const res = yield call(snowtrade, `/api${route}/${_id}`, {
      method,
      params,
      data,
      headers,
    });
    yield put({ type: `${element}_${action}_SUCCESS`, payload: res.data });
  } catch (error) {
    console.log(error);
    if (!_.isNil(error.response)) {
      if (error.response.status === 401) {
        yield put(logout());
      } else if (error.response.status === 404) {
        history.push("/cms/404");
      }
    }
    yield put({ type: `${element}_${action}_FAILURE` });
    yield put(alertActions.error(error.message));
  }
}
