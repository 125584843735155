/**
 * Creates a function that converts the change event and calls the provided changeHandler with
 * arguments (name, value).
 *
 * @param {function} changeHandler - A change handler that takes (path, value) as arguments.
 */
export const createInputChangeEventHandler = (changeHandler) => ({
  target,
}) => {
  const { name, value } = target;
  changeHandler(name, value);
};

/**
 * Takes a onCheckboxClick event and calls the given change handler with name and value of the event source.
 *
 * @param {function} changeHandler - The change handler to call with (name, value).
 */
export const createCheckboxClickEventHandler = (changeHandler) => (
  _event,
  data
) => changeHandler(data.name, data.checked);

/**
 * Takes an event that occurs when the value of a Semantic UI Dropdown Selection is changed
 * and calls the given change handler with name and value of the dropdown.
 *
 * @param {function} changeHandler - The change handler to call with (name, value).
 */
export const createSelectionEventHandler = (changeHandler) => (_event, data) =>
  changeHandler(data.name, data.value);

/**
 * Creates a change handler for grouped values.
 *
 * @param {function} parentOnChange - The onChange function of the parent to call. Takes (path, value) as arguments.
 * @param {string} groupName - The name of the value group.
 * @param {object} groupValues - The current group values.
 */
export const createFunctionalGroupChangeHandler = (
  parentOnChange,
  groupName,
  groupValues
) => (name, value) => {
  const groupValuesBefore = groupValues || {};
  const newGroupValues = { ...groupValuesBefore, [name]: value };
  parentOnChange(groupName, newGroupValues);
};
