import React, { Component } from "react";

import { connect } from "react-redux";

import { Modal } from "../../modal";
import {
  fetchExternalPropertyDetails,
  fetchExternalPropertyStays,
} from "../../operator";
import { fetchProperty, editProperty } from "../actions";
import PropertyDetails from "./PropertyDetails";

export class PropertyDetailsContainer extends Component {
  componentDidMount() {
    this.props.fetchProperty(this.props.match.params._id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params._id !== prevProps.match.params._id) {
      this.props.fetchProperty(this.props.match.params._id);
    }
  }

  onSyncButtonClick = () => {
    this.props.fetchExternalPropertyDetails(this.props.match.params._id);
  };

  onChangePropertyStatus = (newPropertyStatus) => {
    this.props.editProperty(this.props.match.params._id, {
      status: newPropertyStatus,
    });
  };

  onStaySyncButtonClick = () => {
    this.props.fetchExternalPropertyStays(this.props.match.params._id);
  };

  render() {
    const { property, stays } = this.props;
    if (!property) {
      return <div />;
    }

    var isDelete = false;
    this.props.history.location.search === "?deleteModal"
      ? (isDelete = true)
      : (isDelete = false);

    const editPropertyLink = `/cms/properties/${property._id}/editor`;

    return (
      <>
        <Modal
          title={`${property.name} - ${property.city}`}
          content={`Are you sure that you want to delete this property`}
          onDismiss={() =>
            this.props.history.push(this.props.history.location.pathname)
          }
          actions={
            <>
              <button
                className="ui button"
                onClick={() =>
                  this.props.history.push(this.props.history.location.pathname)
                }
              >
                Dismiss
              </button>
              <button
                className="ui negative button"
                onClick={() => {
                  this.props.history.push(
                    this.props.editProperty(this.props.match.params._id, {
                      deleted: true,
                    })
                  );
                  this.props.history.push("/cms");
                }}
              >
                Delete
              </button>
            </>
          }
          active={isDelete}
          modalType="mini"
        />
        <PropertyDetails
          property={property}
          stays={stays}
          onSyncButtonClick={this.onSyncButtonClick}
          onChangePropertyStatus={this.onChangePropertyStatus}
          onUnpublishingButtonClick={this.onUnpublishingButtonClick}
          onDeleteButtonClick={this.onDeleteButtonClick}
          onStaySyncButtonClick={this.onStaySyncButtonClick}
          editPropertyLink={editPropertyLink}
        />
      </>
    );
  }
}

//
// Redux Connect
//

const mapStateToProps = ({ properties }, ownProps) => {
  return {
    property: properties.items[ownProps.match.params._id],
  };
};

export default connect(mapStateToProps, {
  fetchProperty,
  fetchExternalPropertyDetails,
  fetchExternalPropertyStays,
  editProperty,
})(PropertyDetailsContainer);
