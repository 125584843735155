import { snowtrade } from "../../api";

export const fetchUser = (userId) =>
  snowtrade.get(`/api/user/${userId}`).then((response) => response.data);

export const sendPatchUserRequest = (newUser) =>
  snowtrade
    .patch(`/api/users/${newUser._id}`, newUser)
    .then((response) => response.data)
    .catch((e) => {
      throw new Error(e.response.data);
    });
