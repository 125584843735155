import React from 'react';

import ReactDOM from 'react-dom';

const Modal = props => {
  //const className = `${}`
  const { title, content, actions, modalType, active, icon } = props;

  return ReactDOM.createPortal(
    <div
      onClick={props.onDismiss}
      className={`ui dimmer modals page ${active ? 'active' : ''} transition `}>
      <div
        onClick={e => e.stopPropagation()}
        className={`ui ${
          modalType ? modalType : 'standard'
        } modal transition visible active small`}>
        <div className={`ui header ${icon ? 'icon' : ''}`}>
          {icon ? <i className={`${icon} icon`} /> : ''}
          {title}
        </div>
        <div className="content">
          <p>{content}</p>
        </div>
        <div className="actions">{actions}</div>
      </div>
    </div>,
    document.querySelector('#modal')
  );
};

export default Modal;
