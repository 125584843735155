import React from "react";

import { withRouter } from "react-router-dom";
import { Button, Icon, Modal } from "semantic-ui-react";

import { snowtrade } from "../api";
import { authHeader } from "../helpers";

/**
 * A hacky component to create new properties. Renders a Button which invokes a
 * modal when clicked. This modal asks again if the user wants to create a new
 * property. If so, it issues a POST request to the backend. On response, it
 * shows a button to directly navigate to the property editor.
 *
 * @augments {React.PureComponent<State, Props>}
 */
class CreatePropertyButton extends React.PureComponent {
  state = { modalOpen: false, createdProperty: null, isCreating: false };

  toggleModalOpen = () => this.setState({ modalOpen: !this.state.modalOpen });

  createNewProperty = async () => {
    this.setState({ isCreating: true });
    const headers = authHeader();
    const response = await snowtrade.post("/api/properties", {}, { headers });
    const createdProperty = response.data;
    this.setState({ isCreating: false, createdProperty });
  };

  onEditPropertyClick = () => {
    this.props.history.push(
      `/cms/properties/${this.state.createdProperty._id}/editor`
    );
  };

  render() {
    return (
      <>
        <Button onClick={this.toggleModalOpen}>Create New Property</Button>
        <Modal basic open={this.state.modalOpen} onClose={this.toggleModalOpen}>
          {this.state.createdProperty ? (
            <>
              <Modal.Content>Property created!</Modal.Content>
              <Modal.Actions>
                <Button positive inverted onClick={this.onEditPropertyClick}>
                  Edit Property
                </Button>
              </Modal.Actions>
            </>
          ) : (
            <>
              <Modal.Content>
                Do you want to create a new property?
              </Modal.Content>
              <Modal.Actions>
                <Button
                  basic
                  color="red"
                  inverted
                  onClick={this.toggleModalOpen}
                >
                  <Icon name="remove" /> No
                </Button>
                <Button
                  color="green"
                  inverted
                  onClick={this.createNewProperty}
                  loading={this.state.isCreating}
                >
                  <Icon name="checkmark" /> Yes
                </Button>
              </Modal.Actions>
            </>
          )}
        </Modal>
      </>
    );
  }
}

export default withRouter(CreatePropertyButton);
