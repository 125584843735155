/* eslint-disable react/prop-types */

import React from "react";

import { Form } from "semantic-ui-react";

import {
  createInputChangeEventHandler,
  createSelectionEventHandler,
} from "../../form/changeHandlers";

export const GeneralInformationForm = ({ values = {}, onChange }) => {
  return (
    <Form>
      <Form.Group widths="equal">
        <PropertyNameInput value={values.name} onChange={onChange} />
        <PropertyTypeInput value={values.propertyType} onChange={onChange} />
        <PropertyCurrencyInput value={values.currency} onChange={onChange} />
      </Form.Group>
    </Form>
  );
};

//
// Property Name Input
//

const PropertyNameInput = ({ value, onChange }) => (
  <Form.Input
    label="Property name"
    name="name"
    value={value}
    onChange={createInputChangeEventHandler(onChange)}
    placeholder="The name of the property"
  />
);

//
// Property Type Input
//

const PROPERTY_TYPE_OPTIONS = [
  { key: "chalet", text: "Chalet", value: "Chalet" },
  { key: "apartment", text: "Apartment", value: "Apartment" },
  { key: "villa", text: "Villa", value: "Villa" },
];

const PropertyTypeInput = ({ value, onChange }) => (
  <Form.Dropdown
    label="Property Type"
    selection
    name="propertyType"
    value={value || ""}
    onChange={createSelectionEventHandler(onChange)}
    options={PROPERTY_TYPE_OPTIONS}
  />
);

//
// Property Currency Input
//

const PROPERTY_CURRENCY_OPTIONS = [
  { key: "EUR", text: "EUR", value: "EUR" },
  { key: "CHF", text: "CHF", value: "CHF" },
  { key: "USD", text: "USD", value: "USD" },
];

const PropertyCurrencyInput = ({ value, onChange }) => (
  <Form.Select
    label="Currency"
    name="currency"
    value={value || ""}
    options={PROPERTY_CURRENCY_OPTIONS}
    onChange={createSelectionEventHandler(onChange)}
  />
);
