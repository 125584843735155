// @ts-check
import React from "react";

import { Form, Header, Icon, Segment } from "semantic-ui-react";

import { BedTypeSelect } from "./BedTypeSelect";
import { SleepsInputSegment } from "./SleepsInputSegment";

/**
 * @param {{
 *   bed: import("../../../hotelRoomTypes").HotelRoomBed;
 *   bedIndex: number;
 *   onChange: any;
 *   onRemove: any;
 * }} props
 */
export const BedSegment = ({ bed, bedIndex, onChange, onRemove }) => {
  const handleBedUpdate = (update) => onChange(bedIndex, { ...bed, ...update });

  const handleCheckboxClick = (e, { name, checked }) =>
    onChange(bedIndex, { ...bed, [name]: checked });

  const header = `Bed #${bedIndex + 1}`;
  return (
    <Segment className="boxed colored">
      <Header>{header}</Header>
      <Icon
        name="remove"
        style={{ position: "absolute", top: ".5em", right: ".5em" }}
        link
        onClick={() => onRemove(bedIndex)}
      />
      <BedTypeSelect value={bed.bedType} onUpdate={handleBedUpdate} />

      <SleepsInputSegment
        onUpdate={handleBedUpdate}
        sleeps={bed.sleeps}
        bedIndex={bedIndex}
      />

      <Form.Checkbox
        label="Is in standard occupancy"
        checked={bed.isInStandardOccupancy}
        id={`isInStandard-${bedIndex}`}
        // @ts-ignore
        onClick={handleCheckboxClick}
        name="isInStandardOccupancy"
      />
      <Form.Checkbox
        label="Twin bed possible"
        id={`twinBedPossible-${bedIndex}`}
        checked={bed.twinBedPossible}
        // @ts-ignore
        onClick={handleCheckboxClick}
        name="twinBedPossible"
      />
    </Segment>
  );
};
