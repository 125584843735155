import React from "react";

import { isObject, upperFirst } from "lodash";
import { Form } from "semantic-ui-react";

const SelectMultiple = ({ input, label, type, placeholder, options }) => {
  const optionsObject = options.map((option) => {
    if (isObject(option)) {
      const { value } = option;
      return {
        key: value,
        value: value,
        text: upperFirst(value),
        description: option.description,
      };
    }
    return {
      key: option,
      value: option,
      text: upperFirst(option),
    };
  });

  return (
    <Form.Field>
      <label>{label}</label>
      <Form.Select
        placeholder={placeholder}
        fluid
        search
        multiple
        selection
        clearable
        options={optionsObject}
        type={type}
        onChange={(e, { value }) => input.onChange(value)}
        value={input.value}
      />
    </Form.Field>
  );
};

export default SelectMultiple;
