import React, { useState } from "react";

import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Form, Select } from "semantic-ui-react";

import { useUsers } from "../../../../user/hooks/useUsers";
import { editOperator } from "./api";

export const ConnectedUsersInput = ({
  connectedUsers: defaultConnectedUsers = [],
  operatorId,
}) => {
  const { users: allUsers } = useUsers();
  const [connectedUsers, setConnectedUsers] = useState(defaultConnectedUsers);
  const [isLoading, setIsLoading] = useState(false);

  const allUsersOptions = allUsers.map((user) => ({
    key: user._id,
    value: user._id,
    text: user.username,
    description: user.company,
  }));

  const onSubmit = async () => {
    setIsLoading(true);
    await editOperator(operatorId, { _users: connectedUsers });
    setIsLoading(false);
    toast.success("Saved connected users");
  };

  const onChange = (e, { value }) => setConnectedUsers(value);

  return (
    <Form>
      <Form.Field>
        <label>Connected users</label>
        <Select
          multiple
          options={allUsersOptions}
          value={connectedUsers}
          search
          onChange={onChange}
        />
      </Form.Field>
      <Form.Button primary onClick={onSubmit} loading={isLoading}>
        Save users
      </Form.Button>
    </Form>
  );
};

//
// Prop Types
//

ConnectedUsersInput.propTypes = {
  operatorId: PropTypes.string.isRequired,
  connectedUsers: PropTypes.arrayOf(PropTypes.string),
};
