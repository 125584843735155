import axios from "axios";

import { authHeader } from "../helpers/authHeader";

export const baseURL = window.location.href.includes("localhost")
  ? "http://localhost:5000"
  : "https://db.snowtrade-royale.com";

/**
 * AxiosInstance set to work against the STR backend. Bearer token will be set
 * automatically on each request.
 */
export const snowtrade = axios.create({
  baseURL,
  timeout: 330000,
});

// Set an interceptor to apply the bearer token to the request header.
snowtrade.interceptors.request.use((config) => {
  const { Authorization } = authHeader();
  config.headers.Authorization = Authorization;
  return config;
});
