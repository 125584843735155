import React from "react";

import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import { createFunctionalGroupChangeHandler } from "../../../form/changeHandlers";
import { PropertyCityInput } from "./PropertyCityInput";
import { PropertyCoordinatesInput } from "./PropertyCoordinatesInput";
import { PropertyCountryInput } from "./PropertyCountryInput";
import { PropertySectorInput } from "./PropertySectorInput";

/**
 * Renders a Semantic UI form displaying input fields for the location of the
 * property including:
 *
 * - City
 * - Sector
 * - Country
 * - Coordinates
 */
export const PropertyLocationForm = ({ values = {}, onChange }) => {
  const { address = {} } = values;

  const addressChangeHandler = createFunctionalGroupChangeHandler(
    onChange,
    "address",
    address
  );

  return (
    <Form>
      <Form.Group widths="equal">
        <PropertyCityInput value={values.city} onChange={onChange} />
        <PropertyCountryInput
          value={address.country}
          onChange={addressChangeHandler}
        />
        <PropertySectorInput
          value={address.sector}
          onChange={addressChangeHandler}
        />
      </Form.Group>
      <PropertyCoordinatesInput
        coordinates={values.coordinates}
        onChange={onChange}
      />
    </Form>
  );
};

//
// Prop Types
//

PropertyLocationForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    city: PropTypes.string,
    coordinates: PropTypes.object,
    address: PropTypes.shape({
      country: PropTypes.string,
      sector: PropTypes.string,
    }),
  }),
};
