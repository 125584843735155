import React from "react";

import { Form } from "semantic-ui-react";

import {
  createFunctionalGroupChangeHandler,
  createInputChangeEventHandler,
} from "../../../form/changeHandlers";

export const PropertyCoordinatesInput = ({ coordinates = {}, onChange }) => {
  const coordinatesChangeHandler = createFunctionalGroupChangeHandler(
    onChange,
    "coordinates",
    coordinates
  );
  const onCoordinatesInputChange = createInputChangeEventHandler(
    coordinatesChangeHandler
  );

  return (
    <Form.Group widths="equal">
      <Form.Input
        name="latitude"
        label="Latitude"
        value={coordinates.latitude}
        onChange={onCoordinatesInputChange}
      />
      <Form.Input
        name="longitude"
        label="Longitude"
        value={coordinates.longitude}
        onChange={onCoordinatesInputChange}
      />
    </Form.Group>
  );
};
