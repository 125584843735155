/* eslint-disable react/prop-types */
import React from "react";

import { Form, Header } from "semantic-ui-react";

export const LayoutForm = ({ values, onChange }) => {
  const handleChange = ({ target }) => {
    const { name, value } = target;
    onChange(name, value);
  };
  return (
    <Form>
      <Header>Layout</Header>
      <Form.Group widths="equal">
        <Form.Input
          label="Min Size in Sqm"
          name="sizeSqmMin"
          value={values.sizeSqmMin || ""}
          placeholder="In Squaremeters"
          onChange={handleChange}
          type="number"
        />
        <Form.Input
          label="Max Size in Sqm"
          name="sizeSqmMax"
          value={values.sizeSqmMax || ""}
          placeholder="Leave empty if same as min size"
          onChange={handleChange}
          type="number"
          min={values.sizeSqmMin}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          name="numBedrooms"
          value={values.numBedrooms || ""}
          label="Number of Bedrooms"
          onChange={handleChange}
          type="number"
          min={0}
        />
        <Form.Input
          name="numBathrooms"
          value={values.numBathrooms || ""}
          label="Number of Bathrooms"
          onChange={handleChange}
          type="number"
          min={0}
        />
      </Form.Group>
    </Form>
  );
};
