import React, { useState } from "react";

import { map } from "lodash";
import { toast } from "react-toastify";
import { Button, Icon, Modal } from "semantic-ui-react";

import { snowtrade } from "../api";
import { EmailForm } from "./EmailForm";

/** @param {{ selectedUsers: import("./constants").User[] }} props */
export const SendNewsLetterButton = ({ selectedUsers }) => {
  const [serverResponse, setServerResponse] = useState(
    /**
     * @type {null | {
     *   startSendingAt: string;
     *   recipients: import("./constants").User[];
     * }}
     */ (null)
  );

  /** @type {import("./EmailForm").EmailFormSubmitHandler} */
  const handleSubmit = (email) => {
    return snowtrade
      .post(`/api/users/mass-emails`, {
        email,
        userQuery: { _id: { $in: map(selectedUsers, "_id") } },
      })
      .then((response) => setServerResponse(response.data))
      .catch(() => toast.error("oops"));
  };

  return (
    <Modal
      trigger={
        <Button
          disabled={selectedUsers.length === 0}
          content="Send Newsletter"
        />
      }
    >
      <Modal.Header>
        Send newsletter to {selectedUsers.length} users
      </Modal.Header>
      <Modal.Content>
        {serverResponse ? (
          <>
            <Icon size="big" color="green" name="check" />
            <p>
              Start sending newsletter to {selectedUsers.length} users at{" "}
              {new Date(serverResponse.startSendingAt).toLocaleTimeString()}
            </p>
          </>
        ) : (
          <EmailForm handleSubmit={handleSubmit} />
        )}
      </Modal.Content>
    </Modal>
  );
};
