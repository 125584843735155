import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import ReactTable from "react-table";
import { useToggle } from "react-use";
import { Button, Checkbox } from "semantic-ui-react";

import { reactTableRegexFilterMethod } from "../helpers/reactTableRegexFilterMethod";
import { useUsers } from "./hooks/useUsers";
import { SendNewsLetterButton } from "./SendNewsletterButton";
import { UserEmailIcon } from "./UserEmailIcon";
import { UserTokenIcon } from "./UserTokenIcon";

export const UsersTable = () => {
  const { users, isFetching } = useUsers();
  const [data, setData] = useState(
    /** @type {(import("./constants").User & { isSelected?: Boolean })[]} */ (
      users
    )
  );
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [shouldSelectAll, toggleSelectAll] = useToggle(true);

  useEffect(() => {
    setData(users);
  }, [users]);

  console.log(data);

  const columns = [
    {
      Header: () => (
        <Checkbox
          checked={isAllSelected}
          onChange={() => {
            setData((oldData) =>
              oldData.map((row) => ({ ...row, isSelected: shouldSelectAll }))
            );
            setIsAllSelected(shouldSelectAll);
            toggleSelectAll();
          }}
        />
      ),
      Cell: (row) => (
        <Checkbox
          onChange={() => {
            const newData = [...data];
            newData[row.index] = {
              ...data[row.index],
              isSelected: !data[row.index].isSelected,
            };
            setData(newData);
            setIsAllSelected(false);
          }}
          checked={row.original.isSelected}
        />
      ),
      maxWidth: 38,
      sortable: false,
      filterable: false,
    },
    {
      Header: "",
      Cell: (row) => (
        <Link to={`/cms/users/${row.original._id}`}>
          <i className="external alternate icon" />
        </Link>
      ),
      maxWidth: 38,
    },
    {
      Header: "",
      Cell: (row) => <UserTokenIcon userId={row.original._id} />,
      maxWidth: 38,
    },
    {
      Header: "",
      Cell: (row) => (
        <UserEmailIcon user={row.original} key={row.original._id} />
      ),
      maxWidth: 38,
    },
    { Header: "Last name", accessor: "lastName", filterable: true },
    { Header: "First name", accessor: "firstName", filterable: true },
    { Header: "Username", accessor: "username", filterable: true },
    { Header: "Role", accessor: "role" },
    { Header: "Company", accessor: "company", filterable: true },
    { Header: "Email", accessor: "email", filterable: true },
    {
      Header: "Last Online",
      accessor: "lastOnline",
      Cell: (row) =>
        row.original.lastOnline
          ? new Date(row.original.lastOnline).toLocaleString()
          : null,
    },
  ];

  const selectedUsers = data.filter((u) => u.isSelected);

  return (
    <>
      <ReactTable
        className="-striped"
        data={data}
        loading={isFetching}
        columns={columns}
        defaultFilterMethod={reactTableRegexFilterMethod}
      />
      <p style={{ color: "grey" }}>{selectedUsers.length} selected</p>
      <Link to="/cms/users/new">
        <Button>New User</Button>
      </Link>
      <SendNewsLetterButton selectedUsers={selectedUsers} />
    </>
  );
};
