import _ from "lodash";

import { searchTypes } from "./types";

export const filterSearchResults = (formValues, properties, stays) => {
  let results = properties;

  const {
    propertyTypeArray,
    serviceLevelArray,
    countryArray,
    cityArray,
    sectorArray,
    propertyStatusArray,
    operatorArray,
    operatorContactStatus,
    ownerContactStatus,
  } = formValues;
  const bedrooms = _.toNumber(formValues.bedrooms);
  const adults = _.toNumber(formValues.adults);
  const children = _.toNumber(formValues.children);
  const checkInDate = new Date(formValues.checkIn).toJSON();
  const checkOutDate = new Date(formValues.checkOut).toJSON();
  const checkInTimestamp = new Date(formValues.checkIn).getTime();
  const checkOutTimestamp = new Date(formValues.checkOut).getTime();

  if (!_.isNil(propertyStatusArray) && !_.isEmpty(propertyStatusArray)) {
    // Filter PUBLISHEDONLY
    results = _.flattenDeep(
      propertyStatusArray.map((status) => {
        return _.filter(results, { status });
      })
    );
  }

  if (operatorArray && !_.isEmpty(operatorArray)) {
    // Filter OPERATOR
    results = _.flattenDeep(
      operatorArray.map((operator) => {
        return _.filter(results, { operator });
      })
    );
  }

  if (operatorContactStatus) {
    const filterFunction = (p) => {
      const isOperatorActive = _.get(p, "contact.operator.isActive") || false;
      const shouldOperatorBeActive = operatorContactStatus === "active";
      return isOperatorActive === shouldOperatorBeActive;
    };
    results = results.filter(filterFunction);
  }

  if (ownerContactStatus) {
    const filterFunction = (p) => {
      const isOwnerActive = _.get(p, "contact.owner.isActive") || false;
      const shouldOwnerBeActive = ownerContactStatus === "active";
      return isOwnerActive === shouldOwnerBeActive;
    };
    results = results.filter(filterFunction);
  }

  if (!_.isNil(propertyTypeArray) && !_.isEmpty(propertyTypeArray)) {
    // Filter PROPERTYTYPE
    results = _.flattenDeep(
      propertyTypeArray.map((propertyType) => {
        return _.filter(results, { propertyType });
      })
    );
  }

  if (!_.isNil(serviceLevelArray) && !_.isEmpty(serviceLevelArray)) {
    // Filter SERVICETYPE
    results = _.flattenDeep(
      serviceLevelArray.map((level) => {
        return _.filter(results, (o) => {
          if (_.includes(o.serviceLevels, level)) {
            return o;
          }
        });
      })
    );
    results = _.uniqBy(results, "_id");
  }

  if (!_.isNil(sectorArray) && !_.isEmpty(sectorArray)) {
    // Filter SECTOR
    results = _.flattenDeep(
      sectorArray.map((sector) => {
        return _.filter(results, (property) => {
          return !_.isNil(property.address)
            ? property.address.sector === sector
            : null;
        });
      })
    );
  }

  if (!_.isNil(cityArray) && !_.isEmpty(cityArray)) {
    // Filter CITY
    results = _.flattenDeep(
      cityArray.map((city) => {
        return _.filter(results, { city });
      })
    );
  }

  if (!_.isNil(countryArray) && !_.isEmpty(countryArray)) {
    // Filter COUNTRY
    results = _.flattenDeep(
      countryArray.map((country) => {
        return _.filter(results, (property) => {
          return !_.isNil(property.address)
            ? property.address.country === country
            : null;
        });
      })
    );
  }

  if (!_.isNaN(bedrooms)) {
    // Filter BEDROOMS
    const bedroomRange = _.range(bedrooms, bedrooms + 3);
    results = _.flattenDeep(
      bedroomRange.map((bedrooms) => {
        return _.filter(results, { bedrooms });
      })
    );
  }

  if (!_.isNaN(adults) && adults > 0 && !_.isNaN(children)) {
    // Filter ADULTS & CHILDREN
    const sumPeople = adults + children;

    results = _.filter(results, (o) => {
      if (
        o.beds &&
        o.beds.adults + o.beds.children >= sumPeople &&
        o.beds.adults >= adults &&
        o.beds.adults + o.beds.children < sumPeople + 3
      ) {
        return o;
      }
    });
  } else if (!_.isNaN(adults) && _.isNaN(children)) {
    // Filter ADULTS ONLY
    results = _.filter(results, (o) => {
      if (o.beds && o.beds.adults >= adults && o.beds.adults < adults + 3) {
        return o;
      }
    });
  } else if ((_.isNaN(adults) || adults === 0) && !_.isNaN(children)) {
    // Filter CHILDREN only... shouldn't be possible
    results = [];
  }

  if (checkInDate && checkOutDate) {
    //Filter CHECKIN and CHECKOUT dayly
    results = _.filter(results, (property) => {
      let availableDaysArray = [];

      for (let i = checkInTimestamp; i <= checkOutTimestamp; i = i + 86400000) {
        const jsonDate = new Date(i).toJSON();

        const matchingStays = _.find(stays[property._id], (stay) => {
          if (
            stay.available &&
            stay.checkIn <= jsonDate &&
            jsonDate <= stay.checkOut
          ) {
            return true;
          }
          return false;
        });

        if (!_.isNil(matchingStays)) {
          availableDaysArray.push(true);
        } else {
          availableDaysArray.push(false);
        }
      }

      return _.every(availableDaysArray);
    });

    results = _.uniqBy(results, "_id");
  }

  return { type: searchTypes.FILTER_RESULTS, payload: results };
};

/** Returns options for several dropdown fields on the website. */
export const getDropdownOptions = (properties, searchValues) => {
  let propertyTypeArray = [];
  let serviceLevelArray = [];
  let countryArray = [];
  let cityArray = [];
  let sectorArray = [];
  let operatorArray = [];

  properties.forEach((property) => {
    const propertyType = !_.isNil(property.propertyType)
      ? property.propertyType
      : null;
    propertyTypeArray.push(propertyType);

    if (property.serviceLevels) {
      property.serviceLevels.map((level) => {
        return serviceLevelArray.push(level);
      });
    }

    if (property.address) {
      const country = !_.isNil(property.address.country)
        ? property.address.country
        : null;
      countryArray.push(country);

      const city =
        !_.isNil(property.city) &&
        !_.isNull(country) &&
        (_.isNil(searchValues) ||
          _.isEmpty(searchValues.countryArray) ||
          _.includes(searchValues.countryArray, country))
          ? property.city
          : null;
      const cityObject = !_.isNull(city)
        ? { value: city, description: country }
        : null;
      cityArray.push(cityObject);

      const sector =
        !_.isNil(property.address.sector) &&
        !_.isNull(city) &&
        (_.isNil(searchValues) ||
          _.isEmpty(searchValues.cityArray) ||
          _.includes(searchValues.cityArray, city))
          ? { value: property.address.sector, description: city }
          : null;

      sectorArray.push(sector);
    }

    if (property.operator) {
      operatorArray.push(property.operator);
    }
  });

  // Modify PropertyTypeArray
  const uniqPropertyTypeArray = _.uniqBy(propertyTypeArray);
  const compactPropertyTypeArray = _.compact(uniqPropertyTypeArray);
  const sortPropertyTypeArray = _.sortBy(compactPropertyTypeArray);
  propertyTypeArray = sortPropertyTypeArray;

  // Modify ServiceLevelArray
  const uniqServiceLevelArray = _.uniqBy(serviceLevelArray);
  const compactServiceLevelArray = _.compact(uniqServiceLevelArray);
  const sortServiceLevelArray = _.sortBy(compactServiceLevelArray);
  serviceLevelArray = sortServiceLevelArray;

  // Modify countryArray
  const uniqCountryArray = _.uniqBy(countryArray);
  const compactCountryArray = _.compact(uniqCountryArray);
  const sortCountryArray = _.sortBy(compactCountryArray);
  countryArray = sortCountryArray;

  // Modify cityArray
  const compactCityArray = _.compact(cityArray);
  const uniqCityArray = _.uniqBy(compactCityArray, (o) => {
    return o.value;
  });
  const sortCityArray = _.sortBy(uniqCityArray, "value");
  cityArray = sortCityArray;

  // Modify sectorArray
  const compactSectorArray = _.compact(sectorArray);
  const uniqSectorArray = _.uniqBy(compactSectorArray, (o) => {
    return o.value;
  });
  const sortSectorArray = _.sortBy(uniqSectorArray, "value");
  sectorArray = sortSectorArray;

  // Modify operatorArray
  operatorArray = _.uniq(operatorArray);
  operatorArray = _.orderBy(operatorArray);

  const dropdownOptions = {
    propertyTypeArray,
    serviceLevelArray,
    countryArray,
    cityArray,
    sectorArray,
    operatorArray,
  };

  return { type: searchTypes.DROPDOWN_OPTIONS, payload: dropdownOptions };
};
