import React from "react";

import _ from "lodash";

import { CheckBoxArrayForm } from "./CheckBoxArrayForm";

class CheckBoxArrayContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
  }

  initialState() {
    const { labels, value } = this.props;
    let initialState = {};
    labels.forEach(label => {
      const camelCase = _.camelCase(label);
      const checked = value && value.includes(label);
      initialState[camelCase] = {
        label,
        checked
      };
    });
    return initialState;
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.labels) !== JSON.stringify(this.props.labels) || prevProps.value !== this.props.value
    ) {
      this.setState(this.initialState());
    }
  }

  onChange = (_event, { name, checked }) => {
    const oldValue = { ...this.state[name] };
    this.setState({ [name]: { ...oldValue, checked } }, this.externalOnChange);
  };

  externalOnChange = () => {
    if (this.props.onChange) {
      let valueArray = [];
      for (let key in this.state) {
        if (this.state[key].checked) {
          valueArray.push(this.state[key].label);
        }
      }

      this.props.onChange({
        target: { name: this.props.name, value: valueArray }
      });
    }
  };

  render() {
    return (
      <CheckBoxArrayForm
        {...this.props}
        onChange={this.onChange}
        values={this.state}
      />
    );
  }
}

export { CheckBoxArrayContainer };
