import { useHotelRates } from "./useHotelRates";

/**
 * Returns an array of rate options for the given hotel. Use these options in a
 * Semantic UI Select field.
 *
 * @param {string} hotelId
 */
export const useHotelRatesOptions = (hotelId) => {
  const { rates } = useHotelRates(hotelId);

  const rateOptions = rates.map((hotelRate) => ({
    value: hotelRate._id,
    key: hotelRate._id,
    text: hotelRate.rateName,
  }));

  return { rateOptions };
};
