/* eslint-disable react/prop-types */
import React from "react";

import { RoomAmadeusManager } from "./RoomAmadeusManager";

export const RoomAmadeusManagerComponent = ({ match }) => {
  return (
    <RoomAmadeusManager
      roomId={match.params.roomId}
      hotelId={match.params.hotelId}
    />
  );
};
