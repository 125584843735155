import React from "react";

const http404 = () => {
  return (
    <div>
      <h1 className="ui header">404 - Not found</h1>
      <div className="ui segment">
        Something went wrong! Source does not exist.
      </div>
    </div>
  );
};

export default http404;
