import React from "react";

import { Grid } from "semantic-ui-react";

import { CreateOperator } from "./CreateOperator";
import { OperatorPanel } from "./OperatorPanel";

export const OperatorPage = () => (
  <Grid divided="vertically">
    <Grid.Row>
      <OperatorPanel />
    </Grid.Row>
    <Grid.Row>
      <CreateOperator />
    </Grid.Row>
  </Grid>
);
