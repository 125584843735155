import React, { Component } from 'react';

import { connect } from 'react-redux';

import { setPageSize } from './actions';
import PaginationPageSizeDropdown from './PaginationPageSizeDropdown';

export class PaginationPageSizeDropdownContainer extends Component {
  onItemClick = pageSize => {
    this.props.setPageSize(pageSize);
  };

  render() {
    const { pageSize, total, activePage } = this.props;

    const offset = (activePage - 1) * pageSize;
    const currentShownData = `${offset + 1} - ${
      offset + pageSize > total ? total : offset + pageSize
    } of ${total}`;

    return (
      <div>
        <PaginationPageSizeDropdown
          currentPageSize={pageSize}
          currentShownData={currentShownData}
          onItemClick={this.onItemClick}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ pagination }) => {
  const { pageSize, total, activePage } = pagination;
  return { pageSize, total, activePage };
};

export default connect(
  mapStateToProps,
  { setPageSize }
)(PaginationPageSizeDropdownContainer);
