import React, { useEffect, useMemo } from "react";

import { forOwn, isEmpty, orderBy, toNumber } from "lodash";
import { toast } from "react-toastify";
import { Button, Input } from "semantic-ui-react";

import { snowtrade } from "../../api/snowtrade";

export const OrderForm = ({ rooms }) => {
  const [numberByRoomId, setNumberByRoomId] = React.useState({});

  const orderedRooms = useMemo(() => orderBy(rooms, ["minimumPrice"]), [rooms]);

  useEffect(() => {
    if (isEmpty(numberByRoomId) && !isEmpty(orderedRooms)) {
      const newNumberByRoomId = {};
      orderedRooms.forEach((room, index) => {
        newNumberByRoomId[room._id] = index + 1;
      });
      setNumberByRoomId(newNumberByRoomId);
    }
  }, [numberByRoomId, orderedRooms]);

  const handleSaveOrderClick = () => {
    forOwn(numberByRoomId, (displayNumber, roomId) => {
      snowtrade
        .patch(`/api/hotelrooms/${roomId}`, { displayNumber })
        .then(() => toast.success("Saved"));
    });
  };

  const handleReorderConflictingClick = (editedRoomId) => {
    // every room with a displayNumber >= the room we're reordering, increment by 1
    const newNumberByRoomId = {};
    forOwn(numberByRoomId, (displayNumber, roomId) => {
      if (
        roomId !== editedRoomId &&
        displayNumber >= numberByRoomId[editedRoomId]
      ) {
        newNumberByRoomId[roomId] = toNumber(displayNumber) + 1;
      } else {
        newNumberByRoomId[roomId] = toNumber(displayNumber);
      }
    });
    setNumberByRoomId(newNumberByRoomId);
  };

  return (
    <>
      {orderBy(rooms, "minimumPrice").map((room) => (
        <div key={room._id} style={{ marginBottom: "1em" }}>
          {JSON.stringify(room)}
          <Input
            type="number"
            onFocus={(e) => e.target.select()}
            value={numberByRoomId[room._id] || ""}
            style={{ width: "6em" }}
            onChange={(e, { value }) =>
              setNumberByRoomId((prev) => ({ ...prev, [room._id]: value }))
            }
            action={{
              content: "Reorder conflicting",
              onClick: () => {
                handleReorderConflictingClick(room._id);
              },
            }}
          />
        </div>
      ))}
      <Button primary content="Save Order" onClick={handleSaveOrderClick} />
    </>
  );
};
