import React from 'react';

import { withGoogleMap, withScriptjs } from "react-google-maps";

import { MapsView } from './MapsView'


const mapsUrl = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyArSf5tSt3gUsT8fYXHzP9KqjIn2w3tTUg`;


const MapWrapped = withScriptjs(withGoogleMap(MapsView));

const MapsContainer = ({coordinates}) => {
  return (
    <div
      className="mapsContainer"
      style={{ height: "100%", width: "100%"}}
    >
      <MapWrapped
        googleMapURL={mapsUrl}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        coordinates={coordinates}
      />
    </div>
  );
}

export default MapsContainer;