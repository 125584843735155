import { all } from "redux-saga/effects";

import { watchLogin, watchLogout } from "../login";
import { watchExternals } from "../operator";
import { watchProperties } from "../property";
import { watchStays } from "../stay";

export default function* rootSaga() {
  yield all([
    watchLogin(),
    watchLogout(),
    watchProperties(),
    watchStays(),
    watchExternals(),
  ]);
}
