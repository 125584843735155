import React from "react";

import { Form } from "semantic-ui-react";

const options = [
  { key: "confirmed", text: "Contract confirmed", value: "confirmed" },
  { key: "pending", text: "Contract pending", value: "pending" },
  { key: "none", text: "None", value: "none" },
];

/** @param {{ onChange: (name: string, value: any) => void; value?: string }} param0 */
export const ContractForm = ({ onChange, value }) => {
  return (
    <Form>
      <Form.Select
        label="Contract status"
        onChange={(e, { value }) => onChange("contractStatus", value)}
        value={value}
        options={options}
      />
    </Form>
  );
};
