/* eslint-disable react/prop-types */
import React, { useState } from "react";

import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { Button, Icon } from "semantic-ui-react";

import { syncHotelImages } from "./api";

export const SyncHotelImagesButton = ({ hotelId, tableCellDesign }) => {
  const [isSyncing, setIsSyncing] = useState(false);

  const onSyncClick = () => {
    setIsSyncing(true);
    syncHotelImages(hotelId)
      .then((imagesAndThumbnail) => {
        if (isEmpty(imagesAndThumbnail)) {
          toast.error("Could not find images for this room");
          return;
        }

        const { thumbnail, images } = imagesAndThumbnail;
        toast.success(
          `Synced ${thumbnail ? "a thumbnail and" : ""} ${images.length} images`
        );
      })
      .catch(() => toast.error("An error occurend"))
      .finally(() => setIsSyncing(false));
  };

  return (
    <Button
      fluid={tableCellDesign}
      loading={isSyncing}
      onClick={onSyncClick}
      icon
      size={tableCellDesign ? "mini" : undefined}
      compact={tableCellDesign}
    >
      <Icon name="image outline" />
      <Icon name="redo" />
    </Button>
  );
};
