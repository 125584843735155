import React, { useState } from 'react';
import './Tab.css';

const Tab = ({ tabProps }) => {
  const [active, setActive] = useState(0);

  return (
    <>
      <div className="ui top attached tabular menu" id="tabTitleBar">
        {tabProps.map((section, index) => {
          const isActive = active === index ? 'active' : '';
          return (
            <div
              key={index}
              className={`item ${isActive}`}
              onClick={() => {
                setActive(index);
              }}
            >
              {section.title}
            </div>
          );
        })}
      </div>
      {tabProps.map((section, index) => {
        const isActive = active === index ? 'active' : '';
        return (
          <div
            key={index}
            className={`ui bottom attached tab ${isActive} segment`}
          >
            <div>{section.content}</div>
          </div>
        );
      })}
    </>
  );
};

export default Tab;
