// @ts-check
import React from "react";

import { useSelector } from "react-redux";

import { ConnectedPropertiesInputView } from "./ConnectedPropertiesInputView";
import { useConnectedPropertiesSubmissionHandler } from "./useConnectedPropertiesSubmissionHandler";
import { useConnectedPropertyIds } from "./useConnectedPropertyIds";

export const ConnectedPropertiesInputContainer = ({
  initialRentalChannels,
  operatorId,
}) => {
  const {
    connectedPropertyIds,
    setConnectedPropertyIds,
  } = useConnectedPropertyIds(initialRentalChannels);
  const { submit, isSubmitting } = useConnectedPropertiesSubmissionHandler(
    operatorId
  );

  const reduxProperties = useSelector((state) => state.properties);
  const allProperties = Object.values(reduxProperties.items);

  const handleSubmitClick = () => submit(connectedPropertyIds);

  return (
    <ConnectedPropertiesInputView
      allProperties={allProperties}
      connectedPropertyIds={connectedPropertyIds}
      onChange={setConnectedPropertyIds}
      onSubmit={handleSubmitClick}
      isLoading={isSubmitting}
    />
  );
};
