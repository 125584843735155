import React, { Component } from "react";

import _ from "lodash";
import { connect } from "react-redux";

import { fetchProperties } from "../property";
import { fetchStays } from "../stay";
import { filterSearchResults, getDropdownOptions } from "./actions";
import AdvancedSearchForm from "./AdvancedSearchForm";

export class AdvancedSearchFormContainer extends Component {
  componentDidMount() {
    if (!this.props.properties.hasFetched) {
      this.props.fetchProperties(
        null,
        "name city propertyType serviceLevels address bedrooms beds thumbnail status operatorId operator currency"
      );
    }
    this.props.fetchStays();
  }

  componentDidUpdate(prevProps) {
    const { properties, advancedSearch, dropdownOptions } = this.props;

    if (properties.hasFetched && !_.isEmpty(properties.items)) {
      if (
        _.isEmpty(dropdownOptions.countryArray) &&
        _.isEmpty(dropdownOptions.propertyTypeArray)
      ) {
        this.props.getDropdownOptions(properties.items, advancedSearch.values);
      }
      if (advancedSearch.values !== prevProps.advancedSearch.values) {
        this.props.getDropdownOptions(properties.items, advancedSearch.values);
      }
    }
  }

  onFormSubmit = (formValues) => {
    this.props.filterSearchResults(
      formValues,
      this.props.properties.items,
      this.props.stays
    );
  };

  render() {
    const { advancedSearch, dropdownOptions } = this.props;
    return (
      <AdvancedSearchForm
        onSubmit={this.onFormSubmit}
        formValues={advancedSearch}
        dropdownOptions={dropdownOptions}
      />
    );
  }
}

const mapStateToProps = ({ properties, form, search, stays }) => {
  const { hasFetched, items } = properties;
  const itemArray = Object.values(items);
  const propertiesProps = { hasFetched, items: itemArray };
  const advancedSearchProps = !_.isNil(form.advancedSearch)
    ? form.advancedSearch
    : {};
  const dropdownOptionsProps = search.dropdownOptions;
  const staysProps = stays.items;

  return {
    properties: propertiesProps,
    advancedSearch: advancedSearchProps,
    dropdownOptions: dropdownOptionsProps,
    stays: staysProps,
  };
};

export default connect(mapStateToProps, {
  fetchProperties,
  filterSearchResults,
  getDropdownOptions,
  fetchStays,
})(AdvancedSearchFormContainer);
