import React from "react";

import { get } from "lodash";

import PropertyEditorContainer from "./PropertyEditorContainer";

/** Should be placed whithin a Route providing the `match` prop. */
export const PropertyEditorComponent = ({ match }) => {
  const propertyId = get(match, "params.propertyId");
  if (!propertyId) return null;

  return <PropertyEditorContainer propertyId={propertyId} />;
};
