import React from "react";

import PropTypes from "prop-types";
import { Form, Header, Icon } from "semantic-ui-react";

import { useHotelRatesOptions } from "../../../rates/useHotelRateOptions";
import { RateMapInput } from "./RateMapInput";
import { useRateMaps } from "./useRateMaps";

export const RateMapsForm = ({ strHotelId }) => {
  const {
    rateMaps,
    handleChange,
    addRateMap,
    isFetching,
    removeRateMap,
    saveRateMap,
  } = useRateMaps(strHotelId);

  const { rateOptions } = useHotelRatesOptions(strHotelId);

  return (
    <Form loading={isFetching}>
      <Header>Rate Maps</Header>
      {rateMaps.map((rateMap, index) => (
        <RateMapInput
          key={rateMap._id}
          rateMap={rateMap}
          onChange={handleChange}
          index={index}
          onRemoveClick={removeRateMap}
          onSaveClick={saveRateMap}
          hotelRatesOptions={rateOptions}
        />
      ))}
      <Form.Button icon onClick={addRateMap}>
        <Icon name="plus" />
      </Form.Button>
    </Form>
  );
};

//
// Prop Types
//

RateMapsForm.propTypes = {
  strHotelId: PropTypes.string.isRequired,
};
