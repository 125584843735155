import React from "react";

import PropTypes from "prop-types";
import { Grid, Icon } from "semantic-ui-react";

import { Accordion } from "../../accordion";
import { AccomodationInformationForm } from "./AccomodationInformation";
import { GeneralInformationForm } from "./GeneralInformation";
import { OperatorForm } from "./OperatorForm/OperatorForm";
import { PropertyDescriptionEditor } from "./PropertyDescription";
import { PropertyFacilitiesForm } from "./PropertyFacilities";
import { PropertyLocationForm } from "./PropertyLocation";
import { PropertyServiceInformationForm } from "./Services";

/**
 * Renders two icons displaying whether the system has saved the latest changing
 * or still is editing in the backend.
 */
const EditProcessStatus = ({ isEditing }) => (
  <div>
    <Icon name="save outline" />
    {isEditing ? (
      <Icon name="spinner" loading />
    ) : (
      <Icon name="check circle outline" />
    )}
  </div>
);

/**
 * This is the view component for the AdminPropertyEditor component. It defines
 * the look of the editor. The forms are imported from the ../shared folder.
 */
export const PropertyEditorView = ({ property = {}, onChange, isEditing }) => {
  if (!property) return null;

  const GeneralPanel = {
    title: "General Information",
    content: <GeneralInformationForm values={property} onChange={onChange} />,
  };

  const LocationPanel = {
    title: "Location",
    content: <PropertyLocationForm values={property} onChange={onChange} />,
  };

  const AccomodationPanel = {
    title: "Accomodation Information",
    content: (
      <AccomodationInformationForm values={property} onChange={onChange} />
    ),
  };

  const PropertyDescriptionPanel = {
    title: "Property Description",
    content: (
      <PropertyDescriptionEditor
        value={property.descriptionMarkdown}
        onChange={onChange}
      />
    ),
  };

  const PropertyFacilitiesPanel = {
    title: "Facilities",
    content: (
      <PropertyFacilitiesForm
        facilities={property.facilities}
        onChange={onChange}
        name="facilities"
      />
    ),
  };

  const PropertyServicesPanel = {
    title: "Services",
    content: (
      <PropertyServiceInformationForm property={property} onChange={onChange} />
    ),
  };

  const PropertyOperatorPanel = {
    title: "API Operator",
    content: <OperatorForm property={property} onChange={onChange} />,
  };

  const accordionPanels = [
    GeneralPanel,
    LocationPanel,
    AccomodationPanel,
    PropertyDescriptionPanel,
    PropertyFacilitiesPanel,
    PropertyServicesPanel,
    PropertyOperatorPanel,
  ];

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <h1>
            {property.name} - {property.city}
          </h1>
        </Grid.Column>
        <Grid.Column width={1} floated="right">
          <EditProcessStatus isEditing={isEditing} />
        </Grid.Column>
      </Grid>

      <Accordion accordionProps={accordionPanels} />
    </>
  );
};

//
// Prop Types
//

EditProcessStatus.propTypes = {
  isEditing: PropTypes.bool,
};

PropertyEditorView.propTypes = {
  onChange: PropTypes.func.isRequired,
  property: PropTypes.object,

  /**
   * @type {boolean} - Indicates whether the container is in the process of
   *   persisting changes to the backend.
   */
  isEditing: PropTypes.bool,
};
