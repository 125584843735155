import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import { alertReducer } from "../alert";
import { loginReducer } from "../login";
import { paginationReducer } from "../pagination";
import { propertyReducer } from "../property";
import { searchReducer } from "../search";
import { stayReducer } from "../stay";

export default combineReducers({
  auth: loginReducer,
  form: formReducer,
  properties: propertyReducer,
  stays: stayReducer,
  alert: alertReducer,
  search: searchReducer,
  pagination: paginationReducer,
});
