import { channel } from "@redux-saga/core";
import { toast } from "react-toastify";
import { takeEvery, all, call, take, fork, put } from "redux-saga/effects";

import { callAPIMiddleware } from "../redux/callAPIMiddleware";
import { externalTypes } from "./types";

const MAX_CONCURRENT_PROPERTY_DETAILS_FETCHES = 40;

export function* watchExternals() {
  yield all([
    takeEvery(externalTypes.PROPERTY_FETCHLIST_REQUEST, callAPIMiddleware),
    takeEvery(externalTypes.PROPERTY_FETCHLIST_SUCCESS, () => {
      toast.success("Sync was successful!");
    }),
    takeEvery(externalTypes.PROPERTY_FETCHLIST_FAILURE, () => {
      toast.error("Sync had an error!");
    }),
    takeEvery(externalTypes.STAY_FETCHLIST_REQUEST, callAPIMiddleware),
    takeEvery(externalTypes.STAY_FETCHLIST_SUCCESS, () => {
      toast.info("Sync started as background task");
    }),
    takeEvery(externalTypes.PROPERTY_STAY_FETCHLIST_REQUEST, callAPIMiddleware),
    takeEvery(externalTypes.PROPERTY_STAY_FETCHLIST_SUCCESS, () => {
      toast.success("Sync was successful!");
    }),
    takeEvery(externalTypes.PROPERTY_STAY_FETCHLIST_FAILURE, () => {
      toast.error("Sync had an error!");
    }),
    takeEvery(externalTypes.PROPERTY_DETAILS_FETCH_SUCCESS, () => {
      toast.success("Sync was successful!");
    }),
    takeEvery(externalTypes.PROPERTY_DETAILS_FETCH_FAILURE, () => {
      toast.error("Sync had an error!");
    }),
  ]);

  // create a channel to queue incoming requests
  const fetchPropertyDetailsChannel = yield call(channel);

  // create worker 'threads'
  for (var i = 0; i < MAX_CONCURRENT_PROPERTY_DETAILS_FETCHES; i++) {
    yield fork(fetchPropertyDetailsWatcher, fetchPropertyDetailsChannel);
  }

  while (true) {
    const action = yield take(externalTypes.PROPERTY_DETAILS_FETCH_REQUEST);
    yield put(fetchPropertyDetailsChannel, action);
  }
}

function* fetchPropertyDetailsWatcher(chan) {
  while (true) {
    const action = yield take(chan);
    yield callAPIMiddleware(action);
  }
}
