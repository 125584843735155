import React from 'react';

import ListItem from './ListItem';
import './List.css';

const List = ({ listItems }) => {
  return (
    <ul className="customList">
      {listItems.map((item, index) => (
        <ListItem key={index} item={item} />
      ))}
    </ul>
  );
};

export default List;
