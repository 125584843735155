import React from "react";

import {
  Form,
  FormCheckbox,
  FormDropdown,
  FormTextArea,
} from "semantic-ui-react";

export const GeneralInformationForm = ({ values, onChange }) => {
  const handleChange = ({ target }) => {
    const { name, value } = target;
    onChange(name, value);
  };

  const handleCheckboxClick = (_, { checked, name }) => {
    onChange(name, checked);
  };

  return (
    <Form>
      <Form.Group widths="equal" onChange={handleChange}>
        <Form.Input label="Name" name="name" value={values.name || ""} />
        <FormDropdown
          label="Room Category"
          name="category"
          value={values.category}
          selection
          options={roomCategoryOptions}
          onChange={(_, { value }) => onChange("category", value)}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          label="Display Number"
          name="displayNumber"
          value={values.displayNumber || ""}
          type="number"
          onChange={handleChange}
        />
      </Form.Group>
      <FormTextArea
        label="Markdown Description"
        name="descriptionMarkdown"
        value={values.descriptionMarkdown || ""}
        onChange={handleChange}
        rows={10}
      />
      <FormCheckbox
        label="Published"
        checked={!!values.isPublishedForAgents}
        name="isPublishedForAgents"
        onChange={handleCheckboxClick}
      />
      <FormCheckbox
        label="Pets allowed?"
        checked={values.petsAllowed}
        name="petsAllowed"
        onChange={handleCheckboxClick}
      />
    </Form>
  );
};

const roomCategoryOptions = [
  { key: "Room", text: "Room", value: "Room" },
  { key: "Junior Suite", text: "Junior Suite", value: "Junior Suite" },
  { key: "Suite", text: "Suite", value: "Suite" },
  { key: "Penthouse Suite", text: "Penthouse Suite", value: "Penthouse Suite" },
  { key: "Residence", text: "Residence", value: "Residence" },
  { key: "Villa", text: "Villa", value: "Villa" },
  { key: "Chalet", text: "Chalet", value: "Chalet" },
];
