import { useEffect, useState } from "react";

import { get } from "lodash";

import { snowtrade } from "../../../api";

export const useHotelDropdownOptions = () => {
  const [hotels, setHotels] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    snowtrade.get("/api/hotels").then((response) => setHotels(response.data));
  }, []);

  useEffect(() => {
    setOptions(
      hotels.map((hotel) => ({
        title: hotel.name,
        link: `/cms/hotels/${hotel._id}`,
        description: get(hotel, "location.city"),
      }))
    );
  }, [hotels]);

  return options;
};
