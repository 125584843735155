import React, { useState } from "react";

import crown from "../assets/images/STR_Krone.png";
import { SearchBar } from "../search";

const MobileMenuBar = (props) => {
  const { onHamburgerMenuClick, sidebarMenuVisibility } = props;
  const [mobileMenuSearchBarVisibility, setMobileMenuSearchBarVisibility] =
    useState(false);

  const onMobileMenuSearchClick = () => {
    const nextState = mobileMenuSearchBarVisibility ? false : true;
    setMobileMenuSearchBarVisibility(nextState);
  };

  const menuIcon = sidebarMenuVisibility ? "x" : "bars";

  return (
    <>
      <div id="mobileMenuBar">
        <span id="hamburgerMenu" onClick={onHamburgerMenuClick}>
          <i className={`icon ${menuIcon}`}></i>
        </span>
        <span id="logoCrown">
          <img src={crown} alt="Brand Logo Crown" />
        </span>
        <span id="mobileMenuSearch" onClick={onMobileMenuSearchClick}>
          <i className="icon search"></i>
        </span>
      </div>
      <div
        className={`mobileSearchBarContainer ${
          mobileMenuSearchBarVisibility ? "visible" : ""
        }`}
        onClick={onMobileMenuSearchClick}
      >
        <div className="mobileSearchBar" onClick={(e) => e.stopPropagation()}>
          <SearchBar />
        </div>
      </div>
    </>
  );
};

export default MobileMenuBar;
