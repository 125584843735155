import { sortBy } from "lodash";

import { snowtrade } from "../api/snowtrade";
import { authHeader } from "../helpers";

//
// Hotel API
//

export const fetchHotels = () => {
  const headers = authHeader();
  return snowtrade
    .get("/api/hotels", { headers })
    .then((response) => response.data);
};

export const fetchHotel = (hotelId) => {
  const headers = authHeader();
  return snowtrade
    .get("/api/hotels/" + hotelId, { headers })
    .then((response) => response.data);
};

export const syncHotelImages = (hotelId) => {
  const headers = authHeader();
  return snowtrade
    .get(`/api/external/hotels/${hotelId}/images`, { headers })
    .then((response) => response.data);
};

//
// Room API
//

export const fetchHotelRooms = (hotelId) => {
  const headers = authHeader();
  return snowtrade
    .get(`/api/hotels/${hotelId}/rooms`, { headers })
    .then((response) => response.data)
    .then((rooms) => sortBy(rooms, "displayNumber"));
};

export const fetchRoom = (roomId) => {
  const headers = authHeader();
  return snowtrade
    .get(`/api/hotelrooms/${roomId}`, { headers })
    .then((response) => response.data);
};

export const syncRoomImages = (roomId) => {
  const headers = authHeader();
  return snowtrade
    .get(`/api/external/hotelrooms/${roomId}/images`, { headers })
    .then((response) => response.data);
};
