import React from "react";

import PropTypes from "prop-types";
import { Search } from "semantic-ui-react";

import "./SearchBar.css";

const renderResult = ({ id, title, description }) => (
  <div key={id} className="content" role="option">
    {title && <div className="title">{title}</div>}
    {description && <div className="description">{description}</div>}
  </div>
);

const SearchBarForm = ({ results, onChange, onResultSelect }) => {
  const handleOnResultClick = (_e, { result }) => {
    onResultSelect(result);
  };

  return (
    <Search
      results={results}
      onSearchChange={onChange}
      resultRenderer={renderResult}
      onResultSelect={handleOnResultClick}
      id="str-search"
      onFocus={(e) => e.target.select()}
    />
  );
};

export default SearchBarForm;

//
// Prop Types
//

SearchBarForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onResultSelect: PropTypes.func.isRequired,
  results: PropTypes.array,
};
