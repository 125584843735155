import { useEffect, useState } from "react";

import { map } from "lodash";

export const useConnectedPropertyIds = (initialRentalChannels) => {
  const [connectedPropertyIds, setConnectedPropertyIds] = useState(
    mapRentalChannelsToPropertyIds(initialRentalChannels)
  );

  useEffect(() => {
    setConnectedPropertyIds(
      mapRentalChannelsToPropertyIds(initialRentalChannels)
    );
  }, [initialRentalChannels]);

  return { connectedPropertyIds, setConnectedPropertyIds };
};

const mapRentalChannelsToPropertyIds = (channels = []) =>
  map(channels, "_property");
