import React from "react";

import { Field, reduxForm, SubmissionError } from "redux-form";
import { Dropdown, Form } from "semantic-ui-react";

import { Input, SelectMultiple } from "../form";

import "./AdvancedSearchForm.css";

/**
 * The property contacts (operator and owner) may be active or inactive. To
 * disable the filter, the null value is added.
 */
const CONTACT_STATUS_OPTIONS = [
  { key: "none", value: null, text: "--" },
  { key: "active", value: "active", text: "Active" },
  { key: "inactive", value: "inactive", text: "Inactive" },
];

/** Wrapper to use semantic-ui-react.Dropdown in redux form. */
const ReduxFormDropdown = (props) => (
  <Form.Field
    control={Dropdown}
    {...props}
    {...props.input}
    value={props.input.value}
    onChange={(param, data) => props.input.onChange(data.value)}
  />
);

/** Renders the whole search form. Uses redux forms. */
const AdvancedSearchForm = (props) => {
  const onFormSubmit = (formValues) => {
    const date = new Date(Date.now() - 86400000).toJSON();
    if (formValues.checkIn && !formValues.checkOut) {
      throw new SubmissionError({
        checkOut: "Please select a check-out date.",
      });
    } else if (!formValues.checkIn && formValues.checkOut) {
      throw new SubmissionError({ checkIn: "Please select a check-in date." });
    } else if (
      formValues.checkIn &&
      formValues.checkOut &&
      formValues.checkIn > formValues.checkOut
    ) {
      throw new SubmissionError({
        checkIn: "Check-in date has to be before check-out date.",
        checkOut: "Check-in date has to be before check-out date.",
      });
    } else if (formValues.checkIn < date) {
      throw new SubmissionError({ checkIn: "Check-in date is in the past." });
    }
    props.onSubmit(formValues);
  };

  const { dropdownOptions, submitting, handleSubmit } = props;

  return (
    <div className="ui segment">
      <h1>Advanced Search</h1>
      <Form
        onSubmit={handleSubmit(onFormSubmit)}
        className="ui form"
        id="advancedSearchForm"
      >
        <Form.Group widths="equal">
          <Field
            name="checkIn"
            component={Input}
            label="Check In"
            type="date"
          />
          <Field
            name="checkOut"
            component={Input}
            label="Check Out"
            type="date"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="propertyTypeArray"
            component={SelectMultiple}
            placeholder="Select Type"
            label="Type"
            options={dropdownOptions.propertyTypeArray}
            type="select-multiple"
          />
          <Field
            name="serviceLevelArray"
            component={SelectMultiple}
            placeholder="Select Service-Type"
            label="Service Type"
            options={dropdownOptions.serviceLevelArray}
            type="select-multiple"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="countryArray"
            component={SelectMultiple}
            placeholder="Select Countries"
            label="Country"
            type="select-multiple"
            options={dropdownOptions.countryArray}
          />
          <Field
            name="cityArray"
            component={SelectMultiple}
            placeholder="Select Cities"
            label="City"
            options={dropdownOptions.cityArray}
            type="select-multiple"
          />
          <Field
            name="sectorArray"
            component={SelectMultiple}
            placeholder="Select Sectors"
            label="Sector"
            options={dropdownOptions.sectorArray}
            type="select-multiple"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="bedrooms"
            component={Input}
            label="Bedrooms"
            type="number"
            min="0"
          />
          <Field
            name="adults"
            component={Input}
            label="Adults"
            type="number"
            min="0"
          />
          <Field
            name="children"
            component={Input}
            label="Children"
            type="number"
            min="0"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="propertyStatusArray"
            component={SelectMultiple}
            label="Property Status"
            options={["draft", "confidential", "published"]}
            type="select-multiple"
            placeholder="Select property status"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="operatorArray"
            component={SelectMultiple}
            label="Operator"
            options={dropdownOptions.operatorArray}
            type="select-multiple"
            placeholder="Select property operator"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            component={ReduxFormDropdown}
            name="operatorContactStatus"
            label="Operator Status"
            selection
            options={CONTACT_STATUS_OPTIONS}
          />
          <Field
            component={ReduxFormDropdown}
            name="ownerContactStatus"
            label="Owner Status"
            selection
            options={CONTACT_STATUS_OPTIONS}
          />
        </Form.Group>
        <Form.Button disabled={submitting} id="advancedSearchFormSubmit">
          Submit
        </Form.Button>
      </Form>
    </div>
  );
};

export default reduxForm({
  form: "advancedSearch",
})(AdvancedSearchForm);
