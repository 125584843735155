import _ from 'lodash';

import { externalTypes } from '../operator';
import { propertyTypes } from './types';

const INITIAL_STATE = {
  isImporting: false,
  isFetching: false,
  hasFetched: false,
  items: []
};

export default (state = INITIAL_STATE, action) => {
  let _id;
  let item;
  let items = state.items;
  let itemsMappedKeys;
  let newItems;
  let newItemsMappedKeys;
  let newState;
  let mergeItems;

  switch (action.type) {
    case propertyTypes.FETCHLIST_REQUEST:
      return { ...state, isFetching: true };

    case propertyTypes.FETCHLIST_SUCCESS:
      newItems = _.map(action.payload, newItem => {
        if (!_.has(items, newItem._id)) {
          return newItem;
        }
        return _.merge(items[newItem._id], newItem);
      });
      itemsMappedKeys = _.mapKeys(items, '_id');
      newItemsMappedKeys = _.mapKeys(newItems, '_id');
      mergeItems = _.merge({}, newItemsMappedKeys, itemsMappedKeys);

      newState = { isFetching: false, hasFetched: true, items: mergeItems };
      return { ...state, ...newState };

    case propertyTypes.FETCHLIST_FAILURE:
      return { ...state, isFetching: false, isFailure: true };

    case propertyTypes.FETCH_REQUEST:
      _id = action.payload._id;
      item = state.items[_id];

      return { ...state, items: { ...items, [_id]: { ...item, isFetching: true } } };

    case propertyTypes.FETCH_SUCCESS:
      _id = action.payload._id;
      item = state.items[_id];

      return { ...state, items: { ...items, [_id]: action.payload } };

    case propertyTypes.EDIT_REQUEST:
      _id = action.payload._id;
      item = state.items[_id];

      return { ...state, items: { ...items, [_id]: { ...item, isEditing: true } } };
    case propertyTypes.EDIT_SUCCESS:
      _id = action.payload._id;
      item = state.items[_id];

      return { ...state, items: { ...items, [_id]: action.payload } };
    case externalTypes.PROPERTY_FETCHLIST_REQUEST:
      return { ...state, isImporting: true };

    case externalTypes.PROPERTY_FETCHLIST_SUCCESS:
      newItems = _.map(action.payload, newItem => {
        if (!_.has(items, newItem._id)) {
          return newItem;
        }
        return _.merge(items[newItem._id], newItem);
      });
      itemsMappedKeys = _.mapKeys(items, '_id');
      newItemsMappedKeys = _.mapKeys(newItems, '_id');
      mergeItems = _.merge({}, newItemsMappedKeys, itemsMappedKeys);

      newState = { isImporting: false, items: mergeItems };
      return { ...state, ...newState };

    case externalTypes.PROPERTY_DETAILS_FETCH_REQUEST:
      _id = action.payload._id;
      item = state.items[_id];

      return { ...state, items: { ...items, [_id]: { ...item, isImporting: true } } };

    case externalTypes.PROPERTY_DETAILS_FETCH_SUCCESS:
      _id = action.payload._id;
      item = state.items[_id];

      return { ...state, items: { ...items, [_id]: action.payload } };

    default:
      return state;
  }
};
