import React from 'react';

import AdvancedSearchFormContainer from './AdvancedSearchFormContainer';
import AdvancedSearchResultContainer from './AdvancedSearchResultContainer';

const AdvancedSearch = () => {
  return (
    <div>
      <AdvancedSearchFormContainer />
      <AdvancedSearchResultContainer />
    </div>
  );
};

export default AdvancedSearch;
