/* eslint-disable react/prop-types */
import React from "react";

import { EditUserForm } from "./EditUserForm";

export const EditUserPage = ({
  match: {
    params: { userId },
  },
}) => <EditUserForm userId={userId} />;
