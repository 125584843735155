import { toString } from "lodash";

/**
 * @example
 *   <ReactTable
 *     defaultPageSize={50}
 *     className="-striped -highlight"
 *     columns={columns}
 *     data={data}
 *     defaultFilterMethod={reactTableRegexFilterMethod}
 *     defaultSorted={[{ id: "name" }]}
 *   />;
 */
export const reactTableRegexFilterMethod = (filter, row) => {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? toString(row[id]).match(new RegExp(filter.value, "i"))
    : true;
};
