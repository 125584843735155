import jsonpatch from "fast-json-patch";

import { snowtrade } from "../../api/snowtrade";
import { authHeader } from "../../helpers";

/**
 * Represents the room object as known by the backend. This object is used to
 * create JSON Patch objects.
 */
let dbRoom;

/**
 * Fetches the given hotel room and stores it for later comparison. Use this
 * implementation of fetchRoom within the RoomInput context to allow the
 * patchRoom function to work properly.
 *
 * @param {string} roomId - The id of the hotel room to fetch.
 */
export const fetchRoom = (roomId) => {
  const headers = authHeader();
  return snowtrade
    .get(`/api/hotelrooms/${roomId}`, { headers })
    .then((response) => {
      const room = response.data;
      dbRoom = room;
      return room;
    });
};

/**
 * Patches the given room in the database using JSON Patch. It therefore only
 * sends the changes to be made to the backend.
 *
 * @param {Object} room - The new room object.
 */
export const patchRoom = (room) => {
  const patches = jsonpatch.compare(dbRoom, room);

  const headers = authHeader();
  headers["Content-Type"] = "application/json-patch+json";
  return snowtrade
    .patch("/api/hotelrooms/" + room._id, patches, { headers })
    .then((response) => {
      dbRoom = response.data;
      return dbRoom;
    });
};

/**
 * Creates a new hotel room in the database.
 *
 * @param {string} hotelId - The Id of the hotel to create a room for.
 * @param {Object} room - The room to create-
 */
export const postRoom = (hotelId, room) => {
  const headers = authHeader();
  const data = { ...room, _hotel: hotelId };
  return snowtrade
    .post(`/api/hotelrooms`, data, { headers })
    .then((response) => response.data);
};
