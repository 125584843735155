import _ from 'lodash';

import { paginationTypes } from './types.js';

export const getPaginatedItems = (items, activePage, pageSize) => {
  var pg = activePage,
    pgSize = pageSize,
    offset = (pg - 1) * pgSize,
    pagedItems = _.drop(items, offset).slice(0, pgSize);
  return {
    type: paginationTypes.GET_ITEMS,
    payload: {
      activePage: pg,
      pageSize: pgSize,
      total: items.length,
      totalPages: Math.ceil(items.length / pgSize),
      data: pagedItems
    }
  };
};

export const setPageSize = pageSize => {
  return { type: paginationTypes.SET_PAGE_SIZE, payload: { pageSize } };
};
