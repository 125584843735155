import { useMemo } from "react";

import { useHotelDropdownOptions } from "./useHotelDropdownOptions";
import { usePropertiesDropdownOptions } from "./usePropertiesDropdownOptions";

export const useDropdownOptions = () => {
  const propertiesOptions = usePropertiesDropdownOptions();
  const hotelOptions = useHotelDropdownOptions();

  return useMemo(
    () => [...propertiesOptions, ...hotelOptions],
    [propertiesOptions, hotelOptions]
  );
};
