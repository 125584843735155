// @ts-check
import React from "react";

import { compact } from "lodash";
import { Button, Form, Header } from "semantic-ui-react";

import { BedSegment } from "./BedSegment";

/**
 * Renders a form allowing the admin to enter the amount of different bed types
 * the hotel room has included.
 *
 * @param {{
 *   beds: import("../../../hotelRoomTypes").HotelRoomBed[];
 *   onChange: any;
 * }} props
 */
export const BedsForm = ({ beds = [], onChange }) => {
  const addBed = () => {
    const newBed = { sleeps: [{ maxPax: 0, maxAdults: 0 }] };
    onChange("beds", [...beds, newBed]);
  };

  const handleBedChange = (index, newBedState) => {
    const newBeds = [...beds];
    newBeds[index] = newBedState;
    onChange("beds", newBeds);
  };

  const removeBed = (index) => {
    const newBeds = [...beds];
    newBeds[index] = null;
    onChange("beds", compact(newBeds));
  };

  return (
    <Form className="beds">
      <Header>Beds</Header>
      {beds.map((bed, index) => (
        <BedSegment
          key={bed._id || index}
          bed={bed}
          bedIndex={index}
          onChange={handleBedChange}
          onRemove={removeBed}
        />
      ))}

      <Button onClick={addBed}>Add Bed</Button>
    </Form>
  );
};
