import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import { Header } from "semantic-ui-react";

import { snowtrade } from "../api";

export const PossibleTemplateCommands = () => {
  const [format, setFormat] = useState("");

  useEffect(() => {
    snowtrade
      .get("/api/users/emails/template-context-format")
      .then((response) => setFormat(response.data));
  }, []);

  return (
    <div style={{ color: "grey" }}>
      <Header content="Possible Template Commands" color="grey" />
      {JSON.stringify(format, null, 4)}
    </div>
  );
};
