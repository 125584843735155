import React from "react";

import { Icon } from "semantic-ui-react";

export const ContractStatusCell = ({ hotel }) => {
  switch (hotel.contractStatus) {
    case "confirmed":
      return <Icon name="check" color="green" />;
    case "pending":
      return <Icon name="wait" color="yellow" />;
    case "none":
      return <Icon name="exclamation" color="red" />;
    default:
      return <Icon name="question" color="grey" />;
  }
};
