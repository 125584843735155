import React from "react";

import { toast } from "react-toastify";
import { Icon, Modal } from "semantic-ui-react";

import { snowtrade } from "../api";
import { EmailForm } from "./EmailForm";

/** @param {{ user: import("./constants").User }} param0 */
export const UserEmailIcon = ({ user }) => {
  /** @type {import("./EmailForm").EmailFormSubmitHandler} */
  const handleSubmit = (data) => {
    return snowtrade
      .post(`/api/users/${user._id}/emails`, data)
      .then(() => toast.success("Success!"))
      .catch(() => toast.error("oops"));
  };

  return (
    <Modal closeIcon trigger={<Icon name="envelope outline" link />}>
      <Modal.Header>
        Email to {user.firstName} {user.lastName}
      </Modal.Header>
      <Modal.Content>
        <EmailForm
          defaultValues={{
            markdown: makeDefaultMarkdown(user),
            subject: "Your Booking Platform",
          }}
          handleSubmit={handleSubmit}
        />
      </Modal.Content>
    </Modal>
  );
};

/**
 * @param {import("./constants").User} user
 * @returns {string}
 */
const makeDefaultMarkdown = (user) => `Dear ${user.firstName} ${user.lastName},

We are pleased to provide you with a brand new booking platform for the high-end luxury market.
[Please click here]({{user.agentAccessLink}}) to access.
The link will log you in automatically and expires after seven days.

If you ever need to sign in without such a magic link, here are your credentials:${"  "}
Website: {{user.agentPlatformUrl}}${"  "}
Username: ADDUSERNAME${"  "}
Password: ADDPASSWORD${"  "}

Please feel free to explore our platform. Simply text me whenever you have a question.
You might even want to enquire and book the "DEMO Kempinski Palace Engelberg" hotel. I have found availabilities beginning in October.
Those bookings will of course be test bookings and will not be forwarded to the real hotel.


We are looking forward to a fruitful cooperation!
Best regards
Timo`;
