import React from 'react';

import SidebarMenu from './SidebarMenu';

const MobileSideMenu = props => {
  const { onHamburgerMenuClick, visibility, onLogoutButtonClick, user } = props;
  return (
    <div
      className={`mobileSideMenuContainer ${visibility ? 'visible' : ''}`}
      onClick={onHamburgerMenuClick}
    >
      <div className="mobileSideMenu" onClick={e => e.stopPropagation()}>
        <SidebarMenu />
        <div className="mobileLogoutButton">
          <div className="ui button white">{user.username}</div>
          <button
            className="ui button basic negative"
            onClick={onLogoutButtonClick}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileSideMenu;
