import React from 'react';

import { GoogleMap, Marker } from "react-google-maps";

const MapsView = ({coordinates: {longitude, latitude}}) => {

  // defaultOptions={{
  //   styles: [
  //     {
  //       featureType: "poi",
  //       stylers: [{ visibility: "off" }]
  //     }
  //   ]
  // }}
  
  return (
    <GoogleMap
      defaultZoom={17}
      defaultCenter={{ lat: latitude, lng: longitude }}   
      defaultMapTypeId="satellite"   
    >
      <Marker position={{ lat: latitude, lng: longitude }} />
    </GoogleMap>
  );
}

export { MapsView }