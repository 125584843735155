import React, { useMemo } from "react";

import { Header, Segment } from "semantic-ui-react";

import { FilterForm } from "./FilterForm";
import { OrderForm } from "./OrderForm";

export const RoomOrderingView = ({ hotelId }) => {
  const [availableRooms, setAvailableRooms] = React.useState([]);

  const rooms = useMemo(
    () =>
      availableRooms.map(({ room, priceInfo }) => ({
        _id: room._id,
        name: room.name,
        minimumPrice: priceInfo.minimumPrice,
        displayNumber: room.displayNumber,
      })),
    [availableRooms]
  );

  return (
    <>
      <Header as="h2" content="Room Ordering" />
      <FilterForm
        onAvailableRoomsResponse={setAvailableRooms}
        hotelId={hotelId}
      />
      <Segment className="colored">
        <OrderForm rooms={rooms} />
      </Segment>
    </>
  );
};
