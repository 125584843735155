import React, { useEffect, useState } from "react";

import { escapeRegExp, deburr, filter } from "lodash";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { fetchProperties } from "../../property";
import SearchBarForm from "./SearchBarForm";
import { useDropdownOptions } from "./useDropdownOptions";

export const SearchBarContainer = ({ toggleSearchVisibility }) => {
  const history = useHistory();
  const dropdownOptions = useDropdownOptions();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    dispatch(fetchProperties());
  }, []);

  const handleInput = (event) => {
    setSearchInput(event.target.value);
  };

  const getResults = (searchString = "") => {
    if (searchString.length === 0) return null;
    const re = new RegExp(escapeRegExp(deburr(searchString)), "i");
    const isMatch = (result) => re.test(deburr(result.title));

    let searchResults = filter(dropdownOptions, isMatch);
    return searchResults;
  };

  const handleResultSelect = (result) => {
    setSearchInput(result.title);

    history.push(result.link);
    if (toggleSearchVisibility) {
      toggleSearchVisibility();
    }
  };

  const results = getResults(searchInput);

  return (
    <SearchBarForm
      results={results}
      onChange={handleInput}
      onResultSelect={handleResultSelect}
    />
  );
};

//
// Prop Types
//

SearchBarContainer.propTypes = {
  toggleSearchVisibility: PropTypes.func,
};
