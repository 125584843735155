// @ts-check
import React from "react";

import { Form } from "semantic-ui-react";

const bedTypeOptions = [
  { text: "King Size Bed", value: "kingSizeBed" },
  { text: "Queen Size Bed", value: "queenSizeBed" },
  { text: "Single Bed", value: "singleBed" },
  { text: "Sofa/Extra Bed", value: "sofaBed" },
  { text: "Bunk Bed", value: "bunkBed" },
];

export const BedTypeSelect = ({ value, onUpdate }) => {
  const handleChange = (e, { value }) => onUpdate({ bedType: value });
  return (
    <Form.Select
      id="bed-type-select"
      label="Bed Type"
      options={bedTypeOptions}
      value={value}
      // @ts-ignore
      onChange={handleChange}
    />
  );
};
