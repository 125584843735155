import { useState } from "react";

import { toast } from "react-toastify";

import { snowtrade } from "../../../../api";

export const useConnectedPropertiesSubmissionHandler = (operatorId) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = (connectedPropertyIds) => {
    setIsSubmitting(true);
    snowtrade
      .put(
        `/api/property-operators/${operatorId}/property-ids`,
        connectedPropertyIds
      )
      .then(() =>
        toast.success(
          "Successfully saved the connected properties of this operator."
        )
      )
      .catch(() => toast.error("Nope, that did not work. Sorry"))
      .finally(() => setIsSubmitting(false));
  };

  return { submit, isSubmitting };
};
