/* eslint-disable react/prop-types */

import React from "react";

import { Form } from "semantic-ui-react";

import {
  createFunctionalGroupChangeHandler,
  createInputChangeEventHandler,
  createSelectionEventHandler,
} from "../../form/changeHandlers";

export const BedRoomsInput = ({ value, onChange }) => (
  <Form.Input
    name="bedrooms"
    label="Bed rooms"
    placeholder="Number of bed rooms in the accomodation"
    value={value || ""}
    onChange={createInputChangeEventHandler(onChange)}
  />
);

export const SurfaceInput = ({ value, onChange }) => (
  <Form.Input
    name="surface"
    type="number"
    label="Surface"
    placeholder="The surface of the accomodation in square meters"
    value={value || ""}
    onChange={createInputChangeEventHandler(onChange)}
  />
);

const PETS_ALLOWED_OPTIONS = [
  { key: "yes", text: "Yes", value: true },
  { key: "No", text: "No", value: false },
];

export const PetsAllowedInput = ({ value, onChange }) => (
  <Form.Dropdown
    name="petsAllowed"
    label="Pets allowed"
    value={value}
    options={PETS_ALLOWED_OPTIONS}
    onChange={createSelectionEventHandler(onChange)}
    selection
  />
);

export const BedsInput = ({ beds = {}, onChange }) => {
  const bedsChangeHandler = createFunctionalGroupChangeHandler(
    onChange,
    "beds",
    beds
  );
  const onBedsChangeEvent = createInputChangeEventHandler(bedsChangeHandler);

  return (
    <Form.Group widths="equal">
      <Form.Input
        name="adults"
        label="Adult beds"
        value={beds.adults || ""}
        onChange={onBedsChangeEvent}
        placeholder="The number of adult beds in the accomodation"
        type="number"
        min={0}
      />
      <Form.Input
        name="children"
        label="Children beds"
        value={beds.children || ""}
        onChange={onBedsChangeEvent}
        placeholder="The number of children beds in the accomodation"
        type="number"
        min={0}
      />
      <Form.Input
        name="staff"
        label="Staff beds"
        value={beds.staff || ""}
        onChange={onBedsChangeEvent}
        placeholder="The number of staff beds in the accomodation"
        type="number"
        min={0}
      />
    </Form.Group>
  );
};

export const AccomodationInformationForm = ({ values = {}, onChange }) => (
  <Form>
    <Form.Group widths="equal">
      <BedRoomsInput value={values.bedrooms} onChange={onChange} />
      <SurfaceInput value={values.surface} onChange={onChange} />
      <PetsAllowedInput value={values.petsAllowed} onChange={onChange} />
    </Form.Group>

    <BedsInput beds={values.beds} onChange={onChange} />
  </Form>
);
