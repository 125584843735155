import { paginationTypes } from './types';

const INITIAL_STATE = {
  activePage: 1,
  pageSize: 12,
  totalPages: 1,
  total: 0,
  data: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case paginationTypes.GET_ITEMS:
      return { ...state, ...action.payload };
    case paginationTypes.SET_PAGE_SIZE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
