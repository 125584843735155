import React from "react";

import { Button } from 'semantic-ui-react'

const LineForm = ({ onChange, value, onAddLineClick }) => {
  const serviceInput = (props) => {
    return (
      <input
        label="Service"
        key={props.key}
        value={props.service}
        placeholder="Daily housekeeping, Concierge service, etc"
        onChange={(e) => onChange(e, props.key)}
      />
    );
  }

  return (
    <div className="lineForm">
      {value.map((service, index) =>
        serviceInput({
          service,
          key: index
        })
      )}
      <Button onClick={onAddLineClick} icon="plus"/>
    </div>
  );
};

export { LineForm }
