export const defaultHotelState = {
  name: "",
  chain: "",
  subChain: "",
  numRooms: "",
  earliestCheckIn: "",
  latestCheckOut: "",
  isPublishedForGuests: false,
  location: {
    city: "",
    country: "",
    sector: "",
    zipCode: "",
    coordinates: {
      latitude: "",
      longitude: "",
    },
  },
  paymentDetails: {
    creditCardFee: "",
    acceptedCurrenciesOptions: [],
    prepaymentRatio: "",
  },
};
