import React from "react";

import { Grid } from "semantic-ui-react";

import { fetchOperators } from "./api";
import { OperatorEditor } from "./OperatorEditor";
import { OperatorSelection } from "./OperatorSelection";

class OperatorSelectionContainer extends React.PureComponent {
  state = {
    operators: [],
    isFetchingOperators: true,
    selectedOperatorId: undefined,
  };

  componentDidMount() {
    fetchOperators().then((operators) =>
      this.setState({ operators, isFetchingOperators: false })
    );
  }

  handleSelectOperator = (selectedOperatorId) =>
    this.setState({ selectedOperatorId });

  render() {
    return (
      <div>
        <Grid padded>
          <Grid.Row>
            <OperatorSelection
              operators={this.state.operators}
              isLoading={this.state.isFetchingOperators}
              onSelectOperatorId={this.handleSelectOperator}
            />
          </Grid.Row>
          <Grid.Row>
            <OperatorEditor operatorId={this.state.selectedOperatorId} />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default OperatorSelectionContainer;
