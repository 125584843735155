import React from "react";

import { Link } from "react-router-dom";
import ReactTable from "react-table";
import { Button } from "semantic-ui-react";

import { reactTableRegexFilterMethod } from "../../helpers/reactTableRegexFilterMethod";
import { useHotelById } from "../useHotelById";
import { SyncRoomImagesButton } from "./SyncRoomImagesButton";

export const HotelRoomsTableView = ({ rooms, hotelId }) => {
  const { hotel } = useHotelById(hotelId);

  return (
    <>
      <h1>Hotel Rooms - {hotel && hotel.name}</h1>
      <RoomTable rooms={rooms} hotelId={hotelId} />
      <Link to={({ pathname }) => `${pathname}/new`}>
        <Button floated="right">New Room</Button>
      </Link>
    </>
  );
};

const RoomTable = ({ hotelId, rooms = [] }) => {
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      filterable: true,
      Cell: (row) => (
        <Link to={`/cms/hotels/${hotelId}/rooms/${row.original._id}/edit`}>
          {row.original.name}
        </Link>
      ),
    },
    {
      Header: "Category",
      accessor: "category",
      filterable: true,
    },
    {
      Header: "DisplayNumber",
      accessor: "displayNumber",
      maxWidth: 50,
    },
    {
      id: "linkToAmadeusManager",
      Cell: (row) => (
        <Link
          to={`/cms/hotels/${hotelId}/rooms/${row.original._id}/amadeus-manager`}
        >
          Amadeus <i className="cloud download icon" />
        </Link>
      ),
      maxWidth: 100,
    },
    {
      Cell: (row) => <SyncRoomImagesButton room={row.original} />,
      maxWidth: 80,
    },
  ];

  return (
    <ReactTable
      className="-striped -highlight"
      columns={columns}
      data={rooms}
      defaultFilterMethod={reactTableRegexFilterMethod}
      defaultSorted={[{ id: "displayNumber" }]}
    />
  );
};
