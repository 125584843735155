import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Header } from "semantic-ui-react";

import { reselectPropertyArray } from "../../property";
import { getIssues } from "./getIssues";
import { IssuesTable } from "./IssuesTable";

/**
 * This component renders a table displaying issues to resolve on specific properties.
 * A form enables the user to filter for specific issues or for specific properties to scan.
 *
 * @augments {React.PureComponent}
 */
class IssuesContainer extends React.PureComponent {
  render() {
    const issues = getIssues(this.props.properties);

    return (
      <>
        <Header>Property Issue List</Header>
        {/* Filter Form */}
        <IssuesTable issues={issues} />
      </>
    );
  }
}

//
// Redux Connect
//

const mapStateToProps = (state) => {
  const properties = reselectPropertyArray(state);
  return { properties };
};

export default connect(mapStateToProps)(IssuesContainer);

//
// Prop Types
//

IssuesContainer.propTypes = {
  /** From Redux Store */
  properties: PropTypes.array,
};
