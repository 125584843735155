import React, { useState } from 'react';

import MenuBar from './MenuBar';
import MobileMenuBar from './MobileMenuBar';
import MobileSideMenu from './MobileSideMenu';
import './Menu.css';
import SideMenu from './SideMenu';

const Menu = props => {
  const { onLogoutButtonClick, user } = props;
  const [mobileSideMenuVisibility, setMobileSideMenuVisibility] = useState(
    false
  );
  const onHamburgerMenuClick = () => {
    const nextState = mobileSideMenuVisibility ? false : true;
    setMobileSideMenuVisibility(nextState);
  };

  return (
    <>
      <div className="row tablet mobile only" id="mobileMenu">
        <div className="column">
          <MobileMenuBar
            onHamburgerMenuClick={onHamburgerMenuClick}
            sidebarMenuVisibility={mobileSideMenuVisibility}
          />
          <MobileSideMenu
            visibility={mobileSideMenuVisibility}
            onHamburgerMenuClick={onHamburgerMenuClick}
            onLogoutButtonClick={onLogoutButtonClick}
            user={user}
          />
        </div>
      </div>
      <div className="row computer only" id="computerMenu">
        <div className="column">
          <MenuBar user={user} onLogoutButtonClick={onLogoutButtonClick} />
          <SideMenu />
        </div>
      </div>
    </>
  );
};

export default Menu;
