import { propertyTypes } from "./types";

/**
 * Creates a redux action in order to fetch the given property.
 *
 * @returns {import("./types").PropertyDetailsFetchAction}
 */
export const fetchProperty = (_id) => {
  return { type: propertyTypes.FETCH_REQUEST, payload: { _id } };
};

/**
 * Creates a redux action in order to fetch all properties from the backend.
 *
 * @returns {import("./types").PropertiesFetchAction}
 */
export const fetchProperties = (conditions = null, projection = null) => {
  const params = { conditions, projection };
  return { type: propertyTypes.FETCHLIST_REQUEST, payload: { params } };
};

/** Creates a redux action in order to edit the given property. */
export const editProperty = (_id, property) => {
  const data = property;
  return { type: propertyTypes.EDIT_REQUEST, payload: { _id, data } };
};
