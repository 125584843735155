import React from "react";

import { Divider } from "semantic-ui-react";

import { HotelRoomsTable } from "./HotelRoomsTable";
import { RoomOrderingView } from "./RoomOrdering";

export const HotelRoomsPage = ({ match }) => {
  const { hotelId } = match.params;
  return (
    <>
      <HotelRoomsTable hotelId={hotelId} />
      <Divider section style={{ marginTop: "3em" }} />
      <RoomOrderingView hotelId={hotelId} />
    </>
  );
};
