import React, { Component } from "react";

import { connect } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { Cms } from "../cms";
import { history } from "../helpers";
import { LoginContainer } from "../login";
import { PrivateRoute } from "../routes";

import "react-toastify/dist/ReactToastify.css";
import "react-table/react-table.css";

// const whyDidYouRender = require("@welldone-software/why-did-you-render");
// whyDidYouRender(React, {
//   trackAllPureComponents: false,
//   logOnDifferentValues: true,
// });

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/login" exact component={LoginContainer} />
          <PrivateRoute path="/cms" component={Cms} />
          <Route path="/" render={() => <Redirect to="/cms" />} />
        </Switch>
        <ToastContainer autoClose={3000} position="bottom-right" />
      </Router>
    );
  }
}

export default connect(null)(App);
