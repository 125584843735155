import React from 'react';

import { connect } from 'react-redux';

import { PropertyCard } from '.';

const PropertyGrid = props => {
  const { properties, stays } = props;

  return (
    <div className="ui link stackable three cards">
      {properties.map(property => (
        <PropertyCard
          key={property._id}
          property={property}
          stays={stays[property._id]}
        />
      ))}
    </div>
  );
};

const mapStateToProps = ({ stays }) => {
  return { stays: stays.items };
};

export default connect(mapStateToProps, null)(PropertyGrid);
