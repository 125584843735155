import React from "react";

import { Form, FormCheckbox, FormDropdown, Label } from "semantic-ui-react";

export class GeneralInformationForm extends React.PureComponent {
  handleChange = ({ target }) => {
    const { name, value } = target;
    this.props.onChange(name, value);
  };

  handleCategoryChange = (_, { value }) =>
    this.props.onChange("category", value);

  render() {
    const { values } = this.props;

    return (
      <Form onChange={this.handleChange}>
        <Form.Group widths="equal">
          <Form.Input name="name" label="Name" value={values.name || ""} />{" "}
          <FormDropdown
            label="Hotel Category"
            selection
            options={hotelCategoryOptions}
            onChange={this.handleCategoryChange}
            value={values.category}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input name="chain" label="Chain" value={values.chain || ""} />
          <Form.Input
            name="subChain"
            label="Subchain"
            value={values.subChain || ""}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            name="earliestCheckIn"
            label="Earliest check in"
            value={values.earliestCheckIn || ""}
            placeholder="2 pm (Freitext)"
          />
          <Form.Input
            name="latestCheckOut"
            label="Latest check out"
            value={values.latestCheckOut || ""}
            placeholder="11am (Freitext)"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <Label>Is published</Label>
            <FormCheckbox
              checked={values.isPublishedForAgents}
              onClick={() =>
                this.props.onChange(
                  "isPublishedForAgents",
                  !values.isPublishedForAgents
                )
              }
            />
          </Form.Field>
          <Form.Field>
            <Label>Is demo</Label>
            <FormCheckbox
              checked={values.isDemo}
              onClick={() => this.props.onChange("isDemo", !values.isDemo)}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

const hotelCategories = [
  "4 Star",
  "4 Star Superior",
  "5 Star",
  "5 Star Superior",
  "5 Star Palace",
];
const hotelCategoryOptions = hotelCategories.map((category) => ({
  key: category,
  text: category,
  value: category,
}));
