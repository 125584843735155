export const externalTypes = {
  PROPERTY_FETCHLIST_REQUEST: 'EXTERNALPROPERTY_FETCHLIST_REQUEST',
  PROPERTY_FETCHLIST_SUCCESS: 'EXTERNALPROPERTY_FETCHLIST_SUCCESS',
  PROPERTY_FETCHLIST_FAILURE: 'EXTERNALPROPERTY_FETCHLIST_FAILURE',

  PROPERTY_STAY_FETCHLIST_REQUEST: 'EXTERNALPROPERTYSTAY_FETCHLIST_REQUEST',
  PROPERTY_STAY_FETCHLIST_SUCCESS: 'EXTERNALPROPERTYSTAY_FETCHLIST_SUCCESS',
  PROPERTY_STAY_FETCHLIST_FAILURE: 'EXTERNALPROPERTYSTAY_FETCHLIST_FAILURE',

  PROPERTY_DETAILS_FETCH_REQUEST: 'EXTERNALPROPERTYDETAILS_FETCH_REQUEST',
  PROPERTY_DETAILS_FETCH_SUCCESS: 'EXTERNALPROPERTYDETAILS_FETCH_SUCCESS',
  PROPERTY_DETAILS_FETCH_FAILURE: 'EXTERNALPROPERTYDETAILS_FETCH_FAILURE',

  STAY_FETCHLIST_REQUEST: 'EXTERNALSTAY_FETCHLIST_REQUEST',
  STAY_FETCHLIST_SUCCESS: 'EXTERNALSTAY_FETCHLIST_SUCCESS',
  STAY_FETCHLIST_FAILURE: 'EXTERNALSTAY_FETCHLIST_FAILURE'
};
