import React, { useEffect, useState } from "react";

import { Loader } from "semantic-ui-react";

import { fetchOperator } from "./api";
import { ConnectedPropertiesInput } from "./ConnectedPropertiesInput";
import { ConnectedUsersInput } from "./ConnectedUsersInput";

const useOperator = (operatorId) => {
  const [state, setState] = useState({
    operator: undefined,
    hasFetched: false,
    isFetching: false,
  });

  useEffect(() => {
    if (!operatorId || state.isFetching) return;
    const hasOperatorChanged =
      state.operator && state.operator._id !== operatorId;
    if (state.hasFetched && !hasOperatorChanged) return;

    setState({ ...state, isFetching: true });
    fetchOperator(operatorId).then((operator) =>
      setState({ operator, hasFetched: true, isFetching: false })
    );
  }, [operatorId, state]);

  return {
    operator: state.operator,
    hasFetchedOperator: state.hasFetched,
    isFetchingOperator: state.isFetching,
  };
};

/**
 * This component fetches the operator specified by the given operatorId and
 * forwards it to the editing components like the ConnectedPropertiesInput.
 */
export const OperatorEditor = ({ operatorId }) => {
  const { operator, isFetchingOperator } = useOperator(operatorId);

  if (isFetchingOperator) {
    return <Loader active />;
  }

  if (!operator) {
    return (
      <div>
        <p>Please select an operator</p>
      </div>
    );
  }

  return (
    <div className="operatorEditor">
      <ConnectedPropertiesInput
        initialRentalChannels={operator.rentalChannels}
        operatorId={operatorId}
      />
      <ConnectedUsersInput
        connectedUsers={operator._users}
        operatorId={operatorId}
      />
    </div>
  );
};
