import React from 'react';

import { connect } from 'react-redux';

import { alertActions } from './';
import { AlertBar } from './AlertBar';

const AlertBarContainer = props => {
  const onCloseIconClick = () => {
    props.dispatch(alertActions.clear());
  };

  const { alert } = props;
  if (alert.message) {
    return <AlertBar alert={alert} close={onCloseIconClick} />;
  } else {
    return null;
  }
};

const mapStateToProps = state => {
  const { alert } = state;
  return { alert };
};

export default connect(mapStateToProps)(AlertBarContainer);
