import { useEffect, useState } from "react";

import { compact } from "lodash";
import { toast } from "react-toastify";

import { snowtrade } from "../../../../api/snowtrade";

export const useRateMaps = (strHotelId) => {
  const [rateMaps, setRateMaps] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    snowtrade
      .get(`/api/hotels/${strHotelId}/amadeus-rate-maps`)
      .then((response) => setRateMaps(response.data))
      .finally(() => setIsFetching(false));
  }, []);

  const addRateMap = async () => {
    const dbRateMap = await snowtrade
      .post(`/api/hotels/amadeus-rate-maps`, { strHotelId })
      .then((response) => response.data);

    setRateMaps([...rateMaps, dbRateMap]);
  };

  const removeRateMap = async (index) => {
    const rateMap = rateMaps[index];
    await snowtrade.delete(`/api/hotels/amadeus-rate-maps/${rateMap._id}`);
    const newRateMaps = [...rateMaps];
    newRateMaps[index] = null;
    setRateMaps(compact(newRateMaps));
    toast.success("Removed rate map");
  };

  const saveRateMap = async (index) => {
    const newRateMap = rateMaps[index];

    // Mark this rate map as fetching
    const newRateMaps = [...rateMaps];
    newRateMaps[index] = { ...newRateMap, isFetching: true };
    setRateMaps(newRateMaps);

    // Perform the request
    const dbRateMap = await snowtrade
      .patch(`/api/hotels/amadeus-rate-maps/${newRateMap._id}`, newRateMap)
      .then((response) => response.data);

    // Mark rate map as saved
    const finalRateMaps = [...rateMaps];
    finalRateMaps[index] = dbRateMap;
    setRateMaps(finalRateMaps);
    toast.success("Saved rate map");
  };

  const handleChange = (index, newRateMap) => {
    const newRateMaps = [...rateMaps];
    newRateMaps[index] = newRateMap;
    const compactedRateMaps = compact(newRateMaps);
    setRateMaps(compactedRateMaps);
  };

  return {
    rateMaps,
    addRateMap,
    handleChange,
    isFetching,
    removeRateMap,
    saveRateMap,
  };
};
