import React from "react";

import { uniqueId } from "lodash";
import PropTypes from "prop-types";
import { ButtonGroup, Form, Header, Segment } from "semantic-ui-react";

/**
 * Renders a Segment containing a form which allows the user to edit a single
 * AmadeusRateMap.
 */
export const RateMapInput = ({
  index,
  rateMap,
  onChange,
  onSaveClick,
  onRemoveClick,
  hotelRatesOptions,
}) => {
  const handleChange = (name, value) =>
    onChange(index, { ...rateMap, [name]: value });

  const handleChangeEvent = ({ target: { name, value } }) =>
    handleChange(name, value);

  return (
    <Segment className="colored" loading={rateMap.isFetching}>
      <Form.Group widths="equal">
        <Form.Input
          name="amadeusRoomDescriptionRegExp"
          value={rateMap.amadeusRoomDescriptionRegExp || ""}
          label="Amadeus ROOM Description Regexp"
          onChange={handleChangeEvent}
          placeholder="offers.room.description.text"
          id={uniqueId("amadeusRoomDescriptionRegExp-input")}
        />
        <Form.Input
          name="amadeusOfferDescriptionRegExp"
          value={rateMap.amadeusOfferDescriptionRegExp || ""}
          label="Amadeus OFFER Description Regexp"
          onChange={handleChangeEvent}
          placeholder="offers.description.text"
          id={uniqueId("amadeusOfferDescriptionRegExp-input")}
        />
      </Form.Group>

      <Header>=</Header>

      <Form.Select
        label="Snowtrade Rate"
        id="strHotelRateId-dropdown"
        options={hotelRatesOptions}
        value={rateMap.strHotelRateId}
        onChange={(e, { value }) => handleChange("strHotelRateId", value)}
      />

      <ButtonGroup>
        <Form.Button primary onClick={() => onSaveClick(index)}>
          Save
        </Form.Button>
        <Form.Button onClick={() => onRemoveClick(index)}>Remove</Form.Button>
      </ButtonGroup>
    </Segment>
  );
};

//
// Prop Types
//

RateMapInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,

  index: PropTypes.number.isRequired,
  rateMap: PropTypes.object.isRequired,
};
