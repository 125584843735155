import { useEffect, useState } from "react";

import { snowtrade } from "../api/snowtrade";

export const useHotelById = (strHotelId) => {
  const [hotel, setHotel] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    snowtrade
      .get(`/api/hotels/${strHotelId}`)
      .then((response) => setHotel(response.data))
      .finally(() => setIsFetching(false));
  }, []);

  return { hotel, isFetching };
};
