import jsonpatch from "fast-json-patch";

import { snowtrade } from "../../api/snowtrade";
import { authHeader } from "../../helpers";

/**
 * Represents the hotel object as known by the backend.
 * This object is used to create JSON Patch objects.
 */
let dbHotel = {};

/**
 * Fetches the given hotel and stores it for later comparison.
 * Use this implementation of fetchHotel within the HotelInput context
 * to allow the patchHotel function to work properly.
 *
 *
 * @param {string} hotelId - The id of the hotel to fetch.
 */
export const fetchHotel = (hotelId) => {
  const headers = authHeader();
  return snowtrade
    .get("/api/hotels/" + hotelId, { headers })
    .then((response) => {
      const hotel = response.data;
      dbHotel = hotel;
      return hotel;
    });
};

/**
 * Patches the hotel in the database using JSON Patch.
 * It therefore only sends the changes to be made to the backend.
 *
 * @param {Object} hotel - The new hotel object.
 */
export const patchHotel = (hotel) => {
  const patch = jsonpatch.compare(dbHotel, hotel);
  console.log(patch);

  const headers = authHeader();
  headers["Content-Type"] = "application/json-patch+json";
  return snowtrade
    .patch("/api/hotels/" + hotel._id, patch, { headers })
    .then((response) => {
      dbHotel = response.data;
      return dbHotel;
    });
};

/**
 * Creates a new hotel in the database.
 *
 * @param {Object} hotel - The hotel object to create.
 */
export const postHotel = (hotel) => {
  const headers = authHeader();
  return snowtrade
    .post("/api/hotels", hotel, { headers })
    .then((response) => response.data);
};
