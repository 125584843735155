export const stayTypes = {
  FETCHLIST_REQUEST: "STAY_FETCHLIST_REQUEST",
  FETCHLIST_SUCCESS: "STAY_FETCHLIST_SUCCESS",
  FETCHLIST_FAILURE: "STAY_FETCHLIST_FAILURE",

  PROPERTYSTAY_FETCHLIST_REQUEST: "PROPERTYSTAY_FETCHLIST_REQUEST",
  PROPERTYSTAY_FETCHLIST_SUCCESS: "PROPERTYSTAY_FETCHLIST_SUCCESS",
  PROPERTYSTAY_FETCHLIST_FAILURE: "PROPERTYSTAY_FETCHLIST_FAILURE",

  CREATE_REQUEST: "STAY_CREATE_REQUEST",
  CREATE_SUCCESS: "STAY_CREATE_SUCCESS",
  CREATE_FAILURE: "STAY_CREATE_FAILURE",

  EDIT_REQUEST: "STAY_EDIT_REQUEST",
  EDIT_SUCCESS: "STAY_EDIT_SUCCESS",
  EDIT_FAILURE: "STAY_EDIT_FAILURE",
};
