import { useEffect, useState } from "react";

import { snowtrade } from "../../api";

export const fetchUsers = () =>
  snowtrade.get("/api/users").then((response) => response.data);

const INITIAL_STATE = {
  users: [],
  isFetching: false,
  hasFetched: false,
};

/**
 * @returns {{
 *   users: import("../constants").User[];
 *   isFetching: boolean;
 *   hasFetched: boolean;
 * }}
 */
export const useUsers = () => {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    if (state.hasFetched || state.isFetching) return;
    fetchUsers().then((users) =>
      setState({ users, isFetching: false, hasFetched: true })
    );
  });

  return state;
};
