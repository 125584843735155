import React, { PureComponent } from "react";

import { connect } from "react-redux";
import { Segment, Statistic } from "semantic-ui-react";

import { reselectPropertyArray } from "../property";
import { ResetStaysCacheButton } from "./ResetStaysCacheButton";

export class DashboardContainer extends PureComponent {
  render() {
    return (
      <>
        <h1 className="ui header">Dashboard</h1>
        <Segment>
          <ResetStaysCacheButton />
        </Segment>
        <div className="ui placeholder segment">
          <div className="ui icon header">
            <i className="icon chart bar outline" />
            Here will be a very nice dashboard
          </div>
        </div>
        <h2 className="ui header">Statistics</h2>
        <div className="ui one small statistics">
          <Statistic>
            <div className="value">{this.props.properties.length}</div>
            <div className="label">Properties</div>
          </Statistic>
        </div>
      </>
    );
  }
}

//
// Redux Connect
//

const mapStateToProps = (state) => ({
  user: state.auth.user,
  properties: reselectPropertyArray(state),
});

export default connect(mapStateToProps)(DashboardContainer);
