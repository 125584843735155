import React from "react";

import PropTypes from "prop-types";
import { Button, Divider, Segment } from "semantic-ui-react";

import {
  BedsForm,
  FacilitiesMarkdownForm,
  GeneralInformationForm,
  LayoutForm,
  OccupancyForm,
} from "./FormComponents";
import "./RoomInputView.css";

export const RoomInputView = ({
  values,
  onChange,
  onSubmit,
  loading,
  disabled,
}) => {
  return (
    <div className="RoomInputView">
      <h1>Edit {values.name || "Hotel Room"}</h1>
      <Segment className="colored">
        <GeneralInformationForm values={values} onChange={onChange} />
      </Segment>
      <Segment className="colored">
        <OccupancyForm occupancy={values.occupancy || {}} onChange={onChange} />
      </Segment>
      <Divider section />
      <BedsForm beds={values.beds} onChange={onChange} />
      <Divider section />
      <Segment className="colored">
        <LayoutForm values={values} onChange={onChange} />
      </Segment>

      <Segment className="colored">
        <FacilitiesMarkdownForm
          onChange={onChange}
          facilitiesMarkdown={values.facilitiesMarkdown}
        />
      </Segment>
      <Button
        id="submit"
        primary
        loading={loading}
        disabled={disabled}
        onClick={onSubmit}
      >
        Submit
      </Button>
    </div>
  );
};

//
// Prop Types
//

RoomInputView.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,

  /** If set, the submit button will show a loading indicator. */
  loading: PropTypes.bool,

  /** If set to true, the submit button is disabled. */
  disabled: PropTypes.bool,
};
