import React from 'react';

import { Form, Checkbox } from 'semantic-ui-react'

const CheckBoxArrayForm = ({ onChange, values, indent }) => {
  const checkBoxNames = Object.keys(values);

  return (
    <div className="checkBoxArray" style={{ marginLeft: indent }}>
      {checkBoxNames.map(name => (
        <Form.Input key={name}>
          <Checkbox
            name={name}
            label={values[name].label}
            onChange={onChange}
            checked={values[name].checked}
            key={name}
          />
        </Form.Input>
      ))}
    </div>
  );
};

export { CheckBoxArrayForm };