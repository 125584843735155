import React from "react";

import PropTypes from "prop-types";
import { Form, Select } from "semantic-ui-react";

export const ConnectedPropertiesInputView = ({
  connectedPropertyIds = [],
  allProperties = [],
  onChange,
  onSubmit,
  isLoading,
}) => {
  const propertyOptions = allProperties.map((property) => ({
    key: property._id,
    value: property._id,
    text: property.name,
    description: property.city,
  }));

  return (
    <Form>
      <Form.Field>
        <label htmlFor="connected-properties-input">Connected properties</label>
        <Select
          id="connected-properties-input"
          multiple
          options={propertyOptions}
          value={connectedPropertyIds}
          search
          onChange={(e, { value }) => onChange(value)}
        />
      </Form.Field>
      <Form.Button primary onClick={onSubmit} loading={isLoading}>
        Save properties
      </Form.Button>
    </Form>
  );
};

//
// Prop Types
//

ConnectedPropertiesInputView.propTypes = {
  connectedPropertyIds: PropTypes.arrayOf(PropTypes.string),
  allProperties: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
