import React from "react";

import _ from "lodash";
import { connect } from "react-redux";

import {
  PaginationContainer,
  PaginationPageSizeDropdownContainer,
} from "../pagination";
import { PropertyGrid } from "../property";

const AdvancedSearchResult = (props) => {
  const { hasSearched, searchResults, paginationData } = props;

  if (!_.isEmpty(searchResults) && hasSearched === true) {
    return (
      <div className="ui grid segment" style={{ marginBottom: "10rem" }}>
        <div className="row">
          <div className="twelve wide column">
            <h3 className="ui header">Results: {searchResults.length}</h3>
          </div>
          <div className="four wide column right aligned">
            <PaginationPageSizeDropdownContainer />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <PropertyGrid properties={paginationData} />
          </div>
        </div>
        <div className="row centered">
          <PaginationContainer items={searchResults} />
        </div>
      </div>
    );
  } else if (_.isEmpty(searchResults) && hasSearched === true) {
    return (
      <div className="ui placeholder segment" style={{ marginBottom: "10rem" }}>
        <div className="row">
          <div className="twelve wide column">
            <h3 className="ui header">Results: {searchResults.length}</h3>
          </div>
        </div>
        <div className="ui icon header">
          <i className="search icon"></i>
          We do not have any results matching your query
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};

const mapStateToProps = ({ search, pagination }) => {
  const { data } = pagination;

  return {
    hasSearched: search.hasSearched,
    searchResults: search.results,
    paginationData: data,
  };
};

export default connect(mapStateToProps)(AdvancedSearchResult);
