import React, { useState } from "react";

import PropTypes from "prop-types";
import { Form, Segment } from "semantic-ui-react";

import { snowtrade } from "../../api/snowtrade";

const useAmadeusSearch = (amadeusHotelId) => {
  const [formState, setFormState] = useState({ amadeusHotelId });
  const [amadeusResponse, setAmadeusResponse] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const handleInputChange = ({ target: { name, value } }) =>
    setFormState({ ...formState, [name]: value });

  const search = () => {
    setIsFetching(true);
    snowtrade
      .get(
        `/api/hotels/amadeus-hotel-offers/by-hotel/${formState.amadeusHotelId}`,
        {
          params: {
            checkInDate: formState.checkInDate,
            checkOutDate: formState.checkOutDate,
          },
        }
      )
      .then((response) => setAmadeusResponse(response.data))
      .finally(() => setIsFetching(false));
  };

  return { formState, handleInputChange, amadeusResponse, isFetching, search };
};

export const AmadeusSearch = ({ amadeusHotelId }) => {
  const {
    formState,
    handleInputChange,
    isFetching,
    search,
    amadeusResponse,
  } = useAmadeusSearch(amadeusHotelId);

  return (
    <>
      <Form>
        <Form.Input
          label="Amadeus Hotel Id"
          name="amadeusHotelId"
          value={formState.amadeusHotelId || ""}
          onChange={handleInputChange}
        />
        <Form.Input
          label="Check In Date"
          name="checkInDate"
          value={formState.checkInDate}
          onChange={handleInputChange}
          type="date"
        />
        <Form.Input
          label="Check Out Date"
          name="checkOutDate"
          value={formState.checkOutDate}
          onChange={handleInputChange}
          type="date"
          min={formState.checkInDate}
        />
        <Form.Button primary onClick={search}>
          Search
        </Form.Button>
      </Form>
      <Segment loading={isFetching} disabled={!isFetching && !amadeusResponse}>
        <Form>
          <Form.TextArea
            value={JSON.stringify(amadeusResponse, null, 4) || ""}
            style={{ minHeight: amadeusResponse && "50vh" }}
          />
        </Form>
      </Segment>
    </>
  );
};

//
// Prop Types
//

AmadeusSearch.propTypes = {
  /** If set, this is the default amadeusHotelId to use in the search */
  amadeusHotelId: PropTypes.string,
};
