import React from "react";

import PropTypes from "prop-types";

import { fetchHotelRooms } from "../api";
import { HotelRoomsTableView } from "./HotelRoomsTableView";

class HotelRoomsTableContainer extends React.PureComponent {
  state = { rooms: [] };

  componentDidMount() {
    fetchHotelRooms(this.props.hotelId).then((rooms) =>
      this.setState({ rooms })
    );
  }

  render() {
    return (
      <HotelRoomsTableView
        rooms={this.state.rooms}
        hotelId={this.props.hotelId}
      />
    );
  }
}

export default HotelRoomsTableContainer;

HotelRoomsTableContainer.propTypes = {
  hotelId: PropTypes.string.isRequired,
};
