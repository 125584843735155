import _ from "lodash";

import { externalTypes } from "../operator";
import { stayTypes } from "./types";

const INITIAL_STATE = {
  isImporting: false,
  isFetching: false,
  items: {},
};

/** Maps action types to the corresponding reducer. */
const actionReducerMap = {
  [stayTypes.FETCHLIST_REQUEST]: reduceFetchlistRequest,
  [stayTypes.FETCHLIST_SUCCESS]: reduceFetchlistSuccess,
  [stayTypes.FETCHLIST_FAILURE]: reduceFetchlistFailure,
  [stayTypes.PROPERTYSTAY_FETCHLIST_REQUEST]: reducePropertyStaysFetchRequest,
  [stayTypes.PROPERTYSTAY_FETCHLIST_SUCCESS]: reducePropertyStaysFetchSuccess,
  [stayTypes.EDIT_REQUEST]: reduceEditRequestAction,
  [stayTypes.EDIT_SUCCESS]: reduceEditSuccessAction,
  [externalTypes.STAY_FETCHLIST_REQUEST]: reduceImportStayRequest,
  [externalTypes.STAY_FETCHLIST_SUCCESS]: reduceImportStaySuccess,
};

/** Reducer for stays in redux store */
export default (state = INITIAL_STATE, action) => {
  const reducer = actionReducerMap[action.type];
  if (!reducer) return state;

  return reducer(state, action);
};

//
// ------------------------ Reducers ------------------------------------
//

function reduceFetchlistRequest(state) {
  return { ...state, isFetching: true };
}

function reduceFetchlistSuccess(state, action) {
  return {
    ...state,
    isFetching: false,
    items: _.groupBy(action.payload, "_property"),
  };
}

function reduceFetchlistFailure(state) {
  return { ...state, isFetching: false, isFailure: true };
}

function reducePropertyStaysFetchRequest(state, action) {
  const { propertyId } = action.payload;

  return {
    ...state,
    items: { ...state.items, [propertyId]: { isFetching: true } },
  };
}

function reducePropertyStaysFetchSuccess(state, action) {
  if (!_.isNil(action.payload._property)) {
    const propertyId = action.payload._property;
    return {
      ...state,
      items: {
        ...state.items,
        [propertyId]: { message: action.payload.message },
      },
    };
  }
  const propertyId = !_.isNil(action.payload[0])
    ? action.payload[0]._property
    : null;
  return {
    ...state,
    items: { ...state.items, [propertyId]: action.payload },
  };
}

function reduceImportStayRequest(state) {
  return { ...state, isImporting: true };
}

function reduceImportStaySuccess(state) {
  return { ...state, isImporting: false };
}

/**
 * Reduces the "STAY_EDIT_REQUEST" action. Merges the existing stay with the new
 * stay and sets isPatching to true.
 */
function reduceEditRequestAction(state, action) {
  const {
    payload: { stayId, newStay },
  } = action;
  const propertyId = newStay._property;

  // Find the old stay in the propertyStays array
  const propertyStaysBefore = state.items[propertyId] || [];
  const stayIndex = propertyStaysBefore.findIndex((s) => s._id === stayId);
  if (stayIndex < 0) return state;
  const stayBefore = propertyStaysBefore[stayIndex];

  // Construct new stay
  const stayToInsert = { ...stayBefore, ...newStay, isPatching: true };

  // Insert new stay into property stays
  let newPropertyStays = [...propertyStaysBefore];
  newPropertyStays.splice(stayIndex, 1, stayToInsert);

  return {
    ...state,
    items: { ...state.items, [propertyId]: newPropertyStays },
  };
}

/**
 * Reduces the "STAY_EDIT_SUCCESS" action by updating the corresponding stay in
 * the redux store
 */
function reduceEditSuccessAction(state, action) {
  const stay = action.payload;
  const propertyId = stay._property;

  // Find the old stay in the propertyStays array
  const propertyStaysBefore = state.items[propertyId] || [];
  const stayIndex = propertyStaysBefore.findIndex((s) => s._id === stay._id);
  if (stayIndex < 0) return state;

  // Insert new stay into property stays
  let newPropertyStays = [...propertyStaysBefore];
  newPropertyStays.splice(stayIndex, 1, stay);

  return {
    ...state,
    items: { ...state.items, [propertyId]: newPropertyStays },
  };
}
