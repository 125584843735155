import React from "react";

import PropTypes from "prop-types";
import MarkdownEditor from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";

export const HotelDescriptionForm = ({ descriptionMarkdown, onChange }) => {
  const handleChange = (value) => {
    const newValue = value !== "" ? value : null;
    onChange("descriptionMarkdown", newValue);
  };

  return (
    <MarkdownEditor
      value={descriptionMarkdown || ""}
      onChange={handleChange}
      options={{ spellChecker: false }}
    />
  );
};

//
// Prop Types
//

HotelDescriptionForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  descriptionMarkdown: PropTypes.string,
};
