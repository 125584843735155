import React from 'react';

import { Link } from 'react-router-dom';

import logo from '../assets/images/snowtrade-royale_transp_300dpi.png';
import SidebarMenu from './SidebarMenu';

const SideMenu = () => {
  return (
    <div id="sideMenu">
      <Link to="/cms">
        <img src={logo} alt="Snowtrade Royale Logo" />
      </Link>
      <SidebarMenu />
    </div>
  );
};

export default SideMenu;
