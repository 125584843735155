import React from "react";

import { SearchBar } from "../search";

const MenuBar = (props) => {
  const { onLogoutButtonClick, user } = props;

  return (
    <div id="menuBar">
      <div className="itemCluster">
        <SearchBar />
        <div className="ui button white">{user.username}</div>
        <button
          onClick={onLogoutButtonClick}
          className="ui item negative basic button"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default MenuBar;
