import React from "react";

import PropTypes from "prop-types";
import { Form, Header } from "semantic-ui-react";

import {
  createInputChangeEventHandler,
  createSelectionEventHandler,
} from "../../form/changeHandlers";
import Tab from "../../tab/Tab";

const SERVICE_LEVEL_OPTIONS_EN = [
  { key: "selfcatered", text: "Selfcatered", value: "Selfcatered" },
  { key: "bed", text: "Bed & Breakfast", value: "Bed & Breakfast" },
  { key: "catered", text: "Catered", value: "Catered" },
];

export const ServiceLevelInput = ({ value, onChange }) => (
  <Form.Dropdown
    selection
    multiple
    name="serviceLevels"
    label="Available service levels"
    value={value}
    onChange={createSelectionEventHandler(onChange)}
    options={SERVICE_LEVEL_OPTIONS_EN}
  />
);

/**
 * Renders a form where the user can input all services included in the property
 * in different service levels.
 */
export const ServicesIncludedForm = ({
  servicesIncluded = {},
  serviceLevels = [],
  onChange,
}) => {
  if (serviceLevels.length === 0) {
    return "Enter the service levels of this property first.";
  }

  if (serviceLevels.length === 1) {
    const [serviceLevel] = serviceLevels;
    return (
      <ServicesForm
        value={servicesIncluded[serviceLevel]}
        onChange={onChange}
        name={`servicesIncluded.${serviceLevel}`}
      />
    );
  }

  const tabProps = serviceLevels.map((level) => ({
    title: level,
    content: (
      <ServicesForm
        value={servicesIncluded[level]}
        name={`servicesIncluded.${level}`}
        onChange={onChange}
      />
    ),
  }));

  return <Tab tabProps={tabProps} />;
};

/**
 * Renders a Form that takes an array of {service, serviceCategory} objects as value
 *
 * @augments {React.PureComponent<State, Props>}
 */
export class ServicesForm extends React.PureComponent {
  handleChange = (index, value) => {
    const servicesBefore = this.props.value || [];
    let newServices = [...servicesBefore];

    if (value) {
      newServices[index] = value;
    } else {
      newServices.splice(index, 1);
    }

    this.props.onChange(this.props.name, newServices);
  };

  render() {
    const servicesInput = this.props.value || [];
    const servicesToRender = [...servicesInput, { service: "" }];

    return servicesToRender.map((serviceObj, index) => (
      <ServicesFormRow
        index={index}
        serviceObject={serviceObj}
        onChange={this.handleChange}
        key={serviceObj._id || index}
      />
    ));
  }
}

/**
 * This component only renders a row in the ServicesForm component
 *
 * @augments {React.PureComponent<State, Props>}
 */
class ServicesFormRow extends React.PureComponent {
  handleChange = (_name, value) => {
    const newValue =
      value === ""
        ? null
        : {
            service: value,
            serviceCategory: value,
          };

    this.props.onChange(this.props.index, newValue);
  };

  render() {
    const { serviceObject } = this.props;
    return (
      <Form.Input
        value={serviceObject.service}
        onChange={createInputChangeEventHandler(this.handleChange)}
      />
    );
  }
}

ServicesFormRow.propTypes = {
  /** Change handler to be called with (index, newServiceObj) */
  onChange: PropTypes.func.isRequired,

  /** The index of the current service obj in the array of existing services. */
  index: PropTypes.number.isRequired,

  serviceObject: PropTypes.shape({
    service: PropTypes.string,
    serviceCategory: PropTypes.string,
  }),
};

//
// Property Service Information Form
//

/**
 * This component renders a form to enter the services included per service
 * level for a property.
 */
export const PropertyServiceInformationForm = ({ property, onChange }) => (
  <Form>
    <ServiceLevelInput value={property.serviceLevels} onChange={onChange} />
    <Header>Services Included</Header>
    <ServicesIncludedForm
      serviceLevels={property.serviceLevels}
      servicesIncluded={property.servicesIncluded}
      onChange={onChange}
    />
  </Form>
);

PropertyServiceInformationForm.propTypes = {
  property: PropTypes.shape({
    serviceLevels: PropTypes.array,
    servicesIncluded: PropTypes.object,
  }),
  onChange: PropTypes.func,
};
