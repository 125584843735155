import { useMemo } from "react";

import { orderBy, isEmpty } from "lodash";
import { useSelector } from "react-redux";

import { reselectPropertyArray } from "../../../property";

export const usePropertiesDropdownOptions = () => {
  const properties = useSelector(reselectPropertyArray);

  return useMemo(
    () => mapPropertiesToDropdownOptions(properties),
    [properties]
  );
};

const mapPropertiesToDropdownOptions = (properties) => {
  if (isEmpty(properties)) return [];

  const propertyOptions = properties.map((property) => {
    return {
      key: property.name + property.city + property._id, // we had problems with "Chalet La Datcha", one in Verbier and one in Courchevel. That totally broke down!
      title: property.name || "",
      description: property.city,
      link: `/cms/properties/${property._id}`,
    };
  });
  return orderBy(propertyOptions, (p) => p.title.length, ["asc"]);
};
