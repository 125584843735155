import React from "react";

import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import { costPropType } from "./types";

export const CostInput = ({ cost = {}, onChange, index }) => {
  const handleChange = ({ target: { name, value } }) => {
    const newCost = { ...cost, [name]: value };
    onChange(index, newCost);
  };

  return (
    <Form.Group widths="equal">
      <Form.Input
        name="name"
        value={cost.name}
        label="Name"
        onChange={handleChange}
      />
      <Form.Input
        name="description"
        value={cost.description}
        label="Description"
        onChange={handleChange}
      />
    </Form.Group>
  );
};

//
// Prop Types
//

CostInput.propTypes = {
  cost: costPropType.isRequired,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};
