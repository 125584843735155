import React, { useState } from "react";

import { toast } from "react-toastify";
import { Button } from "semantic-ui-react";

import { snowtrade } from "../../api";

export const ResetStaysCacheButton = () => {
  const [isLoading, setIsLoading] = useState(false);

  const resetStaysCache = () => {
    setIsLoading(true);
    snowtrade
      .post("/api/stays/cacheReset")
      .then(() => toast.success("Success"))
      .catch(() => toast.error("oops"))
      .finally(() => setIsLoading(false));
  };

  return (
    <Button loading={isLoading} onClick={resetStaysCache}>
      Reset Stays Cache
    </Button>
  );
};
