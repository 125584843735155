import React from "react";

import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import {
  createInputChangeEventHandler,
  createSelectionEventHandler,
} from "../../../form/changeHandlers";

/**
 * The categories are directly displayed in the frontend as search criteria.
 * That is why the admin should only choose between set options, in order not to
 * screw up those search criteria with misspelled facilityCategories.
 */
const facilityCategoryOptions = [
  { text: "Other", value: "other", key: "other" },
  { text: "Ski-in Ski-out", value: "Ski-in Ski-out", key: "Ski-in Ski-out" },
  { text: "Steam room", value: "Steam room", key: "Steam room" },
  { text: "Jacuzzi", value: "Jacuzzi", key: "Jacuzzi" },
  { text: "Sauna", value: "Sauna", key: "Sauna" },
  { text: "Massage room", value: "Massage room", key: "Massage room" },
  { text: "Swimming pool", value: "Swimming pool", key: "Swimming pool" },
  { text: "Home cinema", value: "Home cinema", key: "Home cinema" },
  { text: "Fitness room", value: "Fitness room", key: "Fitness room" },
  { text: "Night Club", value: "Night Club", key: "Night Club" },
  { text: "Elevator", value: "Elevator", key: "Elevator" },
  { text: "Fireplace", value: "Fireplace", key: "Fireplace" },
  { text: "Treatment room", value: "Treatment room", key: "Treatment room" },
];

/**
 * This component renders a single row in the FacilitiesForm component.
 *
 * @augments {React.PureComponent<State, Props>}
 */
export class FacilityRowForm extends React.PureComponent {
  handleChange = (name, value) => {
    const { onChange, index } = this.props;

    // Remove facilityObj if the user deleted the facility name
    if (name === "facility" && !value) {
      onChange(index, null);
      return;
    }

    const facilityBefore = this.props.facilityObject || {};
    const newFacility = { ...facilityBefore, [name]: value };
    onChange(index, newFacility);
  };

  render() {
    const { facilityObject = {} } = this.props;
    return (
      <Form.Group widths="equal">
        <Form.Input
          value={facilityObject.facility}
          onChange={createInputChangeEventHandler(this.handleChange)}
          name="facility"
          placeholder="Facility"
        />
        <Form.Select
          value={facilityObject.facilityCategory}
          onChange={createSelectionEventHandler(this.handleChange)}
          name="facilityCategory"
          placeholder="Facility Category"
          options={facilityCategoryOptions}
          search
        />
      </Form.Group>
    );
  }
}

//
// Prop Types
//

FacilityRowForm.propTypes = {
  /** Change handler to be called with (index, newFacilityObj) */
  onChange: PropTypes.func.isRequired,

  /** The index of the current facility obj in the array of existing facilities. */
  index: PropTypes.number.isRequired,

  facilityObject: PropTypes.shape({
    facility: PropTypes.string,
    facilityCategory: PropTypes.string,
  }),
};
