import React from "react";

import { Link } from "react-router-dom";
import ReactTable from "react-table";
import { Button } from "semantic-ui-react";

import { reactTableRegexFilterMethod } from "../../helpers/reactTableRegexFilterMethod";
import { fetchHotels } from "../api";
import { SyncHotelImagesButton } from "../SyncHotelImagesButton";
import { ContractStatusCell } from "./ContractStatusCell";

const CONTRACT_STATUS_ORDER = ["confirmed", "pending", "none"];

const getContractStatusSortIndex = (status) =>
  CONTRACT_STATUS_ORDER.includes(status)
    ? CONTRACT_STATUS_ORDER.findIndex((s) => s === status)
    : 99;

class HotelTable extends React.PureComponent {
  state = { hotels: [] };

  componentDidMount() {
    fetchHotels().then((hotels) => this.setState({ hotels }));
  }

  renderTable() {
    const columns = [
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
        Cell: (row) => (
          <Link to={`/cms/hotels/${row.original._id}`}>
            {row.original.name}
          </Link>
        ),
      },
      {
        Header: "Country",
        accessor: "location.country",
        filterable: true,
      },
      {
        Header: "City",
        accessor: "location.city",
        filterable: true,
      },
      {
        accessor: "contractStatus",
        maxWidth: 40,
        Cell: (row) => <ContractStatusCell hotel={row.original} />,
        sortMethod: (a, b) =>
          getContractStatusSortIndex(a) - getContractStatusSortIndex(b),
        filterable: true,
        filterMethod: ({ value }, { contractStatus }) =>
          (contractStatus || "").match(value),
      },
      {
        Cell: (row) => (
          <Link to={`/cms/hotels/${row.original._id}/rooms`}>Rooms</Link>
        ),
        maxWidth: 80,
      },
      {
        Cell: (row) => (
          <Link to={`/cms/hotels/${row.original._id}/edit`}>
            Edit <i className="edit icon" />
          </Link>
        ),
        maxWidth: 80,
      },
      {
        Cell: (row) => (
          <Link to={`/cms/hotels/${row.original._id}/rates`}>
            Rates <i className="ticket sign icon" />
          </Link>
        ),
        maxWidth: 80,
      },
      {
        Cell: (row) => (
          <Link to={`/cms/hotels/${row.original._id}/amadeus-manager`}>
            Amadeus <i className="cloud download icon" />
          </Link>
        ),
        maxWidth: 100,
      },
      {
        Cell: (row) => (
          <SyncHotelImagesButton hotelId={row.original._id} tableCellDesign />
        ),
        maxWidth: 80,
      },
    ];

    return (
      <ReactTable
        className="-striped -highlight"
        columns={columns}
        data={this.state.hotels}
        defaultFilterMethod={reactTableRegexFilterMethod}
        defaultSorted={[{ id: "name" }]}
      />
    );
  }

  render() {
    return (
      <>
        {this.renderTable()}
        <Link to="hotels/new">
          <Button floated="right">New Hotel</Button>
        </Link>
      </>
    );
  }
}

export { HotelTable };
