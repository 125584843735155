import React from "react";

import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import { userRoleTypes } from "../constants";

const ROLE_OPTIONS = Object.values(userRoleTypes).map((role) => ({
  key: role,
  value: role,
  text: role,
}));

export const UserFormView = ({
  user = {},
  onChange,
  onSubmit,
  submitText = "Submit",
  isLoading,
}) => {
  const changeEventToUpdate = ({ target: { name, value } }) =>
    onChange({ [name]: value });
  const onRoleChange = (e, { value }) => onChange({ role: value });

  return (
    <Form loading={isLoading}>
      <Form.Select
        label="Role"
        value={user.role}
        options={ROLE_OPTIONS}
        name="role"
        onChange={onRoleChange}
      />
      <Form.Group widths="equal">
        <Form.Input
          name="username"
          label="Username"
          value={user.username || ""}
          onChange={changeEventToUpdate}
        />
        <Form.Input
          name="name"
          label="Full name (deprecated)"
          value={user.name || ""}
          onChange={changeEventToUpdate}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          label="First name"
          onChange={changeEventToUpdate}
          name="firstName"
          value={user.firstName || ""}
        />
        <Form.Input
          label="Last name"
          onChange={changeEventToUpdate}
          value={user.lastName || ""}
          name="lastName"
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          name="company"
          label="Company"
          value={user.company || ""}
          onChange={changeEventToUpdate}
        />
        <Form.Input
          name="email"
          label="Email"
          value={user.email || ""}
          onChange={changeEventToUpdate}
        />
      </Form.Group>
      <Form.Input
        name="password"
        label="Password (5 characters min)"
        value={user.password || ""}
        onChange={changeEventToUpdate}
      />

      <Form.Button primary onClick={onSubmit}>
        {submitText}
      </Form.Button>
    </Form>
  );
};

//
// Prop Types
//

UserFormView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  submitText: PropTypes.string,
  user: PropTypes.shape({
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    company: PropTypes.string,
    role: PropTypes.string,
    email: PropTypes.string,
  }),
};
