import { snowtrade } from "../../../api";

export const fetchOperator = (operatorId) =>
  snowtrade
    .get(`/api/property-operators/${operatorId}`)
    .then((response) => response.data);

export const postNewRentalChannel = (rentalChannel) =>
  snowtrade.post("/api/property-rental-channels", rentalChannel);

export const deleteRentalChannel = (channelId) =>
  snowtrade.delete(`/api/property-rental-channels/${channelId}`);
