import React from "react";

import { Form } from "semantic-ui-react";

import { createSelectionEventHandler } from "../../../form/changeHandlers";

const COUNTRY_OPTIONS_EN = [
  { key: "france", text: "France", value: "France" },
  { key: "switzerland", text: "Switzerland", value: "Switzerland" },
  { key: "austria", text: "Austria", value: "Austria" },
  { key: "italy", text: "Italy", value: "Italy" },
];

export const PropertyCountryInput = ({ value, onChange }) => (
  <Form.Dropdown
    label="Country"
    selection
    name="country"
    value={value || ""}
    onChange={createSelectionEventHandler(onChange)}
    placeholder="The country the property is located in"
    options={COUNTRY_OPTIONS_EN}
  />
);
