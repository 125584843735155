import React from "react";

import { isArray } from "lodash";
import { Link } from "react-router-dom";

const SidebarMenuItem = (props) => {
  const { header, items } = props;

  if (!header) return null;

  // Render only header if no items are given
  if (!items) {
    return (
      <Link to={header[1]} className="item">
        {header[0]}
      </Link>
    );
  }

  const menuItems = items.map((item) => {
    return (
      <Link to={item[1]} key={item[0]} className="item">
        {item[0]}
      </Link>
    );
  });

  const headerComponent = isArray(header) ? (
    <Link to={header[1]}>
      <div className="header">{header[0]}</div>
    </Link>
  ) : (
    <div className="header">{header}</div>
  );

  return (
    <div className="item">
      {headerComponent}
      <div className="menu">{menuItems}</div>
    </div>
  );
};

export default SidebarMenuItem;
