import { takeLatest, call, put } from "redux-saga/effects";

import { alertActions } from "../alert";
import { snowtrade } from "../api";
import { history } from "../helpers";
import { authTypes } from "./types";

export function* watchLogin() {
  yield takeLatest(authTypes.LOGIN_REQUEST, login);
}

export function* watchLogout() {
  yield takeLatest(authTypes.LOGOUT_REQUEST, logout);
}

function* login(request) {
  try {
    const res = yield call(snowtrade.post, "/auth/local", request.payload);

    localStorage.setItem("auth", JSON.stringify(res.data));
    yield put({ type: authTypes.LOGIN_SUCCESS, payload: res.data });
  } catch (error) {
    const { data, status } = error.response;
    if (status === 401) {
      yield put({ type: authTypes.LOGIN_FAILURE, payload: error.toString() });
      yield put(alertActions.error(data.info.message));
    } else if (status === 400) {
      yield put({ type: authTypes.LOGIN_FAILURE });
      yield put(alertActions.error(error.toString()));
    }
  }
}

function* logout() {
  try {
    yield snowtrade.get("/api/logout");
    localStorage.removeItem("auth");
    history.push("/login");
    yield put({ type: authTypes.LOGOUT_SUCCESS });
  } catch (err) {
    yield put(alertActions.error(err.toString()));
  }
}
