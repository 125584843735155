import React from "react";

import { Form, Header } from "semantic-ui-react";

import { DistancesForm } from "./DistancesForm";

export class LocationForm extends React.PureComponent {
  handleChange = (name, value) =>
    this.props.onChange("location", { ...this.props.location, [name]: value });

  handleInputChangeEvent = ({ target: { name, value } }) =>
    this.handleChange(name, value);

  render() {
    const { location } = this.props;

    return (
      <Form>
        <Header>Location</Header>
        <Form.Group widths="equal" onChange={this.handleInputChangeEvent}>
          <Form.Input name="city" label="City" value={location.city || ""} />
          <Form.Input
            name="country"
            label="Country"
            value={location.country || ""}
          />
        </Form.Group>
        <Form.Group widths="equal" onChange={this.handleInputChangeEvent}>
          <Form.Input
            name="sector"
            label="Sector"
            value={location.sector || ""}
            placeholder="Courchevel 1850"
          />
          <Form.Input
            name="zipCode"
            label="ZIP Code"
            value={location.zipCode || " "}
          />
        </Form.Group>
        <CoordinatesInput
          onChange={this.handleInputChangeEvent}
          coordinates={location.coordinates || {}}
        />
        <DistancesForm
          distanceStrings={location.distanceStrings}
          onChange={this.handleChange}
        />
      </Form>
    );
  }
}

class CoordinatesInput extends React.PureComponent {
  handleChange = ({ target }) => {
    const { name, value } = target;
    const newCoordinatesState = { ...this.props.coordinates, [name]: value };
    this.props.onChange({
      target: { name: "coordinates", value: newCoordinatesState },
    });
  };

  render() {
    return (
      <Form.Group widths="equal" onChange={this.handleChange}>
        <Form.Input
          name="latitude"
          label="Latitude"
          type="number"
          value={this.props.coordinates.latitude}
        />
        <Form.Input
          name="longitude"
          label="Longitude"
          type="number"
          value={this.props.coordinates.longitude}
        />
      </Form.Group>
    );
  }
}
