import React from 'react';

import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

import { AlertBarContainer } from '../alert';
import logo from '../assets/images/snowtrade-royale_transp_300dpi.png';

const renderError = (touched, error) => {
  if (touched && error) {
    return (
      <div className="ui error message">
        <div className="header">{error}</div>
      </div>
    );
  }
};

const renderField = ({ input, label, type, meta: { touched, error } }) => {
  const className = `field ${error && touched ? 'error' : ''}`;
  return (
    <div className={className}>
      <label>{label}</label>
      <div>
        <input {...input} placeholder={label} type={type} />
        {renderError(touched, error)}
      </div>
    </div>
  );
};

const LoginForm = props => {
  const onSubmit = formValues => {
    props.onSubmit(formValues);
  };

  const { error, handleSubmit, submitting, loggingIn } = props;
  return (
    <div className="ui middle aligned center aligned grid">
      <div className="column" style={{ maxWidth: '450px', marginTop: '4rem' }}>
        <h2 className="ui center aligned icon header">
          <Link to="/" className="ui image" style={{ width: '12rem', marginBottom: '3rem' }}>
            <img className="ui image" src={logo} alt="Snowtrade Royale" />
          </Link>
          <div className="content">Login to your account</div>
        </h2>
        <AlertBarContainer />
        <form
          className={`ui large form error ${loggingIn ? 'loading' : ''}`}
          onSubmit={handleSubmit(onSubmit)}>
          <div className="ui stacked segment">
            <Field name="username" type="text" component={renderField} label="Username" />
            <Field name="password" type="password" component={renderField} label="Password" />
            {error && <strong>{error}</strong>}
            <button className="ui fluid large submit button" type="submit" disabled={submitting}>
              Log In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const validate = formValues => {
  const errors = {};

  if (!formValues.username) {
    errors.username = 'Required';
  }

  if (!formValues.password) {
    errors.password = 'Required';
  }

  return errors;
};

export default reduxForm({
  form: 'submitValidation',
  validate
})(LoginForm);
