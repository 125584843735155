import "./HotelInputView.css";

import React from "react";

import PropTypes from "prop-types";
import { Button, Header, Segment } from "semantic-ui-react";

import { AccountingForm } from "./FormComponents/AccountingForm";
import { AdditionalCostsForm } from "./FormComponents/AdditionalCostsForm";
import { AmexAmenitiesMarkdownForm } from "./FormComponents/AmexAmenitiesMarkdownForm";
import { BookingPolicyMarkdownForm } from "./FormComponents/BookingPolicyMarkdownForm";
import { ContactForm } from "./FormComponents/ContactForm";
import { ContractForm } from "./FormComponents/ContractForm";
import { FacilitiesForm } from "./FormComponents/Facilities";
import { GeneralInformationForm } from "./FormComponents/GeneralInformation";
import { HotelDescriptionForm } from "./FormComponents/HotelDescriptionsForm";
import { LocationForm } from "./FormComponents/Location";

const SubmitButton = (props) => (
  <Button id="submit" {...props}>
    Submit
  </Button>
);

/** This view controls the form components needed to manage hotel objects. */
const HotelInputView = ({
  hotel,
  onChange,
  onSubmit,
  isLoading,
  isDisabled,
}) => {
  return (
    <div className="hotelInputView">
      <Segment className="colored">
        <ContractForm value={hotel.contractStatus} onChange={onChange} />
      </Segment>
      <Segment className="colored">
        <AccountingForm hotel={hotel} onChange={onChange} />
      </Segment>
      <Segment className="colored">
        <GeneralInformationForm values={hotel} onChange={onChange} />
      </Segment>
      <Segment className="colored">
        <LocationForm location={hotel.location || {}} onChange={onChange} />
      </Segment>
      <Segment className="colored">
        <Header>Hotel Description</Header>
        <HotelDescriptionForm
          descriptionMarkdown={hotel.descriptionMarkdown}
          onChange={onChange}
        />
      </Segment>
      <Segment className="colored">
        <FacilitiesForm facilities={hotel.facilities} onChange={onChange} />
      </Segment>

      <Segment className="colored">
        <AdditionalCostsForm
          additionalCosts={hotel.additionalCosts}
          onChange={onChange}
        />
      </Segment>
      <Segment className="colored">
        <Header>Amex Amenities</Header>
        <AmexAmenitiesMarkdownForm
          amexAmenitiesMarkdown={hotel.amexAmenitiesMarkdown}
          onChange={onChange}
        />
      </Segment>
      <Segment className="colored">
        <Header>Booking Policy</Header>
        <BookingPolicyMarkdownForm
          bookingPolicyMarkdown={hotel.bookingPolicyMarkdown}
          onChange={onChange}
        />
      </Segment>
      <Segment>
        <ContactForm contact={hotel.contact || {}} onChange={onChange} />
      </Segment>

      <SubmitButton
        primary
        onClick={onSubmit}
        loading={isLoading}
        disabled={isDisabled}
      />
    </div>
  );
};

export { HotelInputView };

//
// Prop Types
//

HotelInputView.propTypes = {
  hotel: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
