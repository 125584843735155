import React from 'react';

const AccordionElement = ({
  title,
  content,
  index,
  activeIndex,
  onTitleClick
}) => {
  const active = index === activeIndex ? 'active' : '';
  return (
    <>
      <div className={`title ${active}`} onClick={onTitleClick}>
        <i className="dropdown icon"></i>
        {title}
      </div>
      <div className={`content ${active}`}>
        <div className="transition visible">{content}</div>
      </div>
    </>
  );
};

export default AccordionElement;
