import { authTypes } from "./types";

let auth = JSON.parse(localStorage.getItem("auth"));

const INITIAL_STATE = auth
  ? { isLoggedIn: true, user: auth.user }
  : { isLoggedIn: false, user: {} };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case authTypes.LOGIN_REQUEST:
      return {
        user: action.payload,
      };
    case authTypes.LOGIN_SUCCESS: {
      const { user } = action.payload;
      return {
        isLoggedIn: true,
        user,
      };
    }
    case authTypes.LOGOUT_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
