import React, { useState } from "react";

import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { snowtrade } from "../api";
import { UserForm } from "./UserForm";

const postNewUser = (user) =>
  snowtrade
    .post("/api/user", user)
    .then((response) => response.data)
    .catch((e) => {
      throw new Error(e.response.data);
    });

export const CreateUserForm = ({ afterSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (newUser) => {
    setIsLoading(true);
    postNewUser(newUser)
      .then((dbUser) => {
        toast.success("Created new user");

        if (afterSubmit) {
          afterSubmit(dbUser);
        }
      })
      .catch((e) => toast.error(e.toString()))
      .finally(() => setIsLoading(false));
  };

  return (
    <UserForm
      isLoading={isLoading}
      onSubmit={onSubmit}
      submitText="Create User"
    />
  );
};

//
// Prop Types
//

CreateUserForm.propTypes = {
  /** An optional function that gets called after the user was created. */
  afterSubmit: PropTypes.func,
};
