import React from 'react';

import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, accessFor = ['admin'], ...rest }) => {
  let authenticated = false;
  if (localStorage.getItem('auth')) {
    const auth = JSON.parse(localStorage.getItem('auth'));
    if (accessFor.includes(auth.user.role)) {
      authenticated = true;
    }
  }

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
