import React from "react";

import { Form } from "semantic-ui-react";

export const AccountingForm = ({ hotel, onChange }) => {
  return (
    <Form>
      <Form.Select
        label="Invoice Timing"
        options={options}
        placeholder="When can we invoice them?"
        value={hotel.invoiceTiming}
        onChange={(e, { value }) => onChange("invoiceTiming", value)}
      />
    </Form>
  );
};

const options = [
  { text: "With booking", value: "withBooking" },
  { text: "With check in", value: "withCheckIn" },
  { text: "With check out", value: "withCheckOut" },
];
