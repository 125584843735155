import React from "react";

import PropTypes from "prop-types";
import { Form, Header } from "semantic-ui-react";

export class OccupancyForm extends React.PureComponent {
  handleChange = ({ target }) => {
    const { name, value } = target;
    const occupancyBefore = this.props.occupancy || {};
    const newOccupancyState = { ...occupancyBefore, [name]: value };
    this.props.onChange("occupancy", newOccupancyState);
  };
  render() {
    const { occupancy = {} } = this.props;
    return (
      <Form>
        <Header>Occupancy</Header>
        <Form.Group widths="equal" onChange={this.handleChange}>
          <Form.Input
            label="Max Adults"
            name="maxAdults"
            value={occupancy.maxAdults || ""}
            type="number"
          />
          <Form.Input
            label="Max Children"
            name="maxChildren"
            value={occupancy.maxChildren || ""}
            type="number"
          />
        </Form.Group>
      </Form>
    );
  }
}

OccupancyForm.propTypes = {
  occupancy: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};
