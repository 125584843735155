import React from "react";

import MarkdownEditor from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";

export class PropertyDescriptionEditor extends React.PureComponent {
  handleDescriptionChange = (value) => {
    const newValue = value !== "" ? value : null;
    this.props.onChange("descriptionMarkdown", newValue);
  };

  render() {
    const { value } = this.props;

    return (
      <MarkdownEditor
        value={value || ""}
        onChange={this.handleDescriptionChange}
        options={{ spellChecker: false }}
      />
    );
  }
}
