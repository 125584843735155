import React from "react";

import { compact, isEmpty } from "lodash";
import { Form, Label } from "semantic-ui-react";

class DistancesForm extends React.PureComponent {
  handleChange = (index, value) => {
    const distancesBefore = this.props.distanceStrings || [];
    const newDistances = [...distancesBefore];
    newDistances[index] = value;
    const compactDistances = compact(newDistances);
    this.props.onChange("distanceStrings", compactDistances);
  };

  render() {
    const { distanceStrings } = this.props;

    let renderedDistances = distanceStrings ? [...distanceStrings] : [];
    const noDistancesExistYet = isEmpty(renderedDistances);

    // determine whether to append a new distance input
    const lastDistanceString = renderedDistances[renderedDistances.length - 1];
    const shouldAppendEmptyInput = !isEmpty(lastDistanceString);

    if (noDistancesExistYet || shouldAppendEmptyInput) {
      renderedDistances.push("");
    }

    return (
      <Form.Field>
        <Label>Distances</Label>
        {renderedDistances.map((distanceString, index) => (
          <DistanceInput
            value={distanceString}
            onChange={this.handleChange}
            index={index}
            key={index}
          />
        ))}
      </Form.Field>
    );
  }
}

class DistanceInput extends React.PureComponent {
  handleChange = ({ target }) => {
    const { value } = target;
    this.props.onChange(this.props.index, value);
  };

  render() {
    return (
      <Form.Input
        name="distance"
        value={this.props.value}
        placeholder="Distance as string"
        onChange={this.handleChange}
      />
    );
  }
}

export { DistancesForm };
