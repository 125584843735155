import React from "react";

import { isEmpty } from "lodash";
import { Form, Header } from "semantic-ui-react";

class FacilityArray extends React.PureComponent {
  handleChange = (index, value) => {
    const facilitiesBefore = this.props.facilities || [];
    const newFacilityState = [...facilitiesBefore];
    newFacilityState[index] = value;
    const compactFacilities = newFacilityState.filter((facilityObj) => {
      return !isEmpty(facilityObj.facility);
    });
    this.props.onChange("facilities", compactFacilities);
  };

  render() {
    const { facilities } = this.props;

    let renderedFacilities = facilities ? [...facilities] : [];
    const noFacilitiesExistYet = isEmpty(renderedFacilities);

    // determine whether a new facilitiy field should be appended
    const lastFacilityObj =
      renderedFacilities[renderedFacilities.length - 1] || {};
    const shouldAppendEmptyField = !isEmpty(lastFacilityObj.facility);
    if (noFacilitiesExistYet || shouldAppendEmptyField) {
      renderedFacilities.push({ facility: "", facilityCategory: "" });
    }

    return (
      <Form>
        <Header>Facilities</Header>
        <Form.Field>
          {renderedFacilities.map((facility, index) => (
            <FacilityInput
              facility={facility.facility}
              facilityCategory={facility.facilityCategory}
              onChange={this.handleChange}
              index={index}
              key={index}
            />
          ))}
        </Form.Field>
      </Form>
    );
  }
}

class FacilityInput extends React.PureComponent {
  handleChange = ({ target }) => {
    const { facility, facilityCategory, onChange, index } = this.props;
    const { name, value } = target;
    const newState = { facility, facilityCategory, [name]: value };
    onChange(index, newState);
  };

  render() {
    const { facility, facilityCategory } = this.props;
    return (
      <Form.Group widths="equal" onChange={this.handleChange}>
        <Form.Input
          name="facility"
          label="Facility description"
          value={facility}
        />
        <Form.Input
          name="facilityCategory"
          label="Facility category"
          value={facilityCategory}
        />
      </Form.Group>
    );
  }
}

export { FacilityArray as FacilitiesForm };
