import React from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { logout } from "../login";
import Menu from "./Menu";

const MenuContainer = (props) => {
  const history = useHistory();
  const { user, logout } = props;

  const onLogoutButtonClick = () => {
    logout();
    history.push("/login");
  };

  return <Menu onLogoutButtonClick={onLogoutButtonClick} user={user} />;
};

const mapStateToProps = ({ auth }) => {
  return { user: auth.user };
};

export default connect(mapStateToProps, { logout })(MenuContainer);
