import React from "react";

import { set } from "lodash";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { fetchHotel, postHotel, patchHotel } from "./api";
import { defaultHotelState } from "./defaultHotelState";
import { HotelInputView } from "./HotelInputView";

/**
 * This class manages the insertion of new hotels in the database and the
 * patching of existing ones.
 */
class HotelInputContainer extends React.PureComponent {
  state = { hotel: defaultHotelState, isLoading: false, isDisabled: false };

  componentDidMount() {
    if (!this.props.isNewHotel) {
      fetchHotel(this.props.hotelId).then((hotel) => this.setState({ hotel }));
    }
  }

  /**
   * Call this method to change the hotel object.
   *
   * @example
   *   this.onChange("contact.booking.name", "Eberhard");
   *
   * @param {string} path - The path of the field to change.
   * @param {any} value - The value to set at the specified path.
   */
  onChange = (path, value) => {
    const newHotelState = { ...this.state.hotel };
    set(newHotelState, path, value);
    this.setState({ hotel: newHotelState });
  };

  /** Call this method when the user intends to save its changes. */
  onSubmit = () => {
    this.setState({ isLoading: true });
    if (this.props.isNewHotel) {
      this.postHotel();
    } else {
      this.patchHotel();
    }
  };

  /** Creates a new hotel in the database. */
  postHotel = () =>
    postHotel(this.state.hotel)
      .then(() => {
        toast.success("Saved to database");
        this.setState({ isLoading: false, isDisabled: true });
      })
      .catch(() => {
        toast.error("Something went wrong");
        this.setState({ isLoading: false });
      });

  /** Patches an existing hotel in the database. */
  patchHotel = () =>
    patchHotel(this.state.hotel)
      .then(() => {
        toast.success("Saved to database");
      })
      .catch((e) => {
        toast.error("Something went wrong");
        toast.error(e);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });

  render() {
    return (
      <>
        <h1>Edit {this.state.hotel.name || this.props.hotelId}</h1>
        <HotelInputView
          hotel={this.state.hotel}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          isLoading={this.state.isLoading}
          isDisabled={this.state.isDisabled}
        />
      </>
    );
  }
}

HotelInputContainer.propTypes = {
  hotelId: PropTypes.string,
  isNewHotel: PropTypes.bool,
};

export default HotelInputContainer;
