import { takeEvery, all, call, put, takeLeading } from "redux-saga/effects";

import { snowtrade } from "../api";
import { stayTypes } from "./types";

export function* watchStays() {
  const takeEveryPropertyStaysFetchRequest = takeEvery(
    stayTypes.PROPERTYSTAY_FETCHLIST_REQUEST,
    withErrorHandling(
      fetchPropertyStays,
      stayTypes.PROPERTYSTAY_FETCHLIST_FAILURE
    )
  );

  const takeLeadingAllStaysFetchRequest = takeLeading(
    stayTypes.FETCHLIST_REQUEST,
    withErrorHandling(fetchAllStays, stayTypes.FETCHLIST_FAILURE)
  );

  const takeEveryStayCreateRequest = takeEvery(
    stayTypes.CREATE_REQUEST,
    withErrorHandling(createStay, stayTypes.CREATE_FAILURE)
  );

  const takeEveryStayEditRequest = takeEvery(
    stayTypes.EDIT_REQUEST,
    withErrorHandling(editStay, stayTypes.EDIT_FAILURE)
  );

  yield all([
    takeEveryPropertyStaysFetchRequest,
    takeLeadingAllStaysFetchRequest,
    takeEveryStayCreateRequest,
    takeEveryStayEditRequest,
  ]);
}

//
// Sagas
//

function* fetchPropertyStays({ payload: { propertyId } }) {
  const response = yield snowtrade.get(`/api/properties/${propertyId}/stays`);
  yield put({
    type: stayTypes.PROPERTYSTAY_FETCHLIST_SUCCESS,
    payload: response.data,
  });
}

function* fetchAllStays({ payload: { params } }) {
  const response = yield snowtrade.get("/api/stays", { params });
  yield put({ type: stayTypes.FETCHLIST_SUCCESS, payload: response.data });
}

function* createStay({ payload: { stay } }) {
  const response = yield snowtrade.post("/api/stays", stay);
  yield put({ type: stayTypes.CREATE_SUCCESS, payload: response.data });
}

function* editStay({ payload: { stayId, newStay } }) {
  const response = yield snowtrade.patch(`/api/stays/${stayId}`, newStay);
  yield put({ type: stayTypes.EDIT_SUCCESS, payload: response.data });
}

//
// Helpers
//

/**
 * Wraps the given saga in an error handler. In the case of an error in the
 * saga, it dispatches the given errorType.
 */
const withErrorHandling = (saga, errorType, ...sagaArgs) =>
  function* (action) {
    console.log(action);
    try {
      yield call(saga, action, ...sagaArgs);
    } catch (error) {
      console.log({ error });
      yield put({ type: errorType, payload: error.toString() });
    }
  };
