import React from "react";

import { isEmpty, last } from "lodash";
import PropTypes from "prop-types";
import { Form, Header } from "semantic-ui-react";

import { CostInput } from "./CostInput";
import { costPropType } from "./types";

export const AdditionalCostsForm = ({ additionalCosts, onChange }) => {
  const handleChange = (index, newCostObject) => {
    const costsBefore = additionalCosts || [];
    const newCosts = [...costsBefore];
    newCosts[index] = newCostObject;
    const compactedCosts = newCosts.filter((cost) => cost && cost.name);
    onChange("additionalCosts", compactedCosts);
  };

  const renderedCosts = additionalCosts ? [...additionalCosts] : [];

  const lastCostObject = last(renderedCosts) || {};
  const shouldAppendEmptyField =
    isEmpty(additionalCosts) || lastCostObject.name;
  if (shouldAppendEmptyField) {
    renderedCosts.push({ name: "", description: "" });
  }

  return (
    <Form>
      <Header>Additional Costs</Header>
      {renderedCosts.map((cost, index) => (
        <CostInput
          cost={cost}
          index={index}
          key={index}
          onChange={handleChange}
        />
      ))}
    </Form>
  );
};

//
// Prop Types
//

AdditionalCostsForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  additionalCosts: PropTypes.arrayOf(costPropType),
};
