import React, { Component } from "react";

import _ from "lodash";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactTable from "react-table";

import { history } from "../helpers";
import { reactTableRegexFilterMethod } from "../helpers/reactTableRegexFilterMethod";
import { fetchProperties } from "../property";
import {
  fetchExternalProperties,
  fetchExternalPropertyDetails,
  fetchExternalStays,
} from "./actions";
import CreatePropertyButton from "./CreatePropertyButton";

class OperatorPropertiesContainer extends Component {
  componentDidMount() {
    const { operator } = this.props.match.params;
    const operatorList = ["cimalpes", "bramble", "nonapi"];
    // Check if the operator entered, is in the list of allowed operators.
    if (!_.includes(operatorList, operator)) {
      history.push("/cms/404");
    }
    this.fetchProperties();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.operator !== prevProps.match.params.operator) {
      this.fetchProperties();
    }
  }

  fetchProperties = () => {
    this.props.fetchProperties(
      { operator: _.upperFirst(this.props.match.params.operator) },
      "name city operatorId relevant propertyType serviceLevels operator contact"
    );
  };

  onPropertyListButtonClick = () => {
    this.props.fetchExternalProperties(
      _.lowerCase(this.props.match.params.operator)
    );
  };

  onDetailsButtonClick = () => {
    const opertatorProperties = this.getOperatorProperties();
    opertatorProperties.map(async (operatorProperty) => {
      return await this.props.fetchExternalPropertyDetails(
        operatorProperty._id
      );
    });
  };

  onStaysButtonClick = () => {
    this.props.fetchExternalStays();
  };

  renderTable() {
    const columns = [
      {
        Header: "",
        id: "linkToDetailPage",
        Cell: (row) => (
          <Link to={`/cms/properties/${row.original._id}`}>
            <i className="external alternate icon" />
          </Link>
        ),
        maxWidth: 38,
      },
      {
        Header: "Operator ID",
        accessor: "operatorId",
        maxWidth: 100,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Type",
        accessor: "propertyType",
      },
      {
        Header: "Service Type",
        accessor: "serviceLevels",
      } /*,
      {
        Header: 'Relevanz',
        accessor: 'relevant',
        Cell: row => (row.value ? 'True' : 'False')
      }*/,
    ];

    return (
      <ReactTable
        defaultPageSize={10}
        className="-striped -highlight"
        data={this.getOperatorProperties()}
        columns={columns}
        defaultFilterMethod={reactTableRegexFilterMethod}
        defaultSorted={[{ id: "operatorId" }]}
        filterable
      />
    );
  }

  getOperatorProperties = () => {
    const { operator } = this.props.match.params;
    if (["bramble", "cimalpes"].includes(operator)) {
      return _.filter(this.props.properties, {
        operator: _.upperFirst(this.props.match.params.operator),
      });
    } else {
      return this.props.properties.filter(
        (p) => !["Cimalpes", "Bramble"].includes(p.operator)
      );
    }
  };

  render() {
    const operatorUpperFirst = _.upperFirst(this.props.match.params.operator);
    return (
      <div>
        <div className="ui grid" style={{ marginBottom: "1rem" }}>
          <div className="row">
            <div className="nine wide column">
              <h2 className="ui header">
                <i className="home icon" />
                <div className="content">
                  {operatorUpperFirst}
                  <div className="sub header">Listings in our Database</div>
                </div>
              </h2>
            </div>
            <div className="seven wide column bottom aligned">
              <button
                style={{ marginBottom: "10px", width: "200px" }}
                onClick={this.onStaysButtonClick}
                className={`ui labeled right floated icon blue button ${
                  this.props.isImporting ? "loading disabled" : ""
                }`}
              >
                <i className="sync icon" />
                Sync all Stays from APIs
              </button>
              {operatorUpperFirst !== "Nonapi" && (
                <button
                  style={{ marginBottom: "10px", width: "200px" }}
                  onClick={this.onDetailsButtonClick}
                  className={`ui labeled right floated icon blue button ${
                    this.props.isImporting ? "loading disabled" : ""
                  }`}
                >
                  <i className="sync icon" />
                  Sync with {operatorUpperFirst} Details API
                </button>
              )}

              <button
                style={{ marginBottom: "10px", width: "200px" }}
                onClick={this.onPropertyListButtonClick}
                className={`ui labeled right floated icon teal button ${
                  this.props.isImporting ? "loading disabled" : ""
                }`}
              >
                <i className="sync icon" />
                Sync with {operatorUpperFirst} Property-List API
              </button>
            </div>
          </div>
        </div>
        <div className="">{this.renderTable()}</div>
        {operatorUpperFirst === "Nonapi" && <CreatePropertyButton />}
      </div>
    );
  }
}

const mapStateToProps = ({ properties, stays }) => {
  return {
    isImporting: properties.isImporting || stays.isImporting,
    properties: Object.values(properties.items),
  };
};

export default connect(mapStateToProps, {
  fetchProperties,
  fetchExternalProperties,
  fetchExternalPropertyDetails,
  fetchExternalStays,
})(OperatorPropertiesContainer);
