import React from "react";

import PropTypes from "prop-types";
import { Form, Select } from "semantic-ui-react";

export const OperatorSelection = ({
  operators = [],
  isLoading,
  onSelectOperatorId,
}) => {
  const operatorOptions = operators.map((operator) => ({
    key: operator._id,
    value: operator._id,
    text: operator.name,
  }));

  return (
    <Form>
      <Select
        options={operatorOptions}
        loading={isLoading}
        onChange={(e, { value }) => onSelectOperatorId(value)}
        search
      />
    </Form>
  );
};

//
// Prop Types
//

OperatorSelection.propTypes = {
  /** Called with the new operator id */
  onSelectOperatorId: PropTypes.func.isRequired,

  isLoading: PropTypes.bool,
  operators: PropTypes.arrayOf(PropTypes.object),
};
