import { stayTypes } from "./types";

export const fetchStays = (conditions = null, projection = null) => {
  const params = { conditions, projection };
  return { type: stayTypes.FETCHLIST_REQUEST, payload: { params } };
};

export const fetchPropertyStays = (propertyId) => {
  return {
    type: stayTypes.PROPERTYSTAY_FETCHLIST_REQUEST,
    payload: { propertyId },
  };
};

export const createStay = (stay) => {
  return {
    type: stayTypes.CREATE_REQUEST,
    payload: { stay },
  };
};

/**
 * Creates a redux action for editing the given stay. The object passed as
 * newStay has to have the _property field!
 */
export const editStay = (stayId, newStay) => {
  return {
    type: stayTypes.EDIT_REQUEST,
    payload: { stayId, newStay },
  };
};
