import React from "react";

import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import {
  createInputChangeEventHandler,
  createSelectionEventHandler,
} from "../../../form/changeHandlers";

/**
 * Renders a form consisting of two input fields, one for the operator and one
 * for the operatorId field.
 */
export const OperatorForm = ({ property = {}, onChange }) => {
  const onInputChangeEvent = createInputChangeEventHandler(onChange);

  return (
    <Form>
      <p>
        This OperatorInput does not connect properties to the OperatorAccess but
        instead controls which API to call for property information (in the case
        of Bramble and Cimalpes) or which images to fetch from S3.
      </p>
      <Form.Group widths="equal">
        <Form.Input
          label="Operator"
          placeholder="Name of the operator"
          value={property.operator || ""}
          name="operator"
          onChange={createSelectionEventHandler(onChange)}
        />
        <Form.Input
          label="Operator Id"
          placeholder="Any number"
          value={property.operatorId || ""}
          name="operatorId"
          onChange={onInputChangeEvent}
          type="number"
        />
      </Form.Group>
      <Form.Group widths="2">
        <Form.Input
          label="Purpleski Chalet Code"
          placeholder="e.g. 'FO', leave empty when other operator"
          value={property.purpleSkiChaletCode || ""}
          name="purpleSkiChaletCode"
          onChange={onInputChangeEvent}
        />
      </Form.Group>
    </Form>
  );
};

//
// Prop Types
//

OperatorForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  property: PropTypes.shape({
    operator: PropTypes.string,
    operatorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};
