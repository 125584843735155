import React, { useState } from "react";

import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { Button, Icon } from "semantic-ui-react";

import { syncRoomImages } from "../api";

export const SyncRoomImagesButton = ({ room }) => {
  const [isSyncing, setIsSyncing] = useState(false);

  const onSyncClick = () => {
    setIsSyncing(true);
    syncRoomImages(room._id)
      .then((imagesAndThumbnail) => {
        if (isEmpty(imagesAndThumbnail)) {
          toast.error("Could not find images for this room");
          return;
        }

        const { thumbnail, images } = imagesAndThumbnail;
        toast.success(
          `Synced ${thumbnail ? "a thumbnail and" : ""} ${images.length} images`
        );
      })
      .catch(() => toast.error("An error occurend"))
      .finally(() => setIsSyncing(false));
  };

  return (
    <Button
      fluid
      loading={isSyncing}
      onClick={onSyncClick}
      icon
      size="mini"
      compact
    >
      <Icon name="image outline" />
      <Icon name="redo" />
    </Button>
  );
};
