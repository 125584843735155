import { useEffect, useState } from "react";

import { snowtrade } from "../../api";

/**
 * @param {string} hotelId
 * @returns {{
 *   isFetching: boolean;
 *   rates: import("./types").HotelRate[];
 *   refetchRates: any;
 * }}
 */
export const useHotelRates = (hotelId) => {
  const [rates, setRates] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    fetchHotelRates();
  }, [hotelId]);

  const fetchHotelRates = () => {
    if (!hotelId) return;
    snowtrade
      .get(`/api/hotels/${hotelId}/rates`)
      .then((response) => setRates(response.data))
      .finally(() => setIsFetching(false));
  };

  return { isFetching, rates, refetchRates: fetchHotelRates };
};
