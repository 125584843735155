import React from 'react';

import _ from 'lodash';
import { Link } from 'react-router-dom';
import './PropertyCard.css';

const addSeparator = price => {
  if (_.isNil(price)) {
    return;
  }
  const priceString = price.toString();
  if (priceString.length <= 3) {
    return priceString;
  } else if (priceString.length <= 6) {
    let hundreds = priceString.slice(-3);
    let thousends = priceString.slice(0, -3);
    return `${thousends} ${hundreds}`;
  }
};

const getPriceMinMax = stays => {
  if (stays.message) {
    return { minPrice: null, maxPrice: null };
  }
  const prices = _.flattenDeep(
    stays.map(stay => {
      if (stay.publishPrices && !_.isEmpty(stay.rates)) {
        return Object.values(stay.rates)
      }
      return null;
    })
  );

  return {
    minPrice: addSeparator(_.min(prices)),
    maxPrice: addSeparator(_.max(prices))
  };
};

const renderPrices = (stays, currency) => {
  const pricesBetween = !_.isNil(stays) && getPriceMinMax(stays);
  const currencyIcon = currency !== 'CHF' ? 'euro icon' : '';
  const currencyCHF = currency === 'CHF' ? 'CHF ' : '';

  let prices;
  if (pricesBetween.minPrice) {
    prices = (
      <>
        <i className={`${currencyIcon} sign`} />
        {currencyCHF} {pricesBetween.minPrice}
        {' — '}
        {pricesBetween.maxPrice}
      </>
    );
  } else {
    prices = 'Price on Application';
  }

  return (
    <div className='extra content' id='propertyPrices'>
      <span className='right floated'>Price</span>
      <span>{prices}</span>
    </div>
  );
};

const PropertyCard = ({ property, stays }) => {
  const {
    _id,
    name,
    city,
    address,
    propertyType,
    bedrooms,
    beds,
    thumbnail,
    status,
    currency
  } = property;

  const addressSector = address != null ? address.sector : '';

  const ribbon =
    status === 'draft' ? <span className='ui red right ribbon label'>{status}</span> : '';

  let itemList = [];
  if (propertyType) {
    itemList.push({ name: _.upperFirst(propertyType), icon: 'home' });
  }
  if (bedrooms) {
    itemList.push({ name: `${bedrooms} Bedrooms`, icon: 'bed' });
  }
  if (beds && beds.adults) {
    itemList.push({ name: `${beds.adults} Adults`, icon: 'user' });
  } else {
    itemList.push({ name: 'On request', icon: 'user' });
  }
  if (beds && beds.children) {
    itemList.push({ name: `${beds.children} Children`, icon: 'child' });
  }

  return (
    <Link to={`/cms/properties/${_id}`} key={_id} className='ui card fluid' id='propertyCard'>
      <div className='image'>
        {ribbon}
        <img src={thumbnail} alt={name} />
      </div>
      <div className='content'>
        <div className='header' id='propertyName'>
          {name}
        </div>
        <div className='meta'>{addressSector ? `${addressSector} - ${city}` : city}</div>
        <div className='description'>
          <div className='ui list'>
            {itemList.map((item, index) => (
              <div className='item' key={index}>
                <i className={`icon ${item.icon}`} style={{ paddingRight: '2rem' }} />
                {item.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      {renderPrices(stays, currency)}
    </Link>
  );
};

export default PropertyCard;
