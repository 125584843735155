import React from "react";

import PropTypes from "prop-types";

import { UserFormView } from "./UserFormView";

/** @augments {React.PureComponent<State, Props>} */
class UserFormContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { user: props.user || {} };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.setState({ user: this.props.user || {} });
    }
  }

  handleChange = (userUpdate) => {
    const userBefore = this.state.user || {};
    const newUser = { ...userBefore, ...userUpdate };
    this.setState({ user: newUser });
    console.log(newUser);
  };

  handleSubmit = () => this.props.onSubmit(this.state.user);

  render() {
    return (
      <UserFormView
        isLoading={this.props.isLoading}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        submitText={this.props.submitText}
        user={this.state.user}
      />
    );
  }
}

export default UserFormContainer;

//
// Prop Types
//

UserFormContainer.propTypes = {
  /** Called with the user value. */
  onSubmit: PropTypes.func.isRequired,

  isLoading: PropTypes.bool,

  submitText: PropTypes.string,

  /**
   * User as from before the edit. If you use this form to create a new user,
   * this value is not required.
   */
  user: PropTypes.object,
};
