// @ts-check
import React from "react";

import { Divider, Form, Segment } from "semantic-ui-react";

/**
 * @param {{
 *   sleeps: import("../../../hotelRoomTypes").HotelRoomBedSleepObject[];
 *   bedIndex: number;
 *   onUpdate: any;
 * }} props
 */
export const SleepsInputSegment = ({ sleeps = [], bedIndex, onUpdate }) => {
  const addSleepObject = () => {
    const newSleepObject = { maxPax: 0, maxAdults: 0 };
    onUpdate({ sleeps: [...sleeps, newSleepObject] });
  };

  const handleSleepChange = (sleepIndex, newSleep) => {
    const newSleeps = [...sleeps];
    newSleeps[sleepIndex] = newSleep;
    onUpdate({ sleeps: newSleeps });
  };

  return (
    <Segment className="boxed colored">
      {sleeps.map((sleepObject, sleepIndex) => (
        <React.Fragment key={sleepObject._id || sleepIndex}>
          <SingleSleepInput
            sleepObject={sleepObject}
            sleepIndex={sleepIndex}
            bedIndex={bedIndex}
            onChange={handleSleepChange}
          />
          {sleepIndex < sleeps.length - 1 && <Divider horizontal>OR</Divider>}
        </React.Fragment>
      ))}
      <Form.Button onClick={addSleepObject}>
        Add different bed usage
      </Form.Button>
    </Segment>
  );
};

/**
 * @param {{
 *   sleepObject: import("../../../hotelRoomTypes").HotelRoomBedSleepObject;
 *   bedIndex: number;
 *   sleepIndex: number;
 *   onChange: any;
 * }} props
 */
const SingleSleepInput = ({ sleepObject, bedIndex, sleepIndex, onChange }) => {
  const handleChange = (e, { name, value }) =>
    onChange(sleepIndex, { ...sleepObject, [name]: value });
  return (
    <Form.Group widths="equal" key={sleepIndex}>
      <Form.Input
        type="number"
        label="Max Pax"
        value={sleepObject.maxPax}
        id={`max-pax-input-${bedIndex}-${sleepIndex}`}
        // @ts-ignore
        onChange={handleChange}
        name="maxPax"
      />
      <Form.Input
        type="number"
        label="Of which are adults"
        value={sleepObject.maxAdults}
        id={`max-adults-input-${bedIndex}-${sleepIndex}`}
        // @ts-ignore
        onChange={handleChange}
        name="maxAdults"
      />
    </Form.Group>
  );
};
