import { externalTypes } from './types';

export const fetchExternalProperties = operator => {
  const params = { operator };
  return { type: externalTypes.PROPERTY_FETCHLIST_REQUEST, payload: { params } };
};

export const fetchExternalPropertyStays = propertyId => {
  return { type: externalTypes.PROPERTY_STAY_FETCHLIST_REQUEST, payload: { propertyId } };
};

export const fetchExternalPropertyDetails = (_id) => {
  return { type: externalTypes.PROPERTY_DETAILS_FETCH_REQUEST, payload: { _id } };
};

export const fetchExternalStays = propertyId => {
  return { type: externalTypes.STAY_FETCHLIST_REQUEST, payload: { propertyId } };
};
