import React, { useState } from "react";

import { toast } from "react-toastify";
import { Form } from "semantic-ui-react";

import { postNewOperator } from "./api";

export const CreateOperator = () => {
  const [name, setName] = useState("");
  const [isPosting, setIsPosting] = useState(false);

  const onSubmit = () => {
    setIsPosting(true);
    postNewOperator({ name }).then(() => {
      toast.success(`Created new operator ${name}`);
      setIsPosting(false);
    });
  };

  return (
    <Form>
      <Form.Group widths="16">
        <Form.Input
          placeholder="New operator name"
          value={name}
          onChange={(e, { value }) => setName(value)}
        />
        <Form.Button primary loading={isPosting} onClick={onSubmit}>
          Create New Operator
        </Form.Button>
      </Form.Group>
    </Form>
  );
};
