import React from 'react';

import { Dropdown } from 'semantic-ui-react';

const PaginationPageSizeDropdown = props => {
  const pageSizes = [12, 24, 48, 96];

  const onItemClick = pageSize => {
    props.onItemClick(pageSize);
  };

  return (
    <div>
      <Dropdown className="icon" text={props.currentShownData}>
        <Dropdown.Menu>
          {pageSizes.map(pageSize => (
            <Dropdown.Item
              text={pageSize}
              key={pageSize}
              icon={`${props.currentPageSize === pageSize ? 'check' : ''}`}
              onClick={(e, data) => {
                onItemClick(data.text);
              }}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default PaginationPageSizeDropdown;
