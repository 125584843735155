import React from "react";

import { default as HotelInputContainer } from "./HotelInputContainer";

export const HotelInput = HotelInputContainer;

export const HotelInputComponent = (props) => (
  <HotelInputContainer hotelId={props.match.params.hotelId} {...props} />
);

export const newHotelInput = (props) => (
  <HotelInputContainer isNewHotel={true} {...props} />
);
