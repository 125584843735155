import _ from "lodash";

export const jsonDateConverter = (dateString) => {
  const ISOdate = new Date(dateString);
  const day = ISOdate.getDay();
  const date = ISOdate.getDate();
  const month = ISOdate.getMonth();
  const year = ISOdate.getFullYear();

  const weekdayArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dateArray = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
  ];
  const monthDay = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return {
    day: weekdayArray[day],
    date: _.toString(date < 10 ? dateArray[date] : date),
    month: monthDay[month],
    year,
  };
};

/**
 * Returns a date string in "yyyy-mm-dd" format. Returns null on error.
 *
 * @param {String} dateString
 */
export const toDateOnlyString = (date) => {
  if (!date) return null;

  const dateInput = new Date(date);

  if (isNaN(dateInput.getTime())) return null;

  const timezoneAdjusted = new Date(
    dateInput.getTime() - dateInput.getTimezoneOffset() * 60000
  );

  const dateString = timezoneAdjusted.toISOString().split("T")[0];
  return dateString;
};
