import React from "react";

import PropTypes from "prop-types";
import MarkdownEditor from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";

export const BookingPolicyMarkdownForm = ({
  bookingPolicyMarkdown,
  onChange,
}) => {
  const handleChange = (value) => {
    const newValue = value !== "" ? value : null;
    onChange("bookingPolicyMarkdown", newValue);
  };

  return (
    <MarkdownEditor
      value={bookingPolicyMarkdown || ""}
      onChange={handleChange}
      options={{ spellChecker: false }}
    />
  );
};

//
// Prop Types
//

BookingPolicyMarkdownForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  bookingPolicyMarkdown: PropTypes.string,
};
