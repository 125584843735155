import React from "react";

import SidebarMenuItem from "./SidebarMenuItem";

const SidebarMenu = () => {
  return (
    <div className="sidebarMenu">
      <SidebarMenuItem header={["Dashboard", "/cms"]} />
      <SidebarMenuItem header={["Advanced Search", "/cms/advancedSearch"]} />
      <SidebarMenuItem header={["Hotels", "/cms/hotels"]} />
      <SidebarMenuItem header={["Users", "/cms/users"]} />
      <SidebarMenuItem header={["Issues", "/cms/dashboard/property-issues"]} />
      <SidebarMenuItem
        header={["Operators", "/cms/operators"]}
        items={[
          ["Bramble", "/cms/operators/bramble/properties"],
          ["Cimalpes", "/cms/operators/cimalpes/properties"],
          ["NonApi", "/cms/operators/nonapi/properties"],
        ]}
      />
    </div>
  );
};
export default SidebarMenu;
