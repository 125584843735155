import { useEffect, useState } from "react";

import { snowtrade } from "../api/snowtrade";

export const useHotelRoomById = (roomId) => {
  const [room, setRoom] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    snowtrade
      .get(`/api/hotelrooms/${roomId}`)
      .then((response) => setRoom(response.data))
      .finally(() => setIsFetching(false));
  }, []);

  return { room, isFetching };
};
