export const userRoleTypes = {
  ADMIN: "admin",
  GUEST: "guest",
  AGENT: "agent",
  SUPERVISOR_AGENT: "supervisorAgent",
  CUSTOMER: "customer",
  OPERATOR: "operator",
  AMEX_LIFESTYLE_AGENT: "amex_lifestyleAgent",
  HOTEL_MANAGER: "hotelManager",
};

/**
 * @typedef {{
 *   _id: string;
 *   lastName: string;
 *   firstName: string;
 *   role: string;
 *   company: string;
 * }} User
 */
