import React from 'react';

import _ from 'lodash';

import { jsonDateConverter } from '../helpers';

const PropertyStaysList = props => {
  const { stays, currency } = props;

  if (stays.isFetching === true) {
    return <div className="ui segment loading">Loading...</div>;
  }

  const renderRates = rates => {
    let res = [];
    _.forOwn(rates, (price, serviceLevel)=> {
      res.push(
        <tr>
          <td>{serviceLevel}</td>
          <td>{price} {currency || "EUR"}</td>
        </tr>
      )
    })
    return _.compact(res);
  };

  const headers = (
    <tr>
      <th>Check In</th>
      <th></th>
      <th>Check Out</th>
      <th></th>
      <th></th>
    </tr>
  );

  const rows = stays => {
    if (stays.message) {
      return (
        <tr>
          <td colSpan="6">{stays.message}</td>
        </tr>
      );
    }

    const staysSorted = _.sortBy(stays, 'checkIn');

    return staysSorted.map(stay => {
      const checkInDate = jsonDateConverter(stay.checkIn);
      const checkOutDate = jsonDateConverter(stay.checkOut);
    
      return (
        <React.Fragment key={stay._id}>
          <tr>
            <td >
              <span>{checkInDate.day}, </span>
              <span>{checkInDate.date}. </span>
              <span>{checkInDate.month} </span>
              <span>{checkInDate.year} </span>
            </td>
            <td>
              <i className="long arrow alternate right icon"></i>
            </td>
            <td >
              <span>{checkOutDate.day}, </span>
              <span>{checkOutDate.date}. </span>
              <span>{checkOutDate.month} </span>
              <span>{checkOutDate.year} </span>
            </td>
            <td >{stay.available ? "Available" : "Sold"}</td>
            <td >{renderRates(stay.rates)}</td>
          </tr>
        </React.Fragment>
      );
    });
  };

  return (
    <table className="ui striped selectable table">
      <thead>{headers}</thead>
      <tbody>{rows(stays)}</tbody>
    </table>
  );
};

export default PropertyStaysList;
