import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Pagination } from 'semantic-ui-react';

import { getPaginatedItems } from './actions';

export class PaginationContainer extends Component {
  componentDidMount() {
    const { items } = this.props;
    const { activePage, pageSize } = this.props.pagination;
    this.props.getPaginatedItems(items, activePage, pageSize);
  }

  componentDidUpdate(prevProps) {
    const { items } = this.props;
    const { pageSize } = this.props.pagination;
    if (items !== prevProps.items || pageSize !== prevProps.pagination.pageSize) {
      this.props.getPaginatedItems(items, 1, pageSize);
    }
  }

  onPageChange = (items, activePage, pageSize) => {
    this.props.getPaginatedItems(items, activePage, pageSize);
    window.scrollTo(0, 0);
  };

  render() {
    const { items } = this.props;
    const { activePage, pageSize, totalPages } = this.props.pagination;
    return (
      <Pagination
        activePage={activePage}
        totalPages={totalPages}
        onPageChange={(e, { activePage }) => {
          this.onPageChange(items, activePage, pageSize);
        }}
      />
    );
  }
}

const mapStateToProps = ({ pagination }) => {
  return { pagination };
};

export default connect(
  mapStateToProps,
  { getPaginatedItems }
)(PaginationContainer);
