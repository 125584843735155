import { compact } from "lodash";
import { defaultMemoize } from "reselect";

/*
Use this file for adding and removing issues.
To ADD a new issue, create a display name prefixed with ISSUE_
Then create a function which takes a property and 
returns the issue display name if the property has this issue.
Finally, add the created selector function to the SELECTORS_TO_USE array.
*/

//
// No Currency
//

const ISSUE_NO_CURRENCY = "Missing currency";
const selectNoCurrency = (property) =>
  property.currency ? false : ISSUE_NO_CURRENCY;

//
// ----------------------------------------
// Operational
// ----------------------------------------
//

/** Add your issue selectors to this array! */
const SELECTORS_TO_USE = [selectNoCurrency];

const getIssueOfProperty = (property) => {
  const issuesOfProperty = [];
  SELECTORS_TO_USE.forEach((selector) => {
    const issue = selector(property);
    if (issue) {
      issuesOfProperty.push(issue);
    }
  });
  if (issuesOfProperty.length === 0) return null;

  return { property, issues: issuesOfProperty };
};

/**
 * Returns issues for the given properties. Uses a memoizer to not scan every
 * property when they did not change.
 *
 * @param {Array} propertiesArray - The array of property to objects to scan for issues.
 * @returns {{ property; issues }[]}
 */
export const getIssues = defaultMemoize((propertiesArray) =>
  compact(propertiesArray.map(getIssueOfProperty))
);
