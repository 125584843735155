import { useEffect, useState } from "react";

import { snowtrade } from "../../api/snowtrade";

export const useAmadeusHotelId = (strHotelId) => {
  useEffect(() => {
    snowtrade
      .get(`/api/hotels/${strHotelId}/amadeusId`)
      .then((response) => setAmadeusHotelId(response.data.amadeusHotelId))
      .finally(() => setIsFetching(false));
  }, []);

  const [amadeusHotelId, setAmadeusHotelId] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  return { amadeusHotelId, isFetching };
};
