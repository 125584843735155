import { authTypes } from "./types";

export const login = (username, password) => {
  return {
    type: authTypes.LOGIN_REQUEST,
    payload: { username, password, tokenExpiration: "never" },
  };
};

export const logout = () => {
  return { type: authTypes.LOGOUT_REQUEST };
};
