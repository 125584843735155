import React from "react";

import { set } from "lodash";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { fetchRoom, patchRoom, postRoom } from "./api";
import { RoomInputView } from "./RoomInputView";

class RoomInputContainer extends React.PureComponent {
  state = {
    room: { isPublishedForAgents: true },
    loading: false,
    disabled: false,
  };

  componentDidMount() {
    if (!this.props.isNewRoom) {
      fetchRoom(this.props.roomId).then((room) => this.setState({ room }));
    }
  }

  onSubmit = () => {
    this.setState({ loading: true });
    if (this.props.isNewRoom) {
      this.postRoom();
    } else {
      this.patchRoom();
    }
  };

  postRoom = () => {
    postRoom(this.props.hotelId, this.state.room)
      .then(() => {
        toast.success("Success!");
        this.setState({ loading: false, disabled: true });
      })
      .catch((e) => {
        toast.error(e);
        this.setState({ loading: false });
      });
  };

  patchRoom = () => {
    patchRoom(this.state.room)
      .then(() => {
        toast.success("Success");
      })
      .catch((e) => toast.error(e.message))
      .finally(() => this.setState({ loading: false }));
  };

  handleChange = (path, value) => {
    const newRoomState = { ...this.state.room };
    set(newRoomState, path, value);
    this.setState({ room: newRoomState });
  };

  render() {
    return (
      <RoomInputView
        values={this.state.room}
        onChange={this.handleChange}
        onSubmit={this.onSubmit}
        loading={this.state.loading}
        disabled={this.state.disabled}
      />
    );
  }
}

export default RoomInputContainer;

RoomInputContainer.propTypes = {
  isNewRoom: PropTypes.bool,
  roomId: PropTypes.string,
};
