import React from 'react';

import { LineForm } from "./LineForm";



class LineFormContainer extends React.Component {
  constructor(props) {
    super(props);

    let initalLines;
    if (props.defaultValue && props.defaultValue.length > 0) {
      initalLines = props.defaultValue;
    } else {
      initalLines = [""]
    }
    this.state = {lines: initalLines};
  }

  onLineChange = (e, index) => {
    let lines = [...this.state.lines];
    lines[index] = e.target.value;
    this.setState({lines});

    if (this.props.onChange) {
      this.props.onChange({ target: { name: this.props.name, value: lines } })        
    }
  }

  onAddLineClick = () => {
    this.setState(prevState => ({
      lines: [...prevState.lines, ""]
    }));
  }

  render() {
    return (
      <LineForm value={this.state.lines} onChange={this.onLineChange} onAddLineClick={this.onAddLineClick} />
    )
  }
}

export default LineFormContainer;