import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ReactTable from "react-table";

const IssuesTableColumns = [
  {
    Header: "",
    id: "linkToDetailPage",
    Cell: (row) => (
      <Link to={`/cms/properties/${row.original.property._id}`}>
        <i className="external alternate icon" />
      </Link>
    ),
    maxWidth: 38,
  },
  {
    Header: "Operator",
    accessor: "property.operator",
  },
  {
    Header: "Country",
    accessor: "property.address.country",
  },
  { Header: "City", accessor: "property.city" },
  { Header: "Name", accessor: "property.name" },
  { Header: "Issues", accessor: "issues" },
];

/**
 * Renders the table for the IssuesContainer
 */
export const IssuesTable = ({ issues }) => {
  return <ReactTable columns={IssuesTableColumns} data={issues} />;
};

//
// Prop Types
//

IssuesTable.propTypes = {
  issues: PropTypes.arrayOf(
    PropTypes.shape({
      property: PropTypes.object,
      issues: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};
