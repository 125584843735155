import React from "react";

import { Dropdown } from "semantic-ui-react";

export const PropertyStatusDropdown = ({
  status = "draft",
  onChangeStatus,
}) => {
  return (
    <Dropdown
      selection
      options={options}
      value={status}
      floating
      onChange={(e, { value }) => onChangeStatus(value)}
    />
  );
};

const options = ["draft", "confidential", "demo", "published"].map((key) => ({
  key,
  value: key,
  text: key,
}));
