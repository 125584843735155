import React from "react";

import { Form } from "semantic-ui-react";

import { createInputChangeEventHandler } from "../../../form/changeHandlers";

export const PropertyCityInput = ({ value = "", onChange }) => (
  <Form.Input
    label="City"
    name="city"
    value={value}
    onChange={createInputChangeEventHandler(onChange)}
    placeholder="The city the property is located in"
  />
);
