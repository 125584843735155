import React from "react";

import { debounce, set } from "lodash";
import PropTypes from "prop-types";

import { editProperty, fetchProperty } from "./api";
import { PropertyEditorView } from "./PropertyEditorView";

const DEBOUNCE_PERSISTING_AFTER_LAST_INPUT = 500;

/**
 * This is the entry point for the AdminPropertyEditor which allows the user to
 * edit a property.
 *
 * It is planned to have an OperatorEditorContainer in the future as well. But
 * at the time of writing, there is none.
 */
class PropertyEditorContainer extends React.PureComponent {
  state = { property: null, isEditing: false };

  componentDidMount() {
    this.initialLoadProperty();
  }

  componentDidUpdate() {
    // Fetch the property if not done yet
    if (!this.state.property) {
      this.initialLoadProperty();
    }
  }

  /**
   * Fetches the property for the editor if a property id is given. Updates the
   * state with the received property.
   */
  initialLoadProperty = () => {
    const { propertyId } = this.props;
    if (!propertyId) return;
    fetchProperty(this.props.propertyId).then((property) =>
      this.setState({ property })
    );
  };

  onFormChange = (path, value) => {
    const newProperty = { ...this.state.property };
    set(newProperty, path, value);

    const newState = { ...this.state, isEditing: true, property: newProperty };
    this.setState(newState);
    this.debouncedPersistChanges(this.props.propertyId, newProperty);
  };

  /**
   * Calls the API to send changes to the backend. Sets the isEditing flag to
   * false afterwards.
   */
  persistChanges = async (propertyId, newPropertyState) => {
    await editProperty(propertyId, newPropertyState);
    this.setState({ isEditing: false });
  };

  debouncedPersistChanges = debounce(
    this.persistChanges,
    DEBOUNCE_PERSISTING_AFTER_LAST_INPUT
  );

  render() {
    return (
      <PropertyEditorView
        property={this.state.property}
        onChange={this.onFormChange}
        isEditing={this.state.isEditing}
      />
    );
  }
}

export default PropertyEditorContainer;

//
// Prop Types
//

PropertyEditorContainer.propTypes = {
  propertyId: PropTypes.string.isRequired,
};
