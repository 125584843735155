import React from "react";

import { default as RoomInput } from "./RoomInputContainer";

/**
 * Wraps the RoomInput in a way that it can be used with react router.
 * The roomId is conveyed in the url params
 */
const RoomInputComponent = (props) => (
  <RoomInput
    {...props}
    hotelId={props.match.params.hotelId}
    roomId={props.match.params.roomId}
  />
);

const newRoomInput = (props) => (
  <RoomInput {...props} hotelId={props.match.params.hotelId} isNewRoom={true} />
);

export { RoomInput, RoomInputComponent, newRoomInput };
