import jsonPatch from "fast-json-patch";

import { snowtrade } from "../../api/snowtrade";
import { authHeader } from "../../helpers";

/**
 * This cache stores property states as stored in the backend. Using this cache,
 * JSON Patch Operations can be computed when a change occurs.
 */
const dbPropertyCache = {};

const getPropertyUrl = (propertyId) => `/api/properties/${propertyId}`;

const makeHeaders = () => ({ ...authHeader(), "accept-language": "en" });

/**
 * Fetches and returns the given property from the backend.
 *
 * @param {string} propertyId - The id of the property to fetch.
 */
export const fetchProperty = (propertyId) => {
  const url = getPropertyUrl(propertyId);
  const headers = makeHeaders();
  return snowtrade.get(url, { headers }).then((res) => {
    const dbProperty = res.data;
    dbPropertyCache[propertyId] = dbProperty;
    return jsonPatch.deepClone(dbProperty);
  });
};

/**
 * Persists the changes made to the property using a simple PATCH request.
 * Returns the updated property.
 *
 * @param {string} propertyId - The id of the property to edit.
 * @param {object} newPropertyState - The new property state to persist.
 */
const putProperty = async (propertyId, newPropertyState) => {
  const url = getPropertyUrl(propertyId);
  const headers = makeHeaders();
  return snowtrade.patch(url, newPropertyState, { headers }).then((res) => {
    const dbProperty = res.data;
    dbPropertyCache[propertyId] = dbProperty;
    return dbProperty;
  });
};

/**
 * Persists the changes of the given property using the json patch standard.
 * Returns the updated property.
 *
 * @param {string} propertyId - The id of the property to edit.
 * @param {object} newPropertyState - The new property state to persist.
 */
const jsonPatchProperty = (propertyId, newPropertyState) => {
  const url = getPropertyUrl(propertyId);
  const headers = makeHeaders();
  headers["content-type"] = "application/json-patch+json";

  const dbProperty = dbPropertyCache[propertyId];
  const patchOperations = jsonPatch.compare(dbProperty, newPropertyState);

  console.log({ patchOperations });

  return snowtrade.patch(url, patchOperations, { headers }).then((res) => {
    const newProperty = res.data;
    dbPropertyCache[propertyId] = newProperty;
    return newProperty;
  });
};

/**
 * Persists the changes for the given property in the backend. Uses json patch
 * standard if possible. Returns the updated property.
 *
 * @param {string} propertyId - The id of the property to edit.
 * @param {object} newPropertyState - The new property state to persist.
 */
export const editProperty = (propertyId, newPropertyState) =>
  dbPropertyCache[propertyId]
    ? jsonPatchProperty(propertyId, newPropertyState)
    : putProperty(propertyId, newPropertyState);
