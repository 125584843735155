import React from "react";

import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import { FacilityRowForm } from "./FacilityRowForm";

/**
 * Renders a Form that allows the user to edit the property's facilities
 *
 * @augments {React.PureComponent<State, Props>}
 */
export class PropertyFacilitiesForm extends React.PureComponent {
  handleChange = (index, value) => {
    const facilitiesBefore = this.props.facilities || [];
    let newFacilities = [...facilitiesBefore];

    if (value) {
      newFacilities[index] = value;
    } else {
      newFacilities.splice(index, 1);
    }

    this.props.onChange(this.props.name, newFacilities);
  };

  render() {
    const facilitiesInput = this.props.facilities || [];
    const facilitiesToRender = [...facilitiesInput, { facility: "" }];

    return (
      <Form>
        {facilitiesToRender.map((facilityObject, index) => (
          <FacilityRowForm
            index={index}
            facilityObject={facilityObject}
            onChange={this.handleChange}
            key={facilityObject._id || index}
          />
        ))}
      </Form>
    );
  }
}

//
// Prop Types
//

PropertyFacilitiesForm.propTypes = {
  onChange: PropTypes.func.isRequired,

  /** @type {string} - Is used when calling the onChange function. */
  name: PropTypes.string.isRequired,
  facilities: PropTypes.array,
};
