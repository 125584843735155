import { searchTypes } from "./types";

const INITIAL_STATE = {
  hasSearched: false,
  results: [],
  dropdownOptions: {
    propertyTypeArray: [],
    serviceLevelArray: [],
    countryArray: [],
    cityArray: [],
    sectorArray: [],
    operatorArray: ["Bramble", "Cimalpes"],
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case searchTypes.FILTER_RESULTS:
      return { ...state, hasSearched: true, results: action.payload };
    case searchTypes.DROPDOWN_OPTIONS:
      return {
        ...state,
        dropdownOptions: { ...state.dropdownOptions, ...action.payload },
      };
    default:
      return state;
  }
};
