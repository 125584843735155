import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button, Header, Input, Loader, Segment } from "semantic-ui-react";

import { snowtrade } from "../../../api/snowtrade";
import { useHotelById } from "../../useHotelById";
import { AmadeusSearch } from "../AmadeusSearch";
import { useAmadeusHotelId } from "../useAmadeusHotelId";
import { RateMapsForm } from "./RateMapsForm";

export const useAmadeusHotelManager = (strHotelId) => {
  const {
    amadeusHotelId: fetchedAmadeusHotelId,
    isFetching,
  } = useAmadeusHotelId(strHotelId);
  const [amadeusHotelId, setAmadeusHotelId] = useState(fetchedAmadeusHotelId);

  useEffect(() => {
    setAmadeusHotelId(fetchedAmadeusHotelId);
  }, [fetchedAmadeusHotelId]);

  const changeAmadeusId = ({ target: { value } }) => setAmadeusHotelId(value);

  const persistAmadeusId = () => {
    snowtrade
      .put(`/api/hotels/${strHotelId}/amadeusId`, {
        amadeusHotelId: amadeusHotelId,
      })
      .then(() => {
        const log = `Persisted ${amadeusHotelId} as Amadeus ID for this hotel`;
        toast.success(log);
      })
      .catch(() => toast.error("uuupps"));
  };

  return { amadeusHotelId, isFetching, changeAmadeusId, persistAmadeusId };
};

export const HotelAmadeusManager = ({ strHotelId }) => {
  const { hotel, isFetching: isFetchingHotel } = useHotelById(strHotelId);
  const {
    amadeusHotelId,
    changeAmadeusId,
    persistAmadeusId,
  } = useAmadeusHotelManager(strHotelId);

  if (isFetchingHotel) return <Loader active />;

  return (
    <>
      <Header>{hotel.name}</Header>
      <Segment className="colored">
        <Input
          label="Amadeus ID"
          value={amadeusHotelId}
          onChange={changeAmadeusId}
        />
        <Button primary onClick={persistAmadeusId}>
          Save Amadeus Id
        </Button>
      </Segment>

      <Segment className="colored">
        <RateMapsForm strHotelId={hotel._id} />
      </Segment>
      <Segment className="colored">
        <Header>Amadeus Search</Header>
        {amadeusHotelId && <AmadeusSearch amadeusHotelId={amadeusHotelId} />}
      </Segment>
    </>
  );
};

//
// Prop Types
//

HotelAmadeusManager.propTypes = {
  strHotelId: PropTypes.string.isRequired,
};
