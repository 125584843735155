import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { UserForm } from "../UserForm";
import { fetchUser, sendPatchUserRequest } from "./api";

const useUser = (userId) => {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // Fetch user only once
  useEffect(() => {
    setIsLoading(true);
    fetchUser(userId)
      .then((dbUser) => setUser(dbUser))
      .finally(() => setIsLoading(false));
  }, []);

  const onSubmit = (newUser) => {
    setIsLoading(true);
    sendPatchUserRequest(newUser)
      .then(() => toast.success("Edited user successfully"))
      .catch((e) => toast.error(e.toString()))
      .finally(() => setIsLoading(false));
  };

  return { user, isLoading, onSubmit };
};

export const EditUserForm = ({ userId }) => {
  const { user, isLoading, onSubmit } = useUser(userId);

  return (
    <UserForm
      isLoading={isLoading}
      submitText="Save Changes"
      user={user}
      onSubmit={onSubmit}
    />
  );
};

//
// Prop Types
//

EditUserForm.propTypes = {
  userId: PropTypes.string.isRequired,
};
