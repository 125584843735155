import { takeEvery, takeLeading, all, put, call } from "redux-saga/effects";

import { snowtrade } from "../api";
import { logout } from "../login";
import { callAPIMiddleware } from "../redux/callAPIMiddleware";
import { propertyTypes } from "./types";

export function* watchProperties() {
  const takeLeadingFetchlistRequest = takeLeading(
    propertyTypes.FETCHLIST_REQUEST,
    withErrorHandling(fetchAllPropertiesSaga, propertyTypes.FETCHLIST_FAILURE)
  );

  const takeEveryFetchRequest = takeEvery(
    propertyTypes.FETCH_REQUEST,
    withErrorHandling(fetchPropertyDetailsSaga, propertyTypes.FETCH_FAILURE)
  );

  const takeEveryEditRequest = takeEvery(
    propertyTypes.EDIT_REQUEST,
    callAPIMiddleware
  );

  yield all([
    takeLeadingFetchlistRequest,
    takeEveryFetchRequest,
    takeEveryEditRequest,
  ]);
}

/**
 * Saga for fetching all properties from the backend.
 *
 * @param {import("./types").PropertiesFetchAction} action
 */
function* fetchAllPropertiesSaga({ payload = {} }) {
  const { params } = payload;
  const response = yield snowtrade.get("/api/properties", { params });
  yield put({ type: propertyTypes.FETCHLIST_SUCCESS, payload: response.data });
}

/**
 * Saga for fetching a single property from the backend.
 *
 * @param {import("./types").PropertyDetailsFetchAction} action
 */
function* fetchPropertyDetailsSaga({ payload = {} }) {
  const { _id } = payload;
  const response = yield snowtrade.get(`/api/properties/${_id}`);
  yield put({ type: propertyTypes.FETCH_SUCCESS, payload: response.data });
}

/**
 * Wraps the given saga in an error handler. In the case of an error in the
 * saga, it dispatches the given errorType. If the error occured was a 4xx or
 * 5xx HTTP code by the backend, it dispatches a logout action.
 */
const withErrorHandling = (saga, errorType, ...sagaArgs) =>
  function* (action) {
    try {
      yield call(saga, action, ...sagaArgs);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        yield put(logout());
      }
      yield put({ type: errorType, payload: error });
    }
  };
